export default ({ css, defaultStyles }) => {
  const { NewEntriesIndicator } = defaultStyles;

  const breakpointDesktop = 768;

  const newEntriesIndicator = css`
    ${NewEntriesIndicator.newEntriesIndicator};

    top: 150px;

    @media (min-width: ${breakpointDesktop}px) {
      display: none;
    }
  `;

  return {
    styles: {
      ...NewEntriesIndicator,
      newEntriesIndicator,
    },
  };
};
