import PropTypes from 'prop-types';

export const StructuredImageUrlShape = PropTypes.shape({
  url: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  default: PropTypes.bool,
});

export const StructuredImageShape = PropTypes.shape({
  type: PropTypes.string,
  caption: PropTypes.shape({
    value: PropTypes.string,
  }),
  byline: PropTypes.shape({
    title: PropTypes.string,
  }),
  imageAsset: PropTypes.shape({
    id: PropTypes.string,
    crop: PropTypes.string,
    size: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    urls: PropTypes.arrayOf(StructuredImageUrlShape).isRequired,
  }).isRequired,
});
