export default ({ css, defaultStyles }) => {
  const { EntryTimeDetails } = defaultStyles;

  const timeDetails = css`
    ${EntryTimeDetails.timeDetails};

    color: var(--c-primary-01, rgba(221,42,48));
    font-size: 14px;
    font-weight: bold;
    padding-left: 4px;
  `;

  return {
    styles: {
      ...EntryTimeDetails,
      timeDetails,
    },
  };
};
