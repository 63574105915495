import { uniqueClassName } from '../functions';

export default ({ css, keyframes }) => {
  const highlightedAnimation = keyframes`
    0% { opacity: 0 }
    65% { opacity: 0.5 }
    100% { opacity: 1 }
  `;

  const entry = uniqueClassName(css, 'entry')(css`
    color: #333;
    margin: 0;
    padding: 8px 0 20px 0;
    list-style: none;
    position: relative;

    &::before {
      border-left: 1px solid #ff7471;
      content: "";
      left: -7px;
      position: absolute;
      z-index: 0;
      top: 42px;
      bottom: 0;
    }

    iframe {
      border: none;
      max-width: 100%;
      overflow: hidden;
    }
  `);

  const entryMeta = css`
    text-align: left;
    margin-left: 15px;
  `;

  const isNew = css`
    opacity: 0;
  `;

  const animate = css`
    animation-name: ${highlightedAnimation};
    animation-duration: 2s;
  `;

  return {
    styles: {
      entry,
      entryMeta,
      isNew,
      animate,
    },
  };
};
