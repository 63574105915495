import { i18n as i18nConfig } from './i18n';
import { publicationConfigs } from './publicationConfigs';

function getI18n(language) {
  return i18nConfig[language] || i18nConfig['nb-no'];
}

export function getConfig(publication, key, group) {
  const publicationConfig = publicationConfigs[publication] || publicationConfigs.default;
  return group ? publicationConfig[group][key] : publicationConfig[key];
}

export function getTexts(publication, componentName, group) {
  const language = getConfig(publication, 'language');
  const i18n = getI18n(language);
  return group ? i18n[group][componentName] : i18n[componentName];
}
