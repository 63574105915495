import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HTML from 'html-parse-stringify';
import { themer } from '@core-live/theme-provider';

import MediaContent from '../MediaContent/MediaContent';
import SocialEmbed from '../SocialEmbed/SocialEmbed';
import DefaultContent from '../DefaultContent/DefaultContent';
import ChatContent from '../ChatContent/ChatContent';
import ArticleTeaserContent, { ArticleTeaserContentVariants } from '../ArticleTeaserContent/ArticleTeaserContent';
import EntryThemed from '../EntryThemed';

import { createElementProps, hashString, isVoidElement } from '../../utils/html';
import { isSocialEmbed } from '../../utils/socialEmbeds';
import { isMediaEmbed, isNrkVideo, triggerNrkVideoInit } from '../../utils/mediaEmbeds';
import { isArticleTeaser } from '../../utils/articleEmbeds';
import { EntryStructuredContent, isEntrySupported } from '../EntryStructuredContent/EntryStructuredContent';
import { getDomainFromUrl, allowedScriptOrigins } from '../../utils/urlHelpers';
import { isBrowser } from '../../utils/env';
import { LIVEFEED_THEMES, SOURCE_NAMES } from '../../utils/constants';
import { FeedSettingsDefaultProps, FeedSettingsShape } from '../../shapes';

export class EntryContent extends Component {
  checkForUnwantedTag(node) {
    return (node.content && node.content.match(/(\r\n|\n|\r)/gm));
  }

  checkAndHandleVoidElements(node, elementProps = {}) {
    const newProps = { ...elementProps };

    if (newProps && newProps.srcset) {
      newProps.srcSet = elementProps.srcset;
      delete newProps.srcset;
    }

    return isVoidElement(node.name) ? React.createElement(node.name, newProps) : null;
  }

  checkForScripts(node) {
    return node.name === 'script';
  }

  isScriptOriginUnsafe(node) {
    if (!node.attrs) {
      return false;
    }

    if (!node.attrs.src) {
      return true;
    }

    const domain = getDomainFromUrl(node.attrs.src);

    return !allowedScriptOrigins.find(origin => origin === domain);
  }

  handleDefaultCase(node, elementProps, elementKey) {
    if (this.checkForScripts(node) && this.isScriptOriginUnsafe(node)) {
      return false;
    }
    const voidElement = this.checkAndHandleVoidElements(node, elementProps);
    const cleanNode = this.cleanNode(node);

    return (
      voidElement || <DefaultContent key={elementKey} node={cleanNode} elementProps={elementProps} />
    );
  }

  cleanNode(node) {
    const { content } = node;
    const unwantedTags = this.checkForUnwantedTag(node);
    const cleanNode = Object.assign({}, node);

    if (unwantedTags && unwantedTags.length) {
      unwantedTags.forEach((el) => {
        if (content) {
          cleanNode.content = content.replace(el, '<br/>');
        }
      });
    }
    return cleanNode;
  }

  generateHashString(node, ix) {
    return `${hashString(JSON.stringify(node))}${ix}`;
  }

  mapParsedElements(elements, publication, sourceName) {
    const { theme } = this.props;

    return elements.map((node, ix) => {
      let element = null;
      const hashKey = this.generateHashString(node, ix);
      const elementProps = node.attrs ? createElementProps(node, ix) : { key: hashKey };

      if (isMediaEmbed(node)) {
        element = <MediaContent key={hashKey} node={node} elementProps={elementProps} publication={publication} />;
      } else if (isSocialEmbed(node)) {
        element = <SocialEmbed key={hashKey} node={node} publication={publication} />;
      } else if (isNrkVideo(node)) {
        element = this.handleDefaultCase(node, elementProps, hashKey);
        if (isBrowser()) {
          setTimeout(() => triggerNrkVideoInit(node), 50);
        }
      } else if (isArticleTeaser(node)) {
        const props = {
          key: hashKey,
          node: this.cleanNode(node),
          publication,
          theme,
        };

        if (sourceName === SOURCE_NAMES.AUTO_RELATED_ARTICLE) {
          props.variant = ArticleTeaserContentVariants.withoutDate;
        }

        element = <ArticleTeaserContent {...props} />;
      } else {
        element = this.handleDefaultCase(node, elementProps, hashKey);
      }
      if (node.children && element) {
        const children = isVoidElement(node.name)
          ? null
          : this.mapParsedElements(node.children, publication);

        return React.cloneElement(element, {}, children);
      }

      return element;
    }).filter(element => element);
  }

  parseLiveblogContent() {
    const { content, publication, sourceName } = this.props;
    const parsedElements = HTML.parse(`<span>${content}</span>`)[0].children;

    return this.mapParsedElements(parsedElements, publication, sourceName);
  }

  getEntryContentView() {
    const {
      isChat,
      authorName,
      authorImage,
      authorType,
      entriesAuthors,
      structuredContent = {},
      publication,
      feedSettings,
    } = this.props;

    const ParsedContent = this.parseLiveblogContent();

    if (feedSettings.theme === LIVEFEED_THEMES.SPEECH_BUBBLES) {
      return (
        <EntryThemed
          content={ParsedContent}
          authorName={authorName}
          authorImage={authorImage}
          entriesAuthors={entriesAuthors}
          structuredContent={structuredContent}
          publication={publication}
          feedSettings={feedSettings}
        />
      );
    }

    if (isEntrySupported(structuredContent)) {
      return (
        <EntryStructuredContent
          structuredContent={structuredContent}
          publication={publication}
        />
      );
    }

    if (isChat) {
      return (
        <ChatContent
          content={ParsedContent}
          authorName={authorName}
          authorImage={authorImage}
          authorType={authorType}
          structuredContent={structuredContent}
          publication={publication}
          feedSettings={feedSettings}
        />);
    }

    return ParsedContent;
  }

  render() {
    const { theme } = this.props;
    return (
      <div className={theme.entryContentWrapper}>
        { this.getEntryContentView() }
      </div>
    );
  }
}
EntryContent.propTypes = {
  content: PropTypes.string.isRequired,
  authorName: PropTypes.string,
  authorImage: PropTypes.string,
  authorType: PropTypes.string,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  publication: PropTypes.string.isRequired,
  sourceName: PropTypes.string.isRequired,
  isChat: PropTypes.bool,
  entriesAuthors: PropTypes.arrayOf(PropTypes.string),
  structuredContent: PropTypes.object,
  feedSettings: FeedSettingsShape,
};

EntryContent.defaultProps = {
  isChat: false,
  authorName: null,
  authorImage: null,
  authorType: null,
  theme: {},
  entriesAuthors: [],
  structuredContent: {},
  feedSettings: FeedSettingsDefaultProps,
};
export default themer('EntryContent')(EntryContent);
