import { prepareEntries as _prepareEntries } from './apiHelpers';
import { findLastIds as _findLastIds } from './findLastIds';
import { determineQuantitativeFlags as _determineQuantitativeFlags } from './determineQuantitativeFlags';
import {
  handleUpdateLiveblogMessage as _getLiveblogProperties,
  handleUpdateFeedMessage as _getFeedProperties,
} from '../messages';
import { isEmptyObject } from './isEmptyObject';

export const createHandleApiData = (
  prepareEntries = _prepareEntries,
  findLastIds = _findLastIds,
  getLiveblogProperties = _getLiveblogProperties,
  getFeedProperties = _getFeedProperties,
  determineQuantitativeFlags = _determineQuantitativeFlags,
) => (
  apiData,
  append,
  currentEntries,
  currentAuthors,
  user,
  feedId,
) => {
  if (!apiData || isEmptyObject(apiData)) {
    return {};
  }

  const {
    meta: {
      count = 0,
      entriesAuthors,
    } = {},
    liveblog = {},
    entries: rawEntries = [],
  } = apiData;

  let fetchedEntries = currentEntries;

  if (rawEntries.length > 0) {
    const sportsMeta = liveblog.eventId ? liveblog.meta : null;
    const normalizedNewEntries = prepareEntries(rawEntries, user, sportsMeta);

    if (append) {
      const currentIds = currentEntries.map(({ id }) => id);

      const filteredNewEntries = normalizedNewEntries.filter(({ id }) => !currentIds.includes(id));

      fetchedEntries = [...currentEntries, ...filteredNewEntries];
    } else {
      fetchedEntries = normalizedNewEntries;
    }
  }

  const liveblogSettings = getLiveblogProperties(liveblog);

  const feeds = Array.isArray(liveblog.feeds) ? liveblog.feeds : [];

  const currentFeed = feedId
    ? feeds.find(feed => feed.id === feedId)
    : feeds.find(feed => feed.placement === 1);

  const feedSettings = getFeedProperties(currentFeed);

  return {
    ...liveblogSettings,
    ...feedSettings,
    ...findLastIds(fetchedEntries),
    ...determineQuantitativeFlags(fetchedEntries, count),
    liveblogDataLoaded: true,
    entriesCount: count,
    isFetchingBelow: false,
    fetchedEntries,
    entriesAuthors: entriesAuthors || currentAuthors,
    liveblogData: liveblog,
  };
};
