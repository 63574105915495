export const SOCIAL = {
  TYPES: {
    FACEBOOK: 'facebook',
    INSTAGRAM: 'instagram',
    TWITTER: 'twitter',
  },
};

export const USER = {
  TYPES: {
    JOURNALIST: 'JOURNALIST',
    GUEST: 'GUEST',
  },
};

export const LIVEFEED_THEMES = {
  DEFAULT: 'DEFAULT',
  SPEECH_BUBBLES: 'SPEECH_BUBBLES',
};

export const POLLING_STRATEGIES = {
  NORMAL: 'NORMAL',
  SLOW: 'SLOW',
  SLOWER: 'SLOWER',
  MINIMAL: 'MINIMAL',
};

export const SOURCE_NAMES = {
  LIVE: 'LIVE',
  DIREKTE_VG: 'DIREKTE_VG',
  NTB: 'NTB',
  TT: 'TT',
  AUTO_RELATED_ARTICLE: 'AUTO_RELATED_ARTICLE',
};
