import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import SportsIcon from '@core-live/live-sports-icons';

import getHeadlineText from '../utils/headlineFactory';
import { elapsedShape, sportsMetaShape } from '../../../utils/shapes';
import SportsEntryTime from '../SportsEntryTime/SportsEntryTime';

export const SportsEntryHeadline = ({ elapsed, type, subtype, meta, theme, publication }) => (
  <div className={theme.sportsEntryHeadline}>
    <div className={`${theme.sportsEntryIcon} ${theme[type]}`}>
      <SportsIcon type={type} subtype={subtype} />
    </div>
    <SportsEntryTime elapsed={elapsed} />
    <span className={theme.sportsHeadlineText}>
      {getHeadlineText(type, subtype, meta, publication)}
    </span>
  </div>
);


SportsEntryHeadline.propTypes = {
  type: PropTypes.string.isRequired,
  subtype: PropTypes.string,
  meta: PropTypes.shape(sportsMetaShape),
  elapsed: PropTypes.shape(elapsedShape).isRequired,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  publication: PropTypes.string.isRequired,
};

SportsEntryHeadline.defaultProps = {
  theme: {},
};

export default themer('SportsEntryHeadline')(SportsEntryHeadline);
