import HTML from 'html-parse-stringify';

const commonConditions = {
  isUserQuote: ({ type, name, attrs } = {}) =>
    (type === 'tag' && name === 'blockquote' && (Object.keys(attrs).length === 0 || !!attrs['data-id'])),
  isJournalistReply: ({ type, children, name } = {}) => (type === 'tag' && (children.length > 0 || name !== 'p')),
  isEntryContent: ({ type } = {}) => (type === 'tag'),
};

/*
 * at least one user quote and one reply from a journalist
 * this function is used to make sure that we don't show two avatar in one entry
*/
const isUserJournalistChat = (content) => {
  const parsed = HTML.parse(`<span>${content}</span>`)[0].children;

  const { quotesCount, repliesCount } = parsed.reduce((statsData, childElement) => {
    const { type, name, attrs, children } = childElement;

    if (commonConditions.isUserQuote({ type, name, attrs })) {
      return { ...statsData, quotesCount: statsData.quotesCount + 1 };
    }

    if (commonConditions.isJournalistReply({ type, children, name })) {
      return { ...statsData, repliesCount: statsData.repliesCount + 1 };
    }

    return statsData;
  }, { quotesCount: 0, repliesCount: 0 });

  return quotesCount >= 1 && repliesCount >= 1;
};

const isUserQuote = (content) => {
  const parsed = HTML.parse(`<span>${content}</span>`)[0].children;

  const evalContent = parsed.map((el) => {
    if (el.type === 'tag' && el.name === 'blockquote') {
      return true;
    }

    if (el.type === 'tag' && el.name === 'p') {
      return el.children.length === 0;
    }

    // eslint-disable-next-line no-control-regex
    const regexWhitespace = RegExp('^(\\s+|\r|\n|(\r\n))');
    if (el.type === 'text' && regexWhitespace.test(el.content)) {
      return true;
    }

    return false;
  });

  const hasOnlyBlockquote = evalContent.find(el => !el) === undefined;
  if (!hasOnlyBlockquote) {
    return false;
  }

  const hasAuthorRegex = RegExp('comment-author');
  const hasAuthor = content.match(hasAuthorRegex);

  return hasAuthor !== null;
};

export {
  commonConditions,
  isUserQuote,
  isUserJournalistChat,
};
