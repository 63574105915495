import Eventhub from 'eventhub-jsclient';

import _parseMessage from '../parseMessage';
import _config from './config';

const Client = (
  {
    Service = Eventhub,
    config = _config,
    parseMessage = _parseMessage,
  } = {},
) => {
  const {
    endpoint,
    token,
    pingInterval,
    pingTimeout,
    maxFailedPings,
    disablePingCheck,
  } = config;

  let connection = null;

  const onConnect = listener => connection.on('connect', listener);
  const onOffline = listener => connection.on('goesOffline', listener);
  const onReconnect = listener => connection.on('reconnect', listener);

  const initializeConnection = (reconnectPeriod) => {
    if (connection) {
      return;
    }

    connection = new Service(
      endpoint,
      token,
      {
        pingInterval,
        pingTimeout,
        maxFailedPings,
        disablePingCheck,
        reconnectInterval: reconnectPeriod,
      },
    );

    connection
      .connect()
      .catch((error) => {
        let reconnectInterval;

        const reconnectCallback = () => {
          connection._emitter.emit('reconnect');

          connection
            .connect()
            .then(() => {
              clearTimeout(reconnectInterval);
            }).catch(() => {
              reconnectInterval = setTimeout(reconnectCallback, reconnectPeriod);
            });
        };

        reconnectInterval = setTimeout(reconnectCallback, reconnectPeriod);
        // eslint-disable-next-line no-console
        console.error('Unable to connect with EventHub', error);
      });

    // eslint-disable-next-line no-console
    onConnect(() => { console.log('[EventHub Client] connected'); });

    // eslint-disable-next-line no-console
    onOffline(() => { console.log('[EventHub Client] offline'); });

    // eslint-disable-next-line no-console
    onReconnect(() => { console.log('[EventHub Client] reconnect'); });
  };

  const onTopicMessage = (topic, callback) => {
    if (!connection) {
      throw new Error('[EventHub Client] Trying to subscribe, but connection is not initialized');
    }

    const onMessage = ({ topic: topicName, message: rawMessage }) => {
      if (topicName === topic) {
        const message = parseMessage(rawMessage);

        callback(message);
      }
    };

    connection.on('connect', () => {
      if (!connection.isSubscribed(topic)) {
        connection.subscribe(topic, onMessage);
      }
    });
  };

  const endConnection = () => {
    if (connection) {
      if (connection._emitter) {
        connection._emitter.emit('goesOffline');
      }

      if (connection.disconnect) {
        connection.disconnect().catch(() => {
          // The error is ignored. If the disconnection fails, it means that the whole connection is off anyway.
        });
      }
    }
  };

  return {
    initializeConnection,
    endConnection,
    onConnect,
    onOffline,
    onReconnect,
    onTopicMessage,
  };
};

export default Client;
