export default ({ css, defaultStyles }) => {
  const { PinnedEntry } = defaultStyles;

  const pinnedEntry = css`
    ${PinnedEntry.pinnedEntry};

    color: var(--c-dark, #333);
  `;

  const itemSeparator = css`
    ${PinnedEntry.itemSeparator};

    border-top-color: var(--c-neutral-03, #cbcbcb);
  `;

  const styledAvatar = css`
    ${PinnedEntry.styledAvatar};
  `;

  const styles = {
    ...PinnedEntry,
    pinnedEntry,
    itemSeparator,
    styledAvatar,
  };

  return {
    styles,
  };
};
