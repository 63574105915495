import { findLastId as _findLastId } from './findLastId';

export const DEFAULT_LAST_ENTRY_ID = null;

export const satisfyLastEntryId = ({ isFromInternalStorage }) => isFromInternalStorage;

export const findLastIds = (
  normalizedEntries,
  findLastId = _findLastId,
) => ({
  lastEntryId: findLastId(
    normalizedEntries,
    DEFAULT_LAST_ENTRY_ID,
    satisfyLastEntryId,
  ),
});
