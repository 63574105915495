import Entry from './Entry';
import { uniqueClassName, className } from '../functions';

export default (props) => {
  const { css } = props;

  const sportsEntryWithColor = color => css`
    margin: 0 0 15px;
    padding: 24px 0;

    @media (min-width: 768px) {
      padding: 34px 0;
    }

    &::before {
      content: "";
      position: absolute;
      background-color: ${color};
      border: none;
      left: -20px;
      right: 0;
      top: 0;
      bottom: 0;
    }
  `;

  const goal = uniqueClassName(css, 'goal')(css``);

  const periodStart = uniqueClassName(css, 'periodStart')(css``);

  const sportsEntry = css`
    line-height: 1.58em; // makes vertical alignment more consistent

    &${className(goal)} {
      ${sportsEntryWithColor('var(--c-background-05, #f0ffdf)')};
    }

    &${className(periodStart)} {
      ${sportsEntryWithColor('var(--c-background-06, #fffce1)')};
    }
  `;

  const entryTheme = Entry(props);

  return {
    styles: {
      ...entryTheme.styles,
      goal,
      periodStart,
      sportsEntry,
      sportsEntryWithColor,
    },
  };
};
