/* eslint-disable import/prefer-default-export */
export const isBrowser = () => typeof window !== 'undefined';

export const getHashFromWindow = () => {
  if (!isBrowser()) {
    return '';
  }

  return window.location.hash;
};
