import { className } from '../../functions';

import commonStyles from './common';

import {
  errorColor,
  backgroundColor,
  color,
} from './variables';

export default ({ css }) => {
  const content = css`
    font-family: inherit;
    width: 100%;
    height: 185px;
    border: none;
    outline: none;
    resize: none;
    background-color: ${backgroundColor};
    font-size: 17px;
    color: ${color};
    margin-bottom: 0;
    line-height: 28px;
    padding: 8px 16px;
    letter-spacing: -0.1px;
    border-radius: 4px 4px 0 0;
    vertical-align: top;
  `;

  const validationErrorContainer = css`
    border: 1px solid ${errorColor};
    border-radius: 2px;

    ${className(content)}::-webkit-input-placeholder {
      color: ${errorColor};
    }
  `;

  return {
    styles: {
      ...commonStyles({ css }),
      content,
      validationErrorContainer,
    },
  };
};
