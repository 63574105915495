export class ImageComponent {
  constructor(structuredData) {
    const {
      caption,
      byline,
      imageAsset: {
        crop,
        urls,
      },
    } = structuredData;

    this.caption = caption ? caption.value : null;
    this.byline = byline ? byline.title : null;
    this.crop = crop || null;
    this.urls = urls;
  }

  getCaption() {
    return this.caption;
  }

  getByline() {
    return this.byline;
  }

  getCrop() {
    return this.crop;
  }

  getDefaultUrl() {
    const { url } = this.urls.find(imageUrl => imageUrl.default) || this.urls[0];

    return url;
  }

  getSrcset() {
    return this.urls
      .filter(({ width }) => Boolean(width))
      .map(({ url, width }) => `${url} ${width}w`)
      .join(', ');
  }
}
