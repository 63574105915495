export default ({ css, defaultStyles }) => {
  const { EntryTimeMain } = defaultStyles;

  const timeMain = css`
    ${EntryTimeMain.timeMain};

    color: var(--c-primary-01, rgba(221,42,48));
    font-size: 14px;
    font-weight: bold;
    padding-left: 0;
  `;

  return {
    styles: {
      ...EntryTimeMain,
      timeMain,
    },
  };
};
