import { SUBTYPES, TYPES } from './const';
import { getTexts } from '../../../config';

const getHeadlineTxt = (key, texts) => texts[key] || '';

const getOpponentName = (teamName, { currentScores = {} }) =>
  Object.keys(currentScores).find(name => name !== teamName) || '';

const headlineFactory = (type, subtype, meta = {}, publication) => {
  const { participants = {} } = meta;
  const texts = getTexts(publication, 'headlineFactory', 'SportsLive');

  switch (type) {
    case TYPES.EVENT_START:
    case TYPES.EVENT_END:
    case TYPES.INJURY:
      return getHeadlineTxt(subtype, texts);

    case TYPES.VIDEO_ASSISTANT_REFEREE:
    case TYPES.PAUSE_START:
      return getHeadlineTxt(type, texts);

    case TYPES.SCORE_CHANGE:
      if (subtype === SUBTYPES.GOAL_PENALTY_SHOOTOUT) {
        return getHeadlineTxt('goalPenalty', texts).replace('{team}', meta.participants.team);
      }

      if (subtype === SUBTYPES.GOAL_OWN) {
        const opponentName = getOpponentName(meta.participants.team, meta);
        return getHeadlineTxt(subtype, texts).replace('{team}', opponentName);
      }

      return getHeadlineTxt(subtype, texts).replace('{team}', meta.participants.team);

    case TYPES.OFFSIDE:
    case TYPES.SUBSTITUTION:
    case TYPES.CORNER:
    case TYPES.FREE_KICK: {
      const team = participants.team;
      if (team) {
        return getHeadlineTxt(type, texts).replace('{team}', team);
      }

      return getHeadlineTxt(`${type}Plain`, texts);
    }

    case TYPES.SHOT:
    case TYPES.PENALTY: {
      const team = participants.team;
      if (team) {
        return getHeadlineTxt(subtype, texts).replace('{team}', team);
      }

      return getHeadlineTxt(`${subtype}Plain`, texts);
    }

    case TYPES.CARD:
      return getHeadlineTxt(`${subtype}Card`, texts);

    case TYPES.PERIOD_START:
      return getHeadlineTxt(subtype, texts);


    case TYPES.PENALTY_SHOOTOUT: {
      const team = participants.team;
      if (team) {
        return getHeadlineTxt('penaltyMissed', texts).replace('{team}', team);
      }

      return getHeadlineTxt('penaltyMissedPlain', texts);
    }

    default:
      return null;
  }
};

export default headlineFactory;
