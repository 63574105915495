import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import { getTexts } from '../../../../config';

export const GeneralContent = ({
  player,
  team,
  skipIfNoPlayer,
  publication,
  theme,
}) => {
  const texts = getTexts(publication, 'GeneralContent', 'SportsLive');

  if ((!player && !team) || (!player && skipIfNoPlayer)) {
    return null;
  }

  let text;
  if (team) {
    if (player) {
      text = texts.playerAndTeam
        .replace('{team}', team)
        .replace('{player}', player);
    } else {
      text = texts.teamOnly
        .replace('{team}', team);
    }
  } else {
    text = texts.playerOnly
      .replace('{player}', player);
  }

  return (
    <div className={theme.generalContent}>
      <p>{text}</p>
    </div>
  );
};

GeneralContent.propTypes = {
  player: PropTypes.string,
  team: PropTypes.string,
  skipIfNoPlayer: PropTypes.bool,
  publication: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    generalContent: PropTypes.string,
  }),
};

export default themer('GeneralContent')(GeneralContent);
