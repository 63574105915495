export function isInstagram(node) { return (node.attrs && node.attrs.class) ? node.attrs.class.includes('instagram-media') : false; }

export function isTwitter(node) { return (node.attrs && node.attrs.class) ? node.attrs.class.includes('twitter-tweet') : false; }

export function isFacebook(node) { return (node.attrs && node.attrs.src) ? node.attrs.src.includes('facebook') : false; }

const hasAttrs = node => (node && node.attrs);

export const isSocialEmbed = (node) => {
  if (hasAttrs(node) && node.attrs.src) {
    return isFacebook(node);
  } else if (hasAttrs(node) && node.attrs.class) {
    return (isInstagram(node) || isTwitter(node));
  }

  return false;
};

export const getInstagramHref = (node) => {
  const regX = /"href":(["'])(.*?)\1/g;
  const href = regX.exec(JSON.stringify(node));

  if (!href || !href[2]) {
    return false;
  }

  return href[2];
};

export const getTweetId = (node) => {
  const regX = /status\/(\d+)\/?/i;
  const id = regX.exec(JSON.stringify(node));

  if (!id || !id[1]) {
    return false;
  }

  return id[1];
};

export const getFbHref = (node) => {
  const regX = /href=(.*?)&.+$/g;
  const match = regX.exec(JSON.stringify(node));
  const href = match && match[1] ? decodeURIComponent(match[1]) : false;

  return href;
};
