/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import { resolveMarkup } from '@rnd-live/markup-to-jsx';
import get from 'lodash.get';

import {
  IMAGE_TYPE, TEXT_TYPE, ImageComponent,
} from '../structuredContent';

export const EntryUserMessage = ({
  theme,
  userMessage,
}) => {
  const {
    components = [],
  } = userMessage || {};

  const content = components
    .filter(({ type }) => type === TEXT_TYPE)
    // eslint-disable-next-line react/no-array-index-key
    .map(({ markup, value }, index) => <p key={`paragraph-${index}`}>{resolveMarkup(value, markup)}</p>)
  ;

  const images = components
    .filter(({ type }) => type === IMAGE_TYPE)
    .map((structuredData, index) => {
      const image = new ImageComponent(structuredData);

      return (
        <img
          src={image.getDefaultUrl()}
          className={theme.userMessageImage}
          alt="User photography"
          srcSet={image.getSrcset()}
          // eslint-disable-next-line react/no-array-index-key
          key={`image-${index}`}
        />
      );
    });

  const author = get(userMessage, 'author.title');

  return (
    <div className={theme.userMessageWrapper}>
      {images}
      <div
        className={theme.userMessageText}
      >
        {content}
      </div>
      <div className={`${theme.userMessageAuthor} userMessageAuthor`}>
        {author}
      </div>
    </div>
  );
};

EntryUserMessage.propTypes = {
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  userMessage: PropTypes.shape({
    components: PropTypes.array.isRequired,
    author: PropTypes.shape({
      title: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

EntryUserMessage.defaultProps = {
  theme: {},
};

export default themer('EntryUserMessage')(EntryUserMessage);
