import { normalizeDecoratedEntry as _normalizeDecoratedEntry } from '../../utils/normalizeDecoratedEntry';

export const createHandlePublishEntryMessage = (
  normalizeDecoratedEntry = _normalizeDecoratedEntry,
) => (
  decoratedNewEntry,
  currentNormalizedEntries,
  currentEntriesCount,
  currentEntriesAuthors,
) => {
  const { id } = decoratedNewEntry;

  const entriesAuthors = [...currentEntriesAuthors];

  // due to MQTT protocol and its QoS the same message can be delivered more than once
  // this behaviour relies on MQTT broker itself and also subscription type requested by a client
  if (currentNormalizedEntries.findIndex(entry => entry.id === id) > -1) {
    return {
      updatedEntries: currentNormalizedEntries,
      entriesCount: currentEntriesCount,
      entriesAuthors,
    };
  }

  const precedingEntry = currentNormalizedEntries[0];

  const normalizedNewEntry = normalizeDecoratedEntry(
    decoratedNewEntry,
    precedingEntry,
  );

  const updatedEntries = [normalizedNewEntry, ...currentNormalizedEntries];

  const { authorName } = normalizedNewEntry;

  if (!entriesAuthors.includes(authorName)) {
    entriesAuthors.push(authorName);
  }

  return {
    updatedEntries,
    entriesCount: currentEntriesCount + 1,
    entriesAuthors,
  };
};
