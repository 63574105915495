export const findLastId = (
  normalizedEntries,
  defaultId,
  doesEntrySatisfy,
) => {
  const entryWithLastId = [...normalizedEntries]
    .reverse()
    .find(normalizedEntry => doesEntrySatisfy(normalizedEntry));

  return entryWithLastId ? entryWithLastId.id : defaultId;
};
