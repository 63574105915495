export default ({ css, defaultStyles }) => {
  const { EntryUserMessage } = defaultStyles;

  const userMessageText = css`
    ${EntryUserMessage.userMessageText};
    background-color: var(--c-neutral-03, #e7eaed);
    &::after {
      border-bottom-color: var(--c-neutral-03, #e7eaed);
    }
`;

  return {
    styles: {
      ...EntryUserMessage,
      userMessageText,
    },
  };
};
