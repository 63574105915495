export default ({ css }) => {
  const dateWithAvatar = css`
    margin-left: 0;
  `;

  return {
    styles: {
      dateWithAvatar,
    },
  };
};
