export default ({ css }) => {
  const icon = css`
    width: 30px !important; // because imgs width is being overrided by farticles styles
    height: 30px;
    position: relative;
    top: 6px;
  `;

  const whistle = css`
    width: 33px !important;
    top: 6px;
    left: -2px;
  `;

  const generalhighlight = css`
    left: 1px;
  `;

  const offside = css`
    width: 27px !important;
    left: 1px;
    top: 7px;
  `;

  const corner = css`
    width: 25px !important;
    height: 25px;
    top: 10px;
    left: 3px;
  `;

  const videoassistantreferee = css`
    height: auto;
    top: 12px;
  `;

  return {
    styles: {
      icon,
      whistle,
      generalhighlight,
      offside,
      corner,
      videoassistantreferee,
    },
  };
};
