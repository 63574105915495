import React from 'react';
import { themer } from '@core-live/theme-provider';
import PropTypes from 'prop-types';

import CopyIcon from '../Icons/CopyIcon';

import { withCopyToClipboard } from './HOC/copyToClipboard';
import { getTexts } from '../../config';

const CopyToClipboardButton = ({ handleOnClick, theme, linkCopied, publication }) => {
  const texts = getTexts(publication, 'CopyToClipboard');

  const copiedToClipboardToastView = linkCopied ?
    (
      <div className={theme.copiedToast}>
        { texts.copiedText }
      </div>
    )
    : null;

  return (
    <div className={theme.copyToClipboardWrapper}>
      <i onClick={handleOnClick} className={theme.copyButton} role="button" tabIndex={0}>
        { texts.copyButton }
        <CopyIcon />
      </i>
      { copiedToClipboardToastView }
    </div>
  );
};

CopyToClipboardButton.propTypes = {
  publication: PropTypes.string.isRequired,
  linkCopied: PropTypes.bool,
  handleOnClick: PropTypes.func,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

CopyToClipboardButton.defaultProps = {
  linkCopied: false,
  theme: {},
  handleOnClick: () => {},
};

export default themer('CopyToClipboard')(withCopyToClipboard(CopyToClipboardButton));
