import React from 'react';

import { TYPES } from './const';

import GeneralContent from '../ContentTypes/GeneralContent/GeneralContent';
import GoalContent from '../ContentTypes/GoalContent/GoalContent';
import PlayerSubstitutionContent from '../ContentTypes/PlayerSubstitutionContent/PlayerSubstitutionContent';

const contentFactory = (type, subtype, meta, publication) => {
  switch (type) {
    case TYPES.SCORE_CHANGE: {
      const { currentScores, participants: { scorer, assistant } } = meta;

      return (<GoalContent
        currentScores={currentScores}
        scorer={scorer}
        assistant={assistant}
        publication={publication}
      />);
    }

    case TYPES.OFFSIDE: {
      const { participants: { player, team } } = meta;
      return (<GeneralContent
        player={player}
        team={team}
        skipIfNoPlayer
        publication={publication}
      />);
    }

    case TYPES.CARD:
    case TYPES.INJURY: {
      const { participants: { player, team } } = meta;
      return <GeneralContent player={player} team={team} publication={publication} />;
    }

    case TYPES.SUBSTITUTION: {
      const { participants: { playerIn, playerOut } } = meta;
      return (<PlayerSubstitutionContent
        playerIn={playerIn}
        playerOut={playerOut}
      />);
    }

    default:
      return null;
  }
};

export default contentFactory;
