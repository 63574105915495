export default ({ css }) => {
  const goalCurrentScores = css`
    color: #333333;
  `;

  return {
    styles: {
      goalCurrentScores,
    },
  };
};
