import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import Button from './Button/Button';
import ButtonType from './Button/Types';
import Input from './Input/Input';
import TextArea from './TextArea/TextArea';
import FileUpload from '../FileUpload/FileUpload';
import StatusLabel from './StatusLabel/StatusLabel';
import { TYPE_SUCCESS, TYPE_ERROR } from './StatusLabel/Types';

import {
  checkIfNotEmpty,
  checkIfContentProvided,
} from './../../utils/formValidators';
import { isBrowser } from './../../utils/env';
import { getTexts } from '../../config';
import { LoggedInUserShape } from '../../shapes/LoggedInUser';

import UploadedFiles from './UploadedFiles/UploadedFiles';
import {
  BlogSettingsDefaultProps,
  BlogSettingsShape,
  FeedSettingsDefaultProps,
  FeedSettingsShape,
} from '../../shapes';

const SUPPORTED_MIME_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/heif',
  'image/heic',
  'image/heif-sequence',
  'image/heic-sequence',
];

const MAX_SIZE_IN_BYTES = 10000000;

export class MessageForm extends Component {
  constructor(props) {
    super(props);

    const fields = {
      message: { value: '', validated: true, validators: [checkIfContentProvided] },
      name: { value: '', validated: true, validators: [checkIfNotEmpty], shouldBeStatic: true },
    };

    this.state = {
      formVisibility: false,
      successMessage: false,
      failureMessage: false,
      image: null,
      fields,
    };

    this.onSend = this.onSend.bind(this);
    this.handleDropImage = this.handleDropImage.bind(this);
    this.handleRemoveUploadedFile = this.handleRemoveUploadedFile.bind(this);
    this.setFieldValueFromEvent = this.setFieldValueFromEvent.bind(this);
  }

  getUserSessionData() {
    if (isBrowser() && window.SPiD && window.SPiD.sessionCache && typeof window.SPiD.sessionCache.get === 'function') {
      return Promise.resolve(window.SPiD.sessionCache.get());
    }

    if (isBrowser() && window.Identity && typeof window.Identity.getUser === 'function') {
      return window.Identity.getUser();
    }

    return Promise.resolve(null);
  }

  getSpidName() {
    return this.getUserSessionData()
      .then((data) => {
        if (!data) {
          return '';
        }

        const { givenName, familyName } = data;

        if (givenName && familyName) {
          return `${givenName} ${familyName}`;
        }

        if (familyName) {
          return familyName;
        }

        if (givenName) {
          return givenName;
        }

        return '';
      })
      .catch(() => '');
  }

  onSend(e) {
    if (!this.validate()) {
      return;
    }

    const { postMessageHandler } = this.props;
    const { sendButtonDisabled, fields: { message, name }, image } = this.state;

    if (sendButtonDisabled) {
      return;
    }

    this.context.trackEvent(e);

    const values = {
      message: message.value,
      userName: name.value,
      image,
    };

    postMessageHandler(values)
      .then(({ status }) => (
        status === 201
          ? this.toggleSuccessMessage()
          : this.toggleFailureMessage()
      ))
      .catch(() => this.toggleFailureMessage());

    this.hideForm();
    this.clearFields();
  }

  setFieldValueFromEvent(event) {
    event.preventDefault();

    const name = event.target.name;
    const value = event.target.value;
    const { fields } = this.state;
    const modifiedField = Object.assign(fields[name], { value });

    this.setState({ fields: Object.assign(fields, { [name]: modifiedField }) });
  }

  toggleSuccessMessage() {
    this.setState({ successMessage: true },
      () => setTimeout(() => this.setState({ successMessage: false }), 3000));
  }

  toggleFailureMessage() {
    this.setState({ failureMessage: true },
      () => setTimeout(() => this.setState({ failureMessage: false }), 3000));
  }

  clearFields() {
    const { fields } = this.state;

    Object.keys(fields)
      .filter(field => !fields[field].shouldBeStatic)
      .forEach((field) => {
        fields[field].value = '';
        fields[field].validated = true;
      });

    this.setState({ fields, image: null });
  }

  showForm() {
    this.getSpidName()
      .then((spidName) => {
        const nameField = Object.assign({}, this.state.fields.name, { value: spidName });

        this.setState(() => ({
          fields: Object.assign({}, this.state.fields, { name: nameField }),
          formVisibility: true,
        }));
      });
  }

  handleDropImage(images = []) {
    const [image] = images;

    if (!image) {
      return;
    }

    if (!SUPPORTED_MIME_TYPES.includes(image.type) || image.size > MAX_SIZE_IN_BYTES) {
      this.toggleFailureMessage();

      return;
    }

    this.setState({ image });
  }

  handleRemoveUploadedFile() {
    this.setState({ image: null });
  }

  hideForm() {
    this.clearFields();
    this.setState({ formVisibility: false });
  }

  validate() {
    const { fields, image } = this.state;

    const formValidationResult = Object.keys(fields).map((key) => {
      const field = fields[key];

      const fieldValidationResult = field.validators.map(validator => validator(field.value, image));

      if (!fieldValidationResult || fieldValidationResult.length === 0) {
        return true;
      }

      const validated = !fieldValidationResult.includes(false);

      const modifiedField = Object.assign(fields[key], { validated });

      this.setState({ fields: Object.assign(fields, { [key]: modifiedField }) });

      return validated;
    });

    return !formValidationResult.includes(false);
  }

  render() {
    const { formVisibility, fields, successMessage, failureMessage, image } = this.state;

    const {
      theme,
      isPostMessageButtonVisible,
      publication,
      loggedInUser,
      forceUserMessagesLoginDisabling,
      blogSettings,
      feedSettings,
    } = this.props;

    const texts = getTexts(publication, 'MessageForm');

    const shouldShowActionsRelatedToLogin = !forceUserMessagesLoginDisabling
      && blogSettings.isUserMessagesLoginRequired
      && !loggedInUser.isLoggedIn;

    const actionsView = blogSettings.closed || shouldShowActionsRelatedToLogin
      ? null
      : (
        <div className={theme.actions}>
          <Button
            content={feedSettings.actionText}
            visibility={isPostMessageButtonVisible && !formVisibility}
            onClick={() => this.showForm()}
          />
          <Button
            content={texts.abortButtonContent}
            visibility={formVisibility}
            onClick={() => this.hideForm()}
            disabled
          />
        </div>
      );

    const descriptionView = blogSettings.closed
      ? texts.closedPostMessageDescription
      : feedSettings.description;

    const descriptionViewContainer = (
      <div className={theme.messageFormDescription}>
        <span>
          {descriptionView}
        </span>

        {actionsView}
      </div>);

    const formView = formVisibility ? (
      <div className={theme.form}>
        <div className={theme.contentContainer}>
          <div className={theme.textareaWrapper}>
            <TextArea
              name="message"
              placeholder={texts.messagePlaceholder}
              errorMessage={texts.messageErrorMessage}
              validated={fields.message.validated}
              onChangeParentHandler={this.setFieldValueFromEvent}
              value={fields.message.value}
              maxLength={600}
            />
          </div>
          <Input
            name="name"
            placeholder={texts.namePlaceholder}
            errorMessage={texts.nameErrorMessage}
            validated={fields.name.validated}
            onChangeParentHandler={this.setFieldValueFromEvent}
            value={fields.name.value}
            maxLength={30}
          />
        </div>

        <div className={theme.actionContainer}>
          <FileUpload
            acceptableFileTypes={SUPPORTED_MIME_TYPES.join(', ')}
            onChange={this.handleDropImage}
            publication={publication}
            preview={image}
          />

          <Button
            content={texts.sendButtonContent}
            visibility={formVisibility}
            onClick={this.onSend}
            buttonType={ButtonType.RAISED}
            customClass={'liveblog-button-send-message'}
          />
        </div>
      </div>) : null;

    const uploadedFilesView = formVisibility ?
      (
        <UploadedFiles
          uploadedFiles={[image]}
          removeUploadedFile={this.handleRemoveUploadedFile}
        />
      ) : null;

    return (
      <div className={theme.messageFormContainer}>
        {descriptionViewContainer}

        <div>
          <StatusLabel
            content={texts.statusLabelSuccessContent}
            statusType={TYPE_SUCCESS}
            visibility={successMessage}
          />

          <StatusLabel
            content={texts.statusLabelFailureContent}
            statusType={TYPE_ERROR}
            visibility={failureMessage}
          />
        </div>

        { formView }

        { uploadedFilesView }
      </div>
    );
  }
}

MessageForm.propTypes = {
  postMessageHandler: PropTypes.func.isRequired,
  isPostMessageButtonVisible: PropTypes.bool,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  publication: PropTypes.string.isRequired,
  loggedInUser: LoggedInUserShape,
  forceUserMessagesLoginDisabling: PropTypes.bool,
  blogSettings: BlogSettingsShape,
  feedSettings: FeedSettingsShape,
};

MessageForm.defaultProps = {
  theme: {},
  loggedInUser: {},
  forceUserMessagesLoginDisabling: false,
  blogSettings: BlogSettingsDefaultProps,
  feedSettings: FeedSettingsDefaultProps,
};

MessageForm.contextTypes = {
  trackEvent: PropTypes.func,
};

export default themer('MessageForm')(MessageForm);
