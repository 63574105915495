import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import { getTexts } from '../../config';
import { LoggedInUserShape } from '../../shapes/LoggedInUser';
import _checkIfShouldForceLogIn from './checkIfShouldForceLogIn';
import { BlogSettingsDefaultProps, BlogSettingsShape } from '../../shapes';

const LoadMoreEntries = ({
  isFetchingBelow,
  handleFetchMoreEntries,
  theme,
  publication,
  loggedInUser,
  blogSettings,
  checkIfShouldForceLogIn,
},
{
  trackEvent,
}) => {
  const {
    loadMoreText,
    loginToLoadMoreText,
    isLoadingText,
  } = getTexts(publication, 'LoadMoreEntries');

  let buttonText = loadMoreText;

  const shouldForceLogIn = checkIfShouldForceLogIn(
    blogSettings, loggedInUser,
  );

  if (shouldForceLogIn) {
    buttonText = loginToLoadMoreText;
  }

  if (isFetchingBelow) {
    buttonText = isLoadingText;
  }

  let classList = `liveblog-button-load-more-entries ${theme.loadMoreButton}`;

  if (shouldForceLogIn) {
    classList = `liveblog-button-load-more-entries-login-action ${theme.loadMoreButton}`;
  }

  return (
    <div className={theme.loadMoreWrapper}>
      <button
        className={classList}
        onClick={(e) => {
          trackEvent(e);

          if (shouldForceLogIn) {
            return loggedInUser.logIn();
          }

          return handleFetchMoreEntries();
        }}
      >
        {buttonText}
      </button>
    </div>
  );
};

LoadMoreEntries.propTypes = {
  handleFetchMoreEntries: PropTypes.func.isRequired,
  publication: PropTypes.string.isRequired,
  isFetchingBelow: PropTypes.bool,
  loggedInUser: LoggedInUserShape,
  checkIfShouldForceLogIn: PropTypes.func,
  theme: PropTypes.object,
  blogSettings: BlogSettingsShape,
};

LoadMoreEntries.defaultProps = {
  isFetchingBelow: false,
  loggedInUser: {},
  theme: {},
  blogSettings: BlogSettingsDefaultProps,
  checkIfShouldForceLogIn: _checkIfShouldForceLogIn,
};

LoadMoreEntries.contextTypes = {
  trackEvent: PropTypes.func,
};

export default themer('LoadMoreEntries')(LoadMoreEntries);
