import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import EntryTime from '../EntryTime/EntryTime';
import Author from '../Author/Author';

import { regularMetaShape } from '../../utils/shapes';
import {
  AuthorTypeShape,
  BlogSettingsDefaultProps,
  BlogSettingsShape,
  FeedSettingsDefaultProps,
  FeedSettingsShape,
} from '../../shapes';
import CopyToClipboardButton from '../CopyToClipboard/CopyToClipboardButton';
import DisplayConditionallyForAB from '../CopyToClipboard/DisplayConditionallyForAB';

export const EntryMetaInfo = ({
  authorName,
  authorImage,
  authorType,
  date,
  addedWithinMatch,
  matchMeta,
  isUserQuote,
  isChat,
  theme,
  publication,
  pinnedUrl,
  id,
  blogSettings,
  feedSettings,
  hasEntryTitle,
}) => (
  <div className={theme.wrapper}>
    <div className={theme.entryMetaInfo}>
      <Author
        authorName={authorName}
        authorImage={authorImage}
        authorType={authorType}
        isUserQuote={isUserQuote}
        isChat={isChat}
        feedSettings={feedSettings}
        publication={publication}
      />
      <EntryTime
        date={date}
        addedWithinMatch={addedWithinMatch}
        matchMeta={matchMeta}
        publication={publication}
        blogSettings={blogSettings}
        hasAuthorWithAvatar={!!authorImage && !isChat}
      />
    </div>
    <DisplayConditionallyForAB
      publication={publication}
      hasEntryTitle={hasEntryTitle}
    >
      <CopyToClipboardButton
        id={id}
        pinnedUrl={pinnedUrl}
        publication={publication}
      />
    </DisplayConditionallyForAB>
  </div>
);

EntryMetaInfo.propTypes = {
  authorName: PropTypes.string,
  authorImage: PropTypes.string,
  authorType: AuthorTypeShape,
  date: PropTypes.string,
  addedWithinMatch: PropTypes.bool,
  matchMeta: PropTypes.shape(regularMetaShape),
  isUserQuote: PropTypes.bool,
  isChat: PropTypes.bool,
  theme: PropTypes.shape({
    wrapper: PropTypes.string,
    entryMetaInfo: PropTypes.string,
  }),
  publication: PropTypes.string.isRequired,
  pinnedUrl: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  blogSettings: BlogSettingsShape,
  feedSettings: FeedSettingsShape,
  hasEntryTitle: PropTypes.bool,
};

EntryMetaInfo.defaultProps = {
  isChat: false,
  blogSettings: BlogSettingsDefaultProps,
  feedSettings: FeedSettingsDefaultProps,
  hasEntryTitle: false,
};

export default themer('EntryMetaInfo')(EntryMetaInfo);
