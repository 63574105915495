export default ({ css, defaultStyles }) => {
  const { GoalContent } = defaultStyles;

  const goalCurrentScores = css`
    ${GoalContent.goalCurrentScores};

    color: var(--c-neutral-07, #333);
  `;

  return {
    styles: {
      ...GoalContent,
      goalCurrentScores,
    },
  };
};
