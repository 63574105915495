export default ({ css }) => {
  const sportsEntryHeader = css`
    font-size: 17px;
    display: flex;
    align-items: flex-start;
  `;

  return {
    styles: {
      sportsEntryHeader,
    },
  };
};
