/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import _get from 'lodash.get';

import Liveblog, { LiveblogSummary } from '@core-live/live-liveblog';
import PollingConnector from '@core-live/live-polling-connector';
import { EVENT_TYPES } from '@core-live/realtime-connector';

import { EntryActionsShape } from './shapes/EntryActions';
import { getDefaultContainerState as _getDefaultContainerState } from './utils/getDefaultContainerState';
import { resolveFeed as _resolveFeed } from './feeds';

import {
  get,
  post,
  prepareEntry,
  removeLimitParamFromUrl,
  getParamsForEntriesEndpoint,
  getLimitParamFromUrl,
  appendToQueryString,
  decorateRawEntry,
  createHandleApiData,
  isEmptyObject,
} from './utils';

import ApiCalls from './utils/apiCalls';

import {
  assembleCacheVersion,
  createMessageHandlers,
  createHandlePinnedEntryModification,
} from './messages';

import {
  createHandleLoggedInUserData,
} from './loggedInUser';

import { UserDefaultProps, UserShape } from './shapes/User';

import OnErrorComponent from './OnErrorComponent';

import LiveblogRealtimeConnector from './messages/LiveblogRealtimeConnector';

class LiveblogRealtimeContainer extends Component {
  // eslint-disable-next-line no-undef
  isComponentMounted = false;

  constructor(props) {
    super(props);

    const {
      interval,
      publication,
      liveId,
      isStageMode,
      prefetchedData,
      fetchEntriesDelay,
      getXhrFunction,
      resolveFeed,
      getDefaultContainerState,
    } = this.props;

    this.getLiveblogData = this.getLiveblogData.bind(this);
    this.updatePinnedEntry = this.updatePinnedEntry.bind(this);
    this.postMessageHandler = this.postMessageHandler.bind(this);
    this.updateCacheVersion = this.updateCacheVersion.bind(this);
    this.fetchMoreEntriesHandler = this.fetchMoreEntriesHandler.bind(this);
    this.getLiveblogDataUrl = this.getLiveblogDataUrl.bind(this);
    this.fetchLiveblogEntries = this.fetchLiveblogEntries.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.initLiveblog = this.initLiveblog.bind(this);
    this.resetLiveblog = this.resetLiveblog.bind(this);
    this.handleApiData = createHandleApiData();

    const {
      handlePublishEntryMessage,
      handleUpdateEntryMessage,
      handleDeleteEntryMessage,
      handleUpdateLiveblogMessage,
      handleUpdateFeedMessage,
    } = createMessageHandlers();

    const handleLoggedInUserData = createHandleLoggedInUserData();

    this.handleUpdateLiveblogMessage = handleUpdateLiveblogMessage;
    this.handlePinnedEntryModification = createHandlePinnedEntryModification();
    this.handleLoggedInUserData = handleLoggedInUserData;

    this.entryMessageTypeToHandler = {
      [EVENT_TYPES.PUBLISH_ENTRY]: handlePublishEntryMessage,
      [EVENT_TYPES.UPDATE_ENTRY]: handleUpdateEntryMessage,
      [EVENT_TYPES.DELETE_ENTRY]: handleDeleteEntryMessage,
    };

    this.feedMessageTypeToHandler = {
      [EVENT_TYPES.UPDATE_FEED]: handleUpdateFeedMessage,
    };

    const {
      getEntries,
      getPinnedEntry,
      getLiveblog,
    } = ApiCalls(getXhrFunction);

    this.getEntries = getEntries;
    this.getPinnedEntry = getPinnedEntry;
    this.resolveFeed = resolveFeed(getLiveblog);

    if (fetchEntriesDelay) {
      const randomDebounceWaitMs = (Math.random() + 0.5) * 1000;

      this.fetchLiveblogEntriesDelayed = debounce(this.fetchLiveblogEntries, randomDebounceWaitMs);
    } else {
      this.fetchLiveblogEntriesDelayed = this.fetchLiveblogEntries;
    }

    const stateFromPrefetchedData = this.prepareStateFromPrefetchedData(prefetchedData);

    const state = getDefaultContainerState(this.props);

    this.state = {
      ...state,
      ...stateFromPrefetchedData,
    };

    this.pollingFetchingStrategy = PollingConnector({
      interval,
      action: this.fetchLiveblogEntries,
    });

    const realtimeConnectorParameters = {
      publicationId: publication,
      liveblogId: liveId,
      isStageMode,
      onMessage: this.handleMessage,
      onReconnect: this.fetchLiveblogEntriesDelayed,
      fallbackStrategy: this.pollingFetchingStrategy,
    };

    this.payloadFetchingStrategy = LiveblogRealtimeConnector({
      ...realtimeConnectorParameters,
      // Added temporally for test purposes
      // TODO remove messageHandlingType when backend
      // will be serving entries adjusted to message consuming
      // for sport liveblogs
      // RND/Live#839
      messageHandlingType: 'payloadConsuming',
    });

    // TODO remove signalFetchingStrategy when backend
    // will be serving entries adjusted to message consuming
    // for sport liveblogs
    // RND/Live#839
    this.signalFetchingStrategy = LiveblogRealtimeConnector({
      ...realtimeConnectorParameters,
      onMessage: this.fetchLiveblogEntriesDelayed,
      messageHandlingType: 'fetchTriggering',
    });
  }

  prepareStateFromPrefetchedData(prefetchedData) {
    if (!prefetchedData) {
      return {};
    }

    const append = false;
    const currentEntries = [];
    const currentAuthors = [];

    const {
      user,
      disablePinnedEntry,
    } = this.props;

    const {
      liveblog: { meta = {} } = {},
      pinnedEntry: prefetchedPinnedEntry,
    } = prefetchedData;

    const { blogSettings, ...prefetchedState } = this.handleApiData(
      prefetchedData,
      append,
      currentEntries,
      currentAuthors,
      user,
    );

    if (disablePinnedEntry) {
      return prefetchedState;
    }

    const {
      fetchedEntries = [],
    } = prefetchedState;

    const pinnedEntry =
      (
        prefetchedPinnedEntry
        // this condition is to mitigate warnings
        // when Hyperion passes nullish pinned entry object
        // { id: null, title: null ... }
        && prefetchedPinnedEntry.id
        && prepareEntry(prefetchedPinnedEntry, user, meta)
      )
      || fetchedEntries.find(entry => entry.id === blogSettings.pinnedEntryId);

    return {
      ...prefetchedState,
      pinnedEntry,
    };
  }

  updateCacheVersion(message) {
    const newCacheVersion = assembleCacheVersion(message);

    this.isComponentMounted && this.setState(
      ({ cacheVersion }) => ({
        cacheVersion: newCacheVersion || cacheVersion,
      }),
    );
  }

  handleMessage(message) {
    this.updateCacheVersion(message);

    const {
      type: messageType,
      body,
    } = message;

    const { feedId } = this.state;

    if (messageType === EVENT_TYPES.UPDATE_LIVEBLOG) {
      this.isComponentMounted && this.setState(
        this.handleUpdateLiveblogMessage(body),
      );

      return;
    }

    const { user } = this.props;

    const decoratedEntry = decorateRawEntry(body, user);

    const handlerEntryMessage = this.entryMessageTypeToHandler[messageType];

    const feedMessageHandler = this.feedMessageTypeToHandler[messageType];

    if (handlerEntryMessage) {
      if (feedId && (
        decoratedEntry.feedIds
        && !decoratedEntry.feedIds.includes(feedId)
      )) {
        return;
      }

      this.isComponentMounted && this.setState(({
        fetchedEntries,
        entriesCount,
        entriesAuthors,
        blogSettings,
        pinnedEntry,
      }) => ({
        ...handlerEntryMessage(
          decoratedEntry,
          fetchedEntries,
          entriesCount,
          entriesAuthors,
        ),
        ...this.handlePinnedEntryModification(
          messageType,
          decoratedEntry,
          blogSettings,
          pinnedEntry,
        ),
      }));
    } else if (feedMessageHandler) {
      if (feedId && body.id !== feedId) {
        return;
      }

      if (!feedId && body.placement !== 1) {
        return;
      }

      this.isComponentMounted && this.setState({
        ...feedMessageHandler(body) });
    } else {
      // eslint-disable-next-line no-console
      console.log(`Handler for type ${messageType} not found`);
    }
  }

  getLiveblogDataUrl(forceLimit) {
    const { pagination } = this.props;

    const {
      liveFeedUrl,
      lastEntryId,
      cacheVersion,
      blogSettings,
    } = this.state;

    const params = getParamsForEntriesEndpoint({
      lastEntryId,
      cacheVersion,
      pagination,
      forceLimit,
      blogSettings,
    });

    return appendToQueryString(liveFeedUrl, params);
  }

  // TODO remove message parameter when backend
  // will be serving entries adjusted to message consuming
  // for sport liveblogs
  fetchLiveblogEntries(message, forceLimit) {
    if (message) {
      this.updateCacheVersion(message);
    }

    const urlWithParams = this.getLiveblogDataUrl(forceLimit);
    const append = false;

    return this.getLiveblogData(urlWithParams, append);
  }

  getLiveblogData(url, append) {
    return this.getEntries(url)
      .then(({
        data,
      }) => {
        const { user } = this.props;
        const { error, feedId } = this.state;

        this.isComponentMounted && this.setState(
          ({ fetchedEntries, entriesAuthors }) => this.handleApiData(
            data,
            append,
            fetchedEntries,
            entriesAuthors,
            user,
            feedId,
          ),
        );

        this.isComponentMounted && error && this.setState({ error: null });
      })
      .catch((error) => {
        const {
          isInitialFetchDone,
        } = this.state;

        if (isInitialFetchDone) {
          // eslint-disable-next-line no-console
          this.isComponentMounted && console.error(error);
        } else {
          this.isComponentMounted && this.setState({ error });
        }
      });
  }

  updatePinnedEntry(pinnedEntryId) {
    const {
      getSingleEntryUrlTemplate: urlTemplate,
      user,
      disablePinnedEntry,
      pinnedEntryId: pinnedEntryIdFromProps,
    } = this.props;

    const {
      fetchedEntries,
      feedId,
      liveblogData,
      blogSettings,
    } = this.state;

    if (disablePinnedEntry) {
      return;
    }

    if (feedId && !pinnedEntryIdFromProps) {
      const pinnedEntryFeedId = blogSettings.pinnedEntryFeedId || (
        liveblogData.meta && liveblogData.meta.pinnedEntryFeedId
      );

      if (pinnedEntryId && feedId !== pinnedEntryFeedId) {
        return;
      }
    }

    if (pinnedEntryId) {
      const pinnedEntry = fetchedEntries.find(entry => entry.id === pinnedEntryId);

      if (pinnedEntry) {
        this.isComponentMounted && this.setState({ pinnedEntry });

        return;
      }

      const pinnedEntryUrl = feedId
        ? appendToQueryString(urlTemplate, [['feedId', feedId]])
        : urlTemplate;

      this.getPinnedEntry(pinnedEntryUrl, pinnedEntryId)
        .then(({
          data,
        }) => {
          if (isEmptyObject(data)) {
            this.isComponentMounted && this.setState({ pinnedEntry: null });
          } else {
            const fetchedPinnedEntry = prepareEntry(data, user);

            this.isComponentMounted && this.setState({ pinnedEntry: fetchedPinnedEntry });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          this.isComponentMounted && console.error(error);
        });
    } else {
      this.isComponentMounted && this.setState({ pinnedEntry: null });
    }
  }

  postMessageHandler({ message, userName, image }) {
    const { liveId, postMessageUrl, postXhrFunction } = this.props;

    const formData = new FormData();

    formData.append('liveblogId', liveId);
    formData.append('userName', userName);

    if (message) {
      formData.append('message', message);
    }

    if (image && image.name) {
      formData.append('image', image, image.name);
    }

    return postXhrFunction(postMessageUrl, formData, {});
  }

  fetchMoreEntriesHandler() {
    this.isComponentMounted && this.setState({ isFetchingBelow: true });

    const { pagination } = this.props;
    const { liveFeedUrl, fetchedEntries, blogSettings } = this.state;

    const reversedEntries = fetchedEntries.slice().reverse();
    const oldestRegularEntry = reversedEntries.find(el => el.entryType !== 'sport');

    const params = getParamsForEntriesEndpoint({
      oldestRegularEntryId: oldestRegularEntry && oldestRegularEntry.id,
      pagination,
      blogSettings,
    });

    const urlWithParams = appendToQueryString(liveFeedUrl, params);

    this.getLiveblogData(urlWithParams, true);
  }

  initLiveblog(props) {
    this.isComponentMounted = true;

    const {
      liveFeedUrl,
      feedId,
      placement,
      prefetchedData,
      liveId,
      getLoggedInUser,
      logInHandler,
      logOutHandler,
    } = props;

    const {
      blogSettings,
      backendBaseUrl,
    } = this.state;

    this.isComponentMounted && this.handleLoggedInUserData({
      getLoggedInUser,
      logInHandler,
      logOutHandler,
    }).then(
      loggedInUser => this.setState({ loggedInUser }),
    );

    const message = null;

    this.resolveFeed({
      liveFeedUrl, feedId, placement, prefetchedData, liveId, backendBaseUrl,
    })
      .then((feed) => {
        const limit = blogSettings.isSportsLiveblog
          ? null
          : getLimitParamFromUrl(feed.url);

        const feedUrlWithoutLimit = removeLimitParamFromUrl(feed.url);

        this.isComponentMounted && this.setState(
          { liveFeedUrl: feedUrlWithoutLimit, feedId: feed.id },
          () => {
            this.fetchLiveblogEntries(message, limit).then(
              () => {
                this.isComponentMounted && this.setState({
                  isInitialFetchDone: true,
                });

                const {
                  propsPinnedEntryId,
                } = this.state;

                if (propsPinnedEntryId) {
                  this.updatePinnedEntry(propsPinnedEntryId);
                }
              },
            );
          },
        );
      });
  }

  componentDidMount() {
    this.initLiveblog(this.props);
  }

  resetLiveblog(props) {
    const stateFromPrefetchedData = this.prepareStateFromPrefetchedData(
      props.prefetchedData,
    );

    this.setState(
      () => ({
        ...props.getDefaultContainerState(props),
        ...stateFromPrefetchedData,
      }),
      () => {
        this.initLiveblog(props);
      },
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      pinnedEntry,
      isInitialFetchDone,
      propsPinnedEntryId,
      blogSettings,
    } = this.state;

    if (!propsPinnedEntryId) {
      if (
        (
          blogSettings.pinnedEntryId !== prevState.blogSettings.pinnedEntryId ||
          blogSettings.pinnedEntryFeedId !== prevState.blogSettings.pinnedEntryFeedId
        )
        ||
        (
          blogSettings.pinnedEntryId === null && pinnedEntry !== null
        )
      ) {
        this.updatePinnedEntry(blogSettings.pinnedEntryId);
      }
    }

    if (blogSettings.pollingStrategy !== prevState.blogSettings.pollingStrategy) {
      this.pollingFetchingStrategy.setPollingStrategyName(blogSettings.pollingStrategy);
    }

    if (
      blogSettings.isSportsLiveblog &&
      blogSettings.isSportsLiveblog !== prevState.blogSettings.isSportsLiveblog
    ) {
      this.fetchLiveblogEntries();
    }

    if (isInitialFetchDone) {
      if (blogSettings.isSportsLiveblog) {
        this.payloadFetchingStrategy.isRunning() && this.payloadFetchingStrategy.end();
        !this.signalFetchingStrategy.isRunning() && this.signalFetchingStrategy.run();
      } else {
        this.signalFetchingStrategy.isRunning() && this.signalFetchingStrategy.end();
        !this.payloadFetchingStrategy.isRunning() && this.payloadFetchingStrategy.run();
      }
    }
  }

  // Handle host's SPA architecture and liveblog changes
  componentWillReceiveProps(nextProps) {
    const currentLiveblogId = _get(this.props, 'prefetchedData.liveblog.id');
    const nextLiveblogId = _get(nextProps, 'prefetchedData.liveblog.id');
    const currentPinnedEntryId = _get(this.props, 'prefetchedData.pinnedEntry.id');
    const nextPinnedEntryId = _get(nextProps, 'prefetchedData.pinnedEntry.id');

    if (
      currentLiveblogId !== nextLiveblogId
      ||
      currentPinnedEntryId !== nextPinnedEntryId
    ) {
      this.resetLiveblog(nextProps);
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;

    this.payloadFetchingStrategy.isRunning() && this.payloadFetchingStrategy.end();
    this.signalFetchingStrategy.isRunning() && this.signalFetchingStrategy.end();
  }

  renderLiveblogSummary() {
    if (!this.props.showSummary || !this.state.feedSettings.summary) {
      return null;
    }

    return (<LiveblogSummary content={this.state.feedSettings.summary} />);
  }

  render() {
    const { error, liveblogData } = this.state;
    const { placement } = this.props;

    if (liveblogData && liveblogData.feeds && placement) {
      const doesFeedExist = liveblogData.feeds.find(feed => feed.placement === placement);

      if (!doesFeedExist) {
        return null;
      }
    }

    return error
      ? (<OnErrorComponent error={error} />)
      : (<div>
        {this.renderLiveblogSummary()}
        <Liveblog
          entries={this.state.fetchedEntries}
          placement={this.props.placement}
          hasNoEntries={this.state.hasNoEntries}
          postMessageHandler={this.postMessageHandler}
          liveblogDataLoaded={this.state.liveblogDataLoaded}
          isDataLoadedClientSide={this.state.isInitialFetchDone}
          AdComponent={this.props.AdComponent}
          pinnedEntry={this.state.pinnedEntry}
          hasMoreEntries={this.state.hasMoreEntries}
          isFetchingBelow={this.state.isFetchingBelow}
          handleFetchMoreEntries={this.fetchMoreEntriesHandler}
          user={this.props.user}
          publication={this.props.publication}
          entriesAuthors={this.state.entriesAuthors}
          entryActions={this.props.entryActions}
          scrollableParent={this.props.scrollableParent}
          loggedInUser={this.state.loggedInUser}
          forceUserMessagesLoginDisabling={this.props.forceUserMessagesLoginDisabling}
          trackEvent={this.props.trackEvent}
          feedId={this.state.feedId}
          blogSettings={this.state.blogSettings}
          feedSettings={this.state.feedSettings}
        />
      </div>);
  }
}

/* eslint-disable react/no-unused-prop-types */
LiveblogRealtimeContainer.propTypes = {
  liveFeedUrl: PropTypes.string.isRequired,
  getSingleEntryUrlTemplate: PropTypes.string,
  getXhrFunction: PropTypes.func,
  postXhrFunction: PropTypes.func,
  liveId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  postMessageUrl: PropTypes.string,
  AdComponent: PropTypes.func,
  pinnedEntryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disablePinnedEntry: PropTypes.bool,
  pagination: PropTypes.number,
  showSummary: PropTypes.bool,
  publication: PropTypes.string.isRequired,
  interval: PropTypes.number,
  isStageMode: PropTypes.bool,
  prefetchedData: PropTypes.object,
  scrollableParent: PropTypes.any,
  entryActions: EntryActionsShape,
  fetchEntriesDelay: PropTypes.bool,
  user: UserShape,
  getLoggedInUser: PropTypes.func,
  logInHandler: PropTypes.func,
  logOutHandler: PropTypes.func,
  forceUserMessagesLoginDisabling: PropTypes.bool,
  trackEvent: PropTypes.func,
  feedId: PropTypes.string,
  placement: PropTypes.number,
  resolveFeed: PropTypes.func,
  getDefaultContainerState: PropTypes.func,
};
/* eslint-enable react/no-unused-prop-types */

LiveblogRealtimeContainer.defaultProps = {
  AdComponent: null,
  getXhrFunction: get,
  postXhrFunction: post,
  disablePinnedEntry: false,
  pagination: 20,
  showSummary: false,
  interval: 10,
  isStageMode: false,
  prefetchedData: null,
  fetchEntriesDelay: true,
  user: UserDefaultProps,
  getLoggedInUser: null,
  logInHandler: null,
  logOutHandler: null,
  forceUserMessagesLoginDisabling: false,
  trackEvent: () => {},
  feedId: null,
  placement: null,
  resolveFeed: _resolveFeed,
  getDefaultContainerState: _getDefaultContainerState,
};

export default LiveblogRealtimeContainer;
