import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

import { decorateRawEntry } from './decorateRawEntry';
import { normalizeDecoratedEntry } from './normalizeDecoratedEntry';

export const prepareEntry = (
  rawEntry,
  user,
  sportsMeta,
) => {
  const decoratedEntry = decorateRawEntry(rawEntry, user);

  if (decoratedEntry.isSport) {
    return decoratedEntry;
  }

  // for now there are two ways of determining addedWithinMatch flag
  // one of them is for entries fetched from API that is implemented below
  // TODO refactor it in RND/Live#848
  const addedWithinMatch = sportsMeta
    ? isAfter(new Date(rawEntry.createdAt), new Date(sportsMeta.matchStart))
    && (!sportsMeta.matchEnd || isBefore(new Date(rawEntry.createdAt), new Date(sportsMeta.matchEnd)))
    : false;

  // related to the previous comment
  // preceding entry in this case is not needed to determine addedWithinMatch flag
  const precedingNormalizedEntry = null;

  return normalizeDecoratedEntry(
    decoratedEntry,
    precedingNormalizedEntry,
    addedWithinMatch,
  );
};

export const prepareEntries = (
  rawEntries,
  user,
  sportsMeta,
  handleSingleEntry = prepareEntry,
) => (
  rawEntries
    ? rawEntries.map(entry => handleSingleEntry(entry, user, sportsMeta))
    : []
);
