import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import cc from 'classcat';

import ButtonType from './../Button/Types';

export const Button = ({
  content,
  visibility,
  disabled,
  onClick,
  buttonType,
  customClass,
  theme,
}) => {
  const buttonClassNames = cc({
    button: true,
    [theme.button]: true,
    [theme.buttonDisabled]: disabled,
    [theme.buttonRaised]: buttonType === ButtonType.RAISED,
    [customClass]: customClass !== undefined,
  });

  return visibility ? (
    <div className={buttonClassNames} onClick={onClick} role="button" tabIndex="0">
      {content}
    </div>
  ) : null;
};

Button.propTypes = {
  content: PropTypes.string.isRequired,
  visibility: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  buttonType: PropTypes.string,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  customClass: PropTypes.string,
};

Button.defaultProps = {
  theme: {},
  customClass: undefined,
};

export default themer('MessageFormButton')(Button);
