import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

function isEmpty(content) {
  return !content.replace(/<p><\/p>/g, '').trim();
}

/* eslint-disable react/no-danger */
const LiveblogSummary = ({ content, theme }) => {
  if (isEmpty(content)) {
    return false;
  }

  return (
    <div className={theme.liveSummary}>
      <div className={theme.liveSummaryContent} dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};
/* eslint-enable */

LiveblogSummary.propTypes = {
  content: PropTypes.string.isRequired,
  theme: PropTypes.object,
};

LiveblogSummary.defaultProps = {
  theme: {},
};

export default themer('LiveblogSummary')(LiveblogSummary);
