import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import WithCounterHOC from '../HOC/withCounter';

export const TextArea = ({
  name,
  value,
  placeholder,
  errorMessage,
  validated,
  maxLength,
  onChange,
  theme,
}) => (
  <textarea
    name={name}
    className={theme.content}
    placeholder={validated ? placeholder : errorMessage}
    onChange={onChange}
    value={value}
    maxLength={maxLength}
  />
);

TextArea.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  validated: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

TextArea.defaultProps = {
  name: '',
  errorMessage: '',
  onChange: () => {},
  value: '',
  maxLength: 30,
  theme: {},
};

export default themer('MessageFormTextArea')(WithCounterHOC(TextArea));
