export default ({ css }) => {
  const imageInfoWrapper = css`
    @font-face {
      font-family: 'Inter';
      src: url('https://default.sacdn.no/rnd/fonts/Inter-Regular.otf') format('opentype'),
      url('https://default.sacdn.no/rnd/fonts/Inter-Regular.woff') format('woff'),
      url('https://default.sacdn.no/rnd/fonts/Inter-Regular.woff2') format('woff2');
      font-weight: 400;
    }

    font-family: 'Inter', sans-serif;
    font-size: 14px;
    padding: 8px 0;
    color: rgb(109, 118, 126);
    letter-spacing: 0;
    line-height: 20px;
    word-break: break-word;
  `;

  return {
    styles: {
      imageInfoWrapper,
    },
  };
};
