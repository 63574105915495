import _appendFeedIdToUrl from './appendFeedIdToUrl';
import _getFeedIdFromLiveblog from './getFeedIdFromLiveblog';
import _getFeedIdFromUrl from './getFeedIdFromUrl';

const handleError = (error, livefeedUrl) => {
  // eslint-disable-next-line no-console
  console.error(error);

  return Promise.resolve({
    url: livefeedUrl,
    id: null,
  });
};

const resolveFeed = (
  fetchLiveblog,
  appendFeedIdToUrl = _appendFeedIdToUrl,
  getFeedIdFromLiveblog = _getFeedIdFromLiveblog,
  getFeedIdFromUrl = _getFeedIdFromUrl,
) => ({
  liveFeedUrl, feedId, placement, prefetchedData, liveId, backendBaseUrl,
} = {}) => {
  try {
    if (liveFeedUrl.indexOf('feedId') > -1) {
      return Promise.resolve({
        url: liveFeedUrl,
        id: feedId || getFeedIdFromUrl(liveFeedUrl),
      });
    }

    if (feedId) {
      return Promise.resolve({
        url: appendFeedIdToUrl(liveFeedUrl, feedId),
        id: feedId,
      });
    }

    if (prefetchedData && placement) {
      const feedIdFromPrefetchedData = getFeedIdFromLiveblog(
        prefetchedData.liveblog, placement,
      );

      return Promise.resolve({
        url: appendFeedIdToUrl(liveFeedUrl, feedIdFromPrefetchedData),
        id: feedIdFromPrefetchedData,
      });
    }

    if (placement) {
      return fetchLiveblog(backendBaseUrl, liveId)
        .then(({ data: liveblog } = {}) => {
          const feedIdFromLiveblog = getFeedIdFromLiveblog(
            liveblog, placement,
          );

          return Promise.resolve({
            url: appendFeedIdToUrl(liveFeedUrl, feedIdFromLiveblog),
            id: feedIdFromLiveblog,
          });
        })
        .catch(error => handleError(error, liveFeedUrl));
    }

    return Promise.resolve({
      url: liveFeedUrl,
      id: null,
    });
  } catch (error) {
    return handleError(error, liveFeedUrl);
  }
};

export default resolveFeed;
