import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import { timeShape } from '../../utils/shapes';

export const EntryTimeMain = ({ time, theme }) => (
  time.main
    ? <span
      className={theme.timeMain}
    >
      { time.main }
    </span>
    : false
);

EntryTimeMain.propTypes = {
  time: PropTypes.shape(timeShape),
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

EntryTimeMain.defaultProps = {
  theme: {},
};

export default themer('EntryTimeMain')(EntryTimeMain);
