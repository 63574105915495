export default (
  {
    getPollingInterval,
    callback,
  },
) => {
  let pollingStrategyName;
  let pollingTimeout = 0;

  const run = () => {
    if (pollingTimeout) {
      clearTimeout(pollingTimeout);
    }

    pollingTimeout = setTimeout(
      () => {
        callback();

        run();
      },
      getPollingInterval(pollingStrategyName),
    );
  };

  const end = () => {
    if (pollingTimeout) {
      clearTimeout(pollingTimeout);
    }
  };

  const setPollingStrategyName = (newPollingStrategyName) => {
    pollingStrategyName = newPollingStrategyName;
  };

  return {
    run,
    end,
    setPollingStrategyName,
  };
};
