import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import cc from 'classcat';

import { elapsedShape } from '../../../utils/shapes';

export const SportsEntryTime = ({ elapsed, theme }) => {
  const time = elapsed.minutes;

  const defaultClasses = {
    [theme.sportsEventTime]: true,
    [theme.timeMain]: true,
  };

  if (!elapsed.minutes) {
    const emptyCaseClasses = Object.assign({}, defaultClasses, { [theme.empty]: true });
    return <span className={cc(emptyCaseClasses)} />;
  }

  const timeStyleClasses = cc(defaultClasses);

  const parsedTime = parseInt(time, 10);
  const timeString = parsedTime
    ? `${parsedTime}`
    : time;

  const addedTime = elapsed.minutesPlus
    ? ` + ${elapsed.minutesPlus}`
    : '';

  return (
    <span className={timeStyleClasses}>
      {timeString}{addedTime}′
    </span>
  );
};

SportsEntryTime.propTypes = {
  elapsed: PropTypes.shape(elapsedShape).isRequired,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default themer('SportsEntryTime')(SportsEntryTime);
