import React, { Component } from 'react';
import PropTypes from 'prop-types';

const tokenApiUrls = {
  vgtv: 'https://api.vg.no',
};

const fetchToken = (tokenApiUrl, videoId, videoAccess) => {
  const url = `${tokenApiUrl}/svp/token/${videoId}?access=${videoAccess}`;
  const options = { credentials: 'include' };

  return fetch(url, options).then(response => response.json());
};

class VideoComponent extends Component {
  constructor(props) {
    super(props);

    this.videoRef = `livefeed-video-asset-${this.props.assetId}-${this.getRandomUUID()}`;
  }

  getRandomUUID() {
    if (self && self.crypto && self.crypto.randomUUID) {
      return self.crypto.randomUUID();
    }

    return Math.random().toString(36).slice(-11);
  }

  loadSvpLibrary() {
    const VERSION = '3.42.12';
    /* eslint-disable */

    if (!window) {
      return;
    }
    // SVP Player init function
    // https://github.schibsted.io/svp/web-player
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];

      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = `https://cdn.stream.schibsted.media/player/${VERSION}/player.min.js`;
      js.async = true;
      try {
        eval('async () => {}'),
          (js.src = js.src.replace('player.min', 'player.next.min'));
      } catch (a) {}
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'svp-player-sdk');
    /* eslint-enable */
  }

  SvpVideoPlayer(loadLibrary = this.loadSvpLibrary) {
    let pendingVideos = [];
    let isInitializing = false;

    // eslint-disable-next-line no-undef
    const initVideo = videoData => new SVP.Player(videoData);

    // eslint-disable-next-line no-undef
    const isLibraryInitialized = () => typeof SVP !== 'undefined' && !!SVP.Player;

    const initLibrary = () => {
      if (isInitializing) {
        return;
      }

      isInitializing = true;

      window.addEventListener('onSvpPlayerReady', () => {
        pendingVideos.forEach(loadVideo => loadVideo());

        pendingVideos = [];
      });

      loadLibrary();
    };

    const loadVideo = videoData => new Promise((resolve) => {
      if (isLibraryInitialized()) {
        resolve(initVideo(videoData));
      } else {
        pendingVideos.push(() => resolve(initVideo(videoData)));
      }
    });

    return {
      initLibrary,
      loadVideo,
    };
  }

  componentDidMount() {
    const { assetId, vendor, title, access } = this.props;

    const videoData = {
      id: Number.parseInt(assetId, 10),
      vendor,
      node: this.videoRef,
      title,
    };

    if (access) {
      const tokenApiUrl = tokenApiUrls[vendor];

      if (tokenApiUrl) {
        videoData.token = () => fetchToken(tokenApiUrl, assetId, access);
      }
    }

    this.svpVideoPlayer = this.SvpVideoPlayer();

    const useSvpVideoPlayer = () => {
      this.svpVideoPlayer.initLibrary();

      return this.svpVideoPlayer.loadVideo;
    };

    this.loadVideo = useSvpVideoPlayer();

    this.loadVideo(videoData).then((player) => {
      // eslint-disable-next-line no-console
      player.on('play', () => console.log('video played'));
    });
  }

  render() {
    const { theme } = this.props;
    return (
      <div className={theme.wrapper}>
        <div id={this.videoRef} />
      </div>
    );
  }
}

VideoComponent.propTypes = {
  assetId: PropTypes.string.isRequired,
  vendor: PropTypes.string.isRequired,
  title: PropTypes.string,
  access: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object,
};

VideoComponent.defaultProps = {
  title: '',
  access: null,
  theme: {},
};

export default VideoComponent;
