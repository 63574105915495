export default ({ css }) => {
  const copyToClipboardWrapper = css`
    color: #787878;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 11px;
    position: relative;
  `;

  const copyButton = css`
    cursor: pointer;
    font-style: normal !important;
    display: flex;
    align-items: baseline;

    svg {
      position: relative;
      top: 7px;
      margin-left: 3px;
    }
  `;

  const copiedToast = css`
    font-size: 12px;
    background-color: #5ac9ca;
    color: white;
    font-weight: 100;
    position: absolute;
    border-radius: 4px;
    z-index: 11;
    white-space: nowrap;
    top: -12px;
    right: 50px;
    padding: 15px 20px;

    @media (min-width: 376px) {
      font-size: 15px;
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      right: -7px;
      width: 0;
      height: 0;
      border-left: 15px solid #5ac9ca;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      top: 15px;
    }
  `;

  return {
    styles: {
      copyToClipboardWrapper,
      copyButton,
      copiedToast,
    },
  };
};
