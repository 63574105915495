import RealtimeConnector, {
  ENTRY_EVENTS,
  RESOURCE_NAMES,
  SPORT_EVENTS,
  FEED_EVENTS,
} from '@core-live/realtime-connector';

const LiveblogRealtimeConnector = realtimeConnectorParameters => RealtimeConnector({
  ...realtimeConnectorParameters,
  resourceNames: [RESOURCE_NAMES.ENTRIES, RESOURCE_NAMES.FEEDS],
  subscribedEvents: [...SPORT_EVENTS, ...ENTRY_EVENTS, ...FEED_EVENTS],
});

export default LiveblogRealtimeConnector;
