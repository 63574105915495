export default ({ css }) => {
  const defaultAuthorField = css`
    color: #707070;
    font-size: 14px;
    position: relative;
    display: inline-block;
    height: 14px;
  `;

  const defaultAuthorName = css`
    padding-left: 2px;
    margin-right: 8px;
    word-break: break-word;
  `;

  const defaultAvatarPlaceholder = css`
    background-color: #f6231e;
    width: 14px;
    height: 14px;
    left: -28px;
    top: 3px;
    border-radius: 50%;
    position: absolute;
    z-index: 11;

    &::after {
      position: absolute;
      background-color: #fff;
      width: 6px;
      height: 6px;
      top: 4px;
      left: 4px;
      border-radius: 50%;
      content: '';
      z-index: 10;
    }
  `;

  const styledAuthorField = css`
    display: inline-block;
    margin-right: 6px;
    position: relative;
    line-height: 20px;
    margin-bottom: 8px;
  `;

  const styledAuthorName = css`
    display: inline-block;
    line-height: 1em;
    position: relative;
    font-weight: bold;
    word-break: break-word;
    margin-left: 10px;
  `;

  const styledAvatar = css`
    box-sizing: content-box;
    width: 40px;
    height: 40px;
    left: -44px;
    top: -10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    border: 2px solid #fff;
    border-radius: 50%;
  `;

  const journalistReplyAuthorField = css`
    ${styledAuthorField};
  `;

  const journalistReplyAuthorName = css `
    margin-left: 10px;
`;

  return {
    styles: {
      defaultAuthorField,
      defaultAuthorName,
      defaultAvatarPlaceholder,
      journalistReplyAuthorField,
      journalistReplyAuthorName,
      styledAuthorField,
      styledAuthorName,
      styledAvatar,
    },
  };
};
