import { colorBlack } from '../variables';

export default ({ css, defaultStyles }) => {
  const success = css`
    background-color: ${colorBlack};
  `;

  return {
    styles: {
      ...defaultStyles.MessageFormStatusLabel,
      success,
    },
  };
};
