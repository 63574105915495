import {
  colorPrimary,
  colorSportbladet,
} from './variables';

import {
  className,
  uniqueClassName,
  sportbladet,
} from './functions';

export default ({ css, defaultStyles }) => {
  const { FileUpload } = defaultStyles;

  const labelDesktop = uniqueClassName(css, 'labelDesktop')(css`
    ${FileUpload.labelDesktop};

    background-image: unset;
    border: 1.5px dashed lightgrey;
    border-radius: 16px;
  `);

  const button = uniqueClassName(css, 'button')(css`
    ${FileUpload.button};

    color: var(--c-primary-01, ${colorPrimary});
    font-family: HelveticaNeue-Bold, sans-serif;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.4px;
    line-height: 16px;
    border: 1px solid var(--c-primary-01, ${colorPrimary});
    border-radius: 16px;
  `);

  const labelMobile = uniqueClassName(css, 'labelMobile')(css`
    ${FileUpload.labelMobile};

    color: var(--c-primary-01, ${colorPrimary});
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.2px;
    border: 1px solid var(--c-primary-01, ${colorPrimary});
    border-radius: 24px;
    box-shadow: 1px 2px 0 0 rgba(0, 0, 0, 0.08);
    padding: 8px 4px;
  `);

  const styles = {
    ...FileUpload,
    labelDesktop,
    labelMobile,
    button,
  };

  const globalStyles = sportbladet(`
    ${className(labelMobile)} {
      color: var(--c-primary-01, ${colorSportbladet};
      border-color: var(--c-primary-01, ${colorSportbladet};
    }

    ${className(labelDesktop)} {
      ${className(button)} {
        color: var(--c-primary-01, ${colorSportbladet};
        border-color: var(--c-primary-01, ${colorSportbladet};
      }
    }
  `);

  return {
    styles,
    globalStyles,
  };
};
