import _ThrowError from './throwError';
import _LogErrorAndReturn from './logErrorAndReturn';
import _ParseResponse from './parseResponse';
import _wrapResponse from './wrapResponse';

const ApiCalls = (
  get,
  ThrowError = _ThrowError,
  LogErrorAndReturn = _LogErrorAndReturn,
  ParseResponse = _ParseResponse,
  wrapResponse = _wrapResponse,
) => ({
  getEntries: (url) => {
    const handleError = ThrowError(url);
    const handleResponse = ParseResponse(handleError);

    return get(url)
      .then(handleResponse)
      .then(wrapResponse);
  },

  getPinnedEntry: (urlTemplate, pinnedEntryId) => {
    const url = urlTemplate.replace('{id}', pinnedEntryId);
    const handleError = LogErrorAndReturn(url);
    const handleResponse = ParseResponse(handleError);

    return get(url)
      .then(handleResponse)
      .then(wrapResponse);
  },

  getLiveblog: (baseUrl, liveblogId) => {
    const url = `${baseUrl}/liveblogs/${liveblogId}`;

    const handleError = LogErrorAndReturn(url);
    const handleResponse = ParseResponse(handleError);

    return get(url)
      .then(handleResponse)
      .then(wrapResponse);
  },
});

export default ApiCalls;
