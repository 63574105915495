import pick from 'lodash.pick';

import { isAddedWithinMatch as _isAddedWithinMatch } from './isAddedWithinMatch';

export const normalizeDecoratedEntry = (
  decoratedEntry,
  precedingNormalizedEntry,
  isAddedWithinMatch = _isAddedWithinMatch,
) => {
  if (decoratedEntry.isSport) {
    return decoratedEntry;
  }

  return {
    ...(pick(decoratedEntry, [
      'id',
      'content',
      'meta',
      'entryType',
      'isEditable',
      'isRegular',
      'isSport',
      'isDirekteVg',
      'isNtb',
      'isFromInternalStorage',
      'structuredContent',
      'title',
      'sourceName',
    ])),
    date: decoratedEntry.createdAt,
    authorName: decoratedEntry.journalistName,
    authorImage: decoratedEntry.journalistAvatarUrl,
    authorType: decoratedEntry.userType,
    tags: decoratedEntry.tags,
    value: decoratedEntry.value,
    // TODO refactor it in RND/Live#848
    addedWithinMatch: typeof isAddedWithinMatch === 'function'
      ? isAddedWithinMatch(precedingNormalizedEntry)
      : !!isAddedWithinMatch,
  };
};
