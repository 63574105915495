import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import { timeShape } from '../../utils/shapes';

export const EntryTimeDetails = ({ time, theme }) => (
  time.details
    ? <span
      className={theme.timeDetails}
    >
      {time.details}
    </span>
    : false
);

EntryTimeDetails.propTypes = {
  time: PropTypes.shape(timeShape),
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

EntryTimeDetails.defaultProps = {
  theme: {},
};

export default themer('EntryTimeDetails')(EntryTimeDetails);
