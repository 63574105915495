const SUPPORTED_MESSAGE_TYPES = [
  'updateLiveblog',
];

const assembleFromLiveblogMessage = (message) => {
  const { type, body: { updatedAt } } = message;

  const updatedAtDate = new Date(updatedAt);

  return SUPPORTED_MESSAGE_TYPES.includes(type)
    ? `${type}-${updatedAtDate.getTime()}`
    : null;
};

export default assembleFromLiveblogMessage;
