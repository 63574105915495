export default ({ css }) => {
  const entryEditOptions = css`
    background-color: #f3f3f3;
    border-radius: 6px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px;
    margin-top: 4px;
    margin-left: auto;

    button {
      margin-left: 10px;
      background-color: #ecf9f7;
      border: 1px solid #00b5ad;
      font-weight: bolder;
      height: 22px;
    }
  `;

  const editEntryButton = css`
    color: #2185d0;
  `;

  const deleteEntryButton = css`
    color: #db2828;
  `;

  const pinEntryButton = css`
    color: #1e761e;
  `;

  const unpinEntryButton = css`
    color: #1e761e;
  `;

  const entryEditOptionsLegend = css`
    flex-grow: 1;
    color: #c9c9c9;
    text-transform: uppercase;
    text-align: right;
    font-weight: bold;
    font-size: 12px;
    align-self: center;
    margin-top: 1px;
  `;

  const entryEditOptionsNewsValue = css`
    color: #333;
    text-align: left;
  `;

  return {
    styles: {
      entryEditOptions,
      editEntryButton,
      deleteEntryButton,
      pinEntryButton,
      unpinEntryButton,
      entryEditOptionsLegend,
      entryEditOptionsNewsValue,
    },
  };
};
