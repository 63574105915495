import PropTypes from 'prop-types';

import { StructuredTextShape } from './StructuredTextShape';
import { StructuredImageShape } from './StructuredImageShape';

export const StructuredContentShape = PropTypes.shape({
  components: PropTypes.arrayOf(PropTypes.oneOfType([
    StructuredTextShape,
    StructuredImageShape,
    PropTypes.object,
  ])).isRequired,
});
