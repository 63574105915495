export default ({ css }) => {
  const timeMain = css`
    color: #ff4c49;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
  `;

  return {
    styles: {
      timeMain,
    },
  };
};
