import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import JournalistReply from '../JournalistReply/JournalistReply';
import EntryUserMessage from '../EntryUserMessage';

import { BLOCKQUOTE_TYPE, getEntriesStructure } from '../../utils/QAStructureParser';
import { uniqueKeyGenerator } from '../../utils/generators';
import {
  CHAT_TYPE,
  USER_MESSAGE_SUBTYPE,
} from '../structuredContent/componentTypes';
import {
  isUserJournalistChat,
  isUserQuote,
} from '../../utils/entryContentEvaluator';
import {
  FeedSettingsDefaultProps,
  FeedSettingsShape,
} from '../../shapes';

const keyGenerator = uniqueKeyGenerator();

const getThemeIndex = (author, authors = [], count) => {
  const authorIndex = authors.indexOf(author);

  if (authorIndex === -1) {
    return 'Default';
  }

  return (authorIndex % count) + 1;
};

export const EntryThemed = ({
  content,
  authorName,
  authorImage,
  authorType,
  entriesAuthors,
  entriesThemedCount,
  theme,
  publication,
  structuredContent: { components = [] } = {},
  feedSettings,
}) => {
  const entriesStructure = getEntriesStructure(content);
  const isQuoteOnly = isUserQuote(content);
  const isChat = isUserJournalistChat(content);

  const parsedEntriesStructure = entriesStructure.map((item) => {
    const key = keyGenerator.generateUniqueKey();

    if (item.type === BLOCKQUOTE_TYPE) {
      const userMessage = components.find(
        ({ type, subtype, meta: { userMessageId } = {} }) => type === CHAT_TYPE
          && subtype === USER_MESSAGE_SUBTYPE
          && userMessageId === item.attrs['data-id'],
      );

      return (
        <EntryUserMessage
          userMessage={userMessage}
          theme={theme}
          key={key}
        />
      );
    }

    const themeIndex = getThemeIndex(authorName, entriesAuthors, entriesThemedCount);

    return (
      <div key={key} className={theme[`entryThemed${themeIndex}`]}>
        <JournalistReply
          authorImage={authorImage}
          authorName={authorName}
          authorType={authorType}
          isQuoteOnly={isQuoteOnly}
          isChat={isChat}
          publication={publication}
          content={item.content}
          key={key}
          feedSettings={feedSettings}
        />
      </div>
    );
  });

  return (
    <div className={theme.entryThemedWrapper}>
      {parsedEntriesStructure}
    </div>
  );
};

EntryThemed.propTypes = {
  content: PropTypes.array,
  authorName: PropTypes.string,
  authorImage: PropTypes.string,
  authorType: PropTypes.string,
  entriesAuthors: PropTypes.arrayOf(PropTypes.string),
  entriesThemedCount: PropTypes.number,
  publication: PropTypes.string.isRequired,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  structuredContent: PropTypes.shape({
    components: PropTypes.array,
  }).isRequired,
  feedSettings: FeedSettingsShape,
};

EntryThemed.defaultProps = {
  isChat: false,
  content: [],
  authorName: null,
  authorImage: null,
  authorType: null,
  entriesAuthors: [],
  entriesThemedCount: 6,
  theme: {},
  feedSettings: FeedSettingsDefaultProps,
};

export default themer('EntryThemed')(EntryThemed);
