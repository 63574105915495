export default ({ css }) => {
  const chatContentWrapper = css`
    width: 100%;
    margin-top: 5px;
  `;

  return {
    styles: {
      chatContentWrapper,
    },
  };
};
