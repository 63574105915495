export default ({ css }) => {
  const pinnedEntry = css`
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333333;
    font-size: 16px;
  `;

  const pinnedEntryHeader = css`
    color: #787878;
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    text-align: center;
    align-items: center;
    position: relative;
  `;

  const pinnedEntryTitle = css`
    margin-left: -13px;
  `;

  const pinnedEntryIcon = css`
    display: flex;

    svg {
      height: 17px;
      padding-left: 10px;
      color: #787878;
    }
  `;

  const pinnedEntryContent = css`
    margin-left: -15px;
  `;

  const pinnedEntryChatContent = css`
    margin-left: 20px;
  `;

  const pinnedEntryDetails = css`
    color: #787878;
    font-size: 14px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  `;

  const author = css`
    margin: 0 12px;
  `;

  const datePublished = css`
    color: #FF4C49;
    font-weight: bold;
  `;

  const metadataWrapper = css `
    display: flex;
    align-items: center;
  `;

  const itemSeparator = css`
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border-top: 1px solid #cbcbcb;
    margin: 40px 0 50px 0;
  `;

  const styledAvatar = css`
    box-sizing: content-box;
    width: 40px;
    height: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid #fff;
    border-radius: 50%;
  `;

  return {
    styles: {
      pinnedEntry,
      styledAvatar,
      metadataWrapper,
      pinnedEntryHeader,
      pinnedEntryTitle,
      pinnedEntryIcon,
      pinnedEntryContent,
      pinnedEntryChatContent,
      pinnedEntryDetails,
      author,
      datePublished,
      itemSeparator,
    },
  };
};
