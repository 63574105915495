const ARTICLE_TEASER_CHILD_PATTERNS = [
  'teaser-title',
  'teaser-description',
  'teaser-image',
];

export const isArticleTeaserChild = classNames =>
  !!classNames &&
  !!(ARTICLE_TEASER_CHILD_PATTERNS
    .find(pattern => classNames.indexOf(pattern) > -1));

export const isArticleTeaser = (node) => {
  const classNames = node && node.attrs && node.attrs.class;

  return !isArticleTeaserChild(classNames) && classNames === 'article-teaser';
};
