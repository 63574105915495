import { className } from '../../functions';

import commonStyles from './common';

import {
  backgroundColor,
  color,
  errorColor,
} from './variables';

export default ({ css }) => {
  const input = css`
    width: 100%;
    border: none;
    outline: none;
    background-color: ${backgroundColor};
    margin-bottom: 0;
    font-size: 17px;
    color: ${color};
    letter-spacing: -0.1px;
    line-height: 28px;
    padding: 6px 64px 6px 16px;
    border-radius: 4px;
  `;

  const validationErrorContainer = css`
    border: 1px solid ${errorColor};
    border-radius: 2px;

    ${className(input)}::-webkit-input-placeholder {
      color: ${errorColor};
    }
  `;

  return {
    styles: {
      ...commonStyles({ css }),
      input,
      validationErrorContainer,
    },
  };
};
