import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import LazyLoad from 'react-lazy-load';

import VideoComponent from '@core-live/video-component';

import { isBrowser } from '../../utils/env';

const STANDARD_VIDEO_WIDTH = 800;
const STANDARD_VIDEO_ASPECT_RATIO = {
  HORIZONTAL: 16,
  VERTICAL: 9,
};

const EntryVideo = ({ assetId, vendor, videoTitle, theme }) => {
  const getVideoHeight = () => {
    if (!isBrowser()) {
      return null;
    }

    const root = document.querySelector('.core-liveblog');
    const rootWidth = root ? root.getBoundingClientRect().width : STANDARD_VIDEO_WIDTH;

    return (rootWidth / STANDARD_VIDEO_ASPECT_RATIO.HORIZONTAL) * STANDARD_VIDEO_ASPECT_RATIO.VERTICAL;
  };

  return (
    <LazyLoad height={getVideoHeight()}>
      <VideoComponent
        assetId={assetId}
        vendor={vendor}
        videoTitle={videoTitle}
        theme={theme}
        // This hardcoded part will be removed once https://github.schibsted.io/core-live/Live/issues/2577 is fully implemented
        access="sport"
      />
    </LazyLoad>
  );
};

/* eslint-disable react/forbid-prop-types */
EntryVideo.propTypes = {
  assetId: PropTypes.string.isRequired,
  vendor: PropTypes.string.isRequired,
  videoTitle: PropTypes.string,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};
/* eslint-enable react/forbid-prop-types */
EntryVideo.defaultProps = {
  theme: {},
};
export default themer('EntryVideo')(EntryVideo);
