import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import cc from 'classcat';

import getContent from '../utils/contentFactory';

import { sportsMetaShape } from '../../../utils/shapes';

export const SportsEntryContent = ({ type, subtype, meta, theme, text, publication }) => {
  const contentView = getContent(type, subtype, meta, publication);

  const textStyleClasses = cc({
    [theme.sportsEntryText]: true,
    [theme.separated]: !!contentView,
  });

  const textView = text ? <div className={textStyleClasses}>{text}</div> : null;

  return (
    <div className={theme.sportsEntryContent}>
      {contentView}
      {textView}
    </div>
  );
};

SportsEntryContent.propTypes = {
  type: PropTypes.string,
  subtype: PropTypes.string,
  meta: PropTypes.shape(sportsMetaShape),
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  text: PropTypes.string,
  publication: PropTypes.string.isRequired,
};

export default themer('SportsEntryContent')(SportsEntryContent);
