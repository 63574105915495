import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import { SUBTYPES, TYPES } from './const';

const BASE_URL = '//liveblog-static-assets.schibsted.tech/report-items';

// if `default` value for type provided it'll return it for all subtypes
const customMapping = {
  [TYPES.SCORE_CHANGE]: {
    [SUBTYPES.GOAL]: 'goal-football',
    [SUBTYPES.GOAL_PENALTY_SHOOTOUT]: 'goalpenalty',
  },
  [TYPES.PENALTY_SHOOTOUT]: {
    [SUBTYPES.PENALTY_SHOOTOUT_MISSED]: 'penaltymissed',
  },
  [TYPES.SHOT]: { default: 'shoton' },
  [TYPES.INJURY]: { default: 'injury' },
  [TYPES.FREE_KICK]: { default: 'generalhighlight' },
  [TYPES.EVENT_START]: { default: 'whistle' },
  [TYPES.EVENT_END]: { default: 'whistle' },
  [TYPES.PAUSE_START]: { default: 'whistle' },
  [TYPES.PERIOD_START]: { default: 'whistle' },
  [TYPES.VIDEO_ASSISTANT_REFEREE]: { default: 'videoassistantreferee' },
  [TYPES.FOUL]: { default: 'whistle' },
  [TYPES.TREATMENT]: { default: 'injury' },
  [TYPES.OFFSIDE]: { default: 'offside' },
  [TYPES.COMMENTARY]: { default: 'commentary' },
};

const getByType = (type, subtype) => {
  const customTypeMapping = customMapping[type];
  if (!customTypeMapping) {
    return subtype;
  }

  return customTypeMapping[subtype] || customTypeMapping.default || subtype;
};

export const getIconName = (type, subtype) => getByType(type, subtype).toLowerCase();

const SportsIcon = ({ theme, type, subtype }) => {
  if (!type || !subtype) {
    return null;
  }

  const icon = getIconName(type, subtype);

  const classes = `${theme.icon} ${theme[icon]}`;

  return <img src={`${BASE_URL}/${icon}.svg`} className={classes} alt={icon} />;
};

SportsIcon.propTypes = {
  type: PropTypes.string.isRequired,
  subtype: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object,
};

SportsIcon.defaultProps = {
  theme: {},
};

export default themer('SportsIcon')(SportsIcon);

