export const isAddedWithinMatch = (
  precedingNormalizedEntry,
) => {
  if (!precedingNormalizedEntry) {
    return false;
  }

  if (precedingNormalizedEntry.addedWithinMatch) {
    return true;
  }

  if (!precedingNormalizedEntry.isSport) {
    return false;
  }

  return precedingNormalizedEntry.type !== 'eventEnd';
};
