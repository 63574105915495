import { commonConditions } from './entryContentEvaluator';

const BLOCKQUOTE_TYPE = 'BLOCKQUOTE_TYPE';
const CONTENT_TYPE = 'CONTENT_TYPE';
const JOURNALIST_REPLY_TYPE = 'JOURNALIST_REPLY_TYPE';

export {
  BLOCKQUOTE_TYPE,
  CONTENT_TYPE,
  JOURNALIST_REPLY_TYPE,
};

const { isUserQuote } = commonConditions;

const createStructureWrapper = (type, content, attrs) => ({ type, content: [content], attrs });

export const isValidEntryElement = (item) => {
  if (typeof item !== 'object') {
    return false;
  }

  return !!(item && item.props && item.props.node && item.props.node.type);
};

export const isEmptyParagraph = (item) => {
  const { props: { node: { name, children, content: nodeContent } = {} } = {} } = item;

  return (name === 'p' && children.length === 0) || (nodeContent && nodeContent.trim() === '');
};

export const parseQAStructure = structure => structure.reduce((carry, item = {}) => {
  const { props: { node: { type, name, attrs } = {} } = {} } = item;

  const isQuote = isUserQuote({ type, name, attrs });

  if (isQuote) {
    carry.push(createStructureWrapper(BLOCKQUOTE_TYPE, item, attrs));
  } else if (!carry.length) {
    carry.push(createStructureWrapper(CONTENT_TYPE, item, attrs));
  } else if (carry[carry.length - 1].type === BLOCKQUOTE_TYPE) {
    carry.push(createStructureWrapper(JOURNALIST_REPLY_TYPE, item, attrs));
  } else {
    carry[carry.length - 1].content.push(item);
  }

  return carry;
}, []);

export const getEntriesStructure = (content = []) => parseQAStructure(
  content
    .filter(isValidEntryElement)
    .filter(item => !isEmptyParagraph(item)),
);
