import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  getPageUrl,
  getPageUrlWithoutPinnedEntry,
  getUrlSeparator,
} from '../../../utils/urlHelpers';

const pageUrl = getPageUrl();

const pageUrlWithoutPinnedEntry = getPageUrlWithoutPinnedEntry(pageUrl);

const urlSeparator = getUrlSeparator(pageUrlWithoutPinnedEntry);

const VISIBILITY_TIMEOUT = 2000;

export const withCopyToClipboard = (WrappedComponent) => {
  const wrapped = class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        linkCopied: false,
      };

      this.handleOnClick = this.handleOnClick.bind(this);
      this.prepareShareUrl = this.prepareShareUrl.bind(this);
    }

    copyText() {
      const input = this.shareUrlInputRef;

      input.focus();
      input.setSelectionRange(0, input.value.length);

      const isSupported = document.execCommand('copy');

      input.blur();

      return isSupported;
    }

    handleOnClick() {
      if (!this.copyText()) {
        return;
      }

      this.setState({ linkCopied: true });

      this.timeout = setTimeout(
        () => this.setState({ linkCopied: false }),
        VISIBILITY_TIMEOUT,
      );
    }

    componentWillUnmount() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    }

    prepareShareUrl() {
      const { id, pinnedUrl } = this.props;

      return pinnedUrl.replace('{id}', id);
    }

    render() {
      const { linkCopied } = this.state;

      const copyUrlInputView = (
        <input
          ref={(ref) => { this.shareUrlInputRef = ref; }}
          value={this.prepareShareUrl()}
          readOnly
          style={{
            position: 'absolute',
            left: '-99999px',
            fontSize: '50px', // avoids zoom-in on iOS when copying text
          }}
        />
      );

      return (
        <div>
          { copyUrlInputView }
          <WrappedComponent
            handleOnClick={this.handleOnClick}
            linkCopied={linkCopied}
            {...this.props}
          />
        </div>
      );
    }
  };

  wrapped.propTypes = {
    pinnedUrl: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
  };

  wrapped.defaultProps = {
    pinnedUrl: `${pageUrlWithoutPinnedEntry}${urlSeparator}pinnedEntry={id}`,
  };

  return wrapped;
};
