import { isFunction as _isFunction } from '../../utils/isFunction';
import {
  isLoggedInUserDataInProperFormat as _isLoggedInUserDataInProperFormat,
} from './isLoggedInUserDataInProperFormat';

export const createHandleLoggedInUserData = (
  // eslint-disable-next-line no-console
  logWarning = console.warn,
  isFunction = _isFunction,
  isLoggedInUserDataInProperFormat = _isLoggedInUserDataInProperFormat,
) => ({
  getLoggedInUser,
  logInHandler,
  logOutHandler,
}) => {
  if (isFunction(getLoggedInUser)) {
    return getLoggedInUser()
      .then((loggedInUserData) => {
        if (!isLoggedInUserDataInProperFormat(loggedInUserData)) {
          throw new Error('User data structure has wrong format');
        }

        return {
          data: loggedInUserData,
          isLoggedIn: true,
          logOut: isFunction(logOutHandler) ? logOutHandler : undefined,
        };
      })
      .catch((error) => {
        logWarning(`[Liveblog]: Unable to handle logged in user. ${error}`);

        return {
          data: null,
          isLoggedIn: false,
          logIn: isFunction(logInHandler) ? logInHandler : undefined,
        };
      });
  }

  return Promise.resolve({
    data: null,
    isLoggedIn: false,
    logIn: isFunction(logInHandler) ? logInHandler : undefined,
  });
};
