import LiveblogRealtimeContainerRaw from './LiveblogRealtimeContainer';
import UrlParamsRetriever from './UrlParamsRetriever';
import OnErrorComponent from './OnErrorComponent';
import withErrorBoundary from './withErrorBoundary';

const LiveblogRealtimeContainer = withErrorBoundary(
  LiveblogRealtimeContainerRaw,
  OnErrorComponent,
);

export {
  LiveblogRealtimeContainer,
  UrlParamsRetriever,
  OnErrorComponent,
};
