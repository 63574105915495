import { createStylesWithBaseTheme } from '../functions';

import { theme as themeDefault } from '../default';

import ArticleTeaserContent from './ArticleTeaserContent';
import Author from './Author';
import CopyToClipboard from './CopyToClipboard';
import Entry from './Entry';
import EntryContent from './EntryContent';
import EntryTimeDetails from './EntryTimeDetails';
import EntryTimeMain from './EntryTimeMain';
import EntryTitle from './EntryTitle';
import EntryUserMessage from './EntryUserMessage';
import FileUpload from './FileUpload';
import GoalContent from './SportsEntryContent/GoalContent';
import JournalistReply from './JournalistReply';
import Liveblog from './Liveblog';
import LiveblogHeading from './LiveblogHeading';
import LiveblogLoginView from './LiveblogLoginView';
import LiveblogSummary from './LiveblogSummary';
import LoadMoreEntries from './LoadMoreEntries';
import MessageForm from './MessageForm/MessageForm';
import MessageFormButton from './MessageForm/MessageFormButton';
import MessageFormInput from './MessageForm/MessageFormInput';
import MessageFormTextArea from './MessageForm/MessageFormTextArea';
import MessageFormStatusLabel from './MessageForm/MessageFormStatusLabel';
import NewEntriesIndicator from './NewEntriesIndicator';
import PinnedEntry from './PinnedEntry';
import SportsEntry from './SportsEntry';
import SportsEntryContent from './SportsEntryContent';
import SportsEntryHeadline from './SportsEntryHeadline';
import SportsEntryTime from './SportsEntryTime';

const styles = {
  ArticleTeaserContent,
  Author,
  CopyToClipboard,
  Entry,
  EntryContent,
  EntryTimeDetails,
  EntryTimeMain,
  EntryTitle,
  EntryUserMessage,
  FileUpload,
  GoalContent,
  JournalistReply,
  Liveblog,
  LiveblogHeading,
  LiveblogLoginView,
  LiveblogSummary,
  LoadMoreEntries,
  MessageForm,
  MessageFormButton,
  MessageFormInput,
  MessageFormTextArea,
  MessageFormStatusLabel,
  NewEntriesIndicator,
  PinnedEntry,
  SportsEntry,
  SportsEntryContent,
  SportsEntryHeadline,
  SportsEntryTime,
};

export const theme = props => createStylesWithBaseTheme(
  styles,
  themeDefault,
  props,
);
