import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { wrapContent } from '../../utils/html';
import { nodeShape, elementPropsShape } from '../../utils/shapes';
import { isBrowser } from '../../utils/env';

export class DefaultContent extends Component {
  componentDidMount() {
    if (!isBrowser()) {
      return;
    }
    const { node } = this.props;

    if (node.name === 'script') {
      const script = document.createElement('script');

      script.src = node.attrs.src;

      if (node.attrs['data-id']) {
        script.setAttribute('data-id', node.attrs['data-id']);
      }

      script.defer = true;

      document.body.appendChild(script);
    }
  }

  render() {
    const { node, elementProps, children } = this.props;

    const childrenWithoutBooleans = children.filter(child => typeof child !== 'boolean');

    if (node.name && node.name.includes('--')) {
      return false;
    }

    if (node.name === 'script') {
      return false;
    }

    if (node.type === 'text') {
      return React.createElement(
        'span',
        {
          dangerouslySetInnerHTML: wrapContent(node.content),
          ...elementProps,
        },
      );
    }

    return React.createElement(
      node.name,
      elementProps,
      childrenWithoutBooleans,
    );
  }
}

DefaultContent.propTypes = {
  node: PropTypes.shape(nodeShape).isRequired,
  elementProps: PropTypes.shape(elementPropsShape).isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
};

DefaultContent.defaultProps = {
  children: [],
};
export default DefaultContent;
