export const resolveEntryType = ({ entryType }) => {
  const basicFlags = {
    isRegular: !['sport', 'direkteVg', 'ntb'].includes(entryType),
    isSport: entryType === 'sport',
    isDirekteVg: entryType === 'direkteVg',
    isNtb: entryType === 'ntb',
  };

  return {
    ...basicFlags,
    isFromInternalStorage: basicFlags.isRegular || basicFlags.isDirekteVg || basicFlags.isNtb,
  };
};
