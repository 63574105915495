export default ({ css }) => {
  const facebookPostContainer = css`
    margin: 20px 0;
  `;

  return {
    styles: {
      facebookPostContainer,
    },
  };
};
