export const TYPES = {
  SCORE_CHANGE: 'scoreChange',
  OFFSIDE: 'offside',
  SUBSTITUTION: 'substitution',
  EVENT_START: 'eventStart',
  EVENT_END: 'eventEnd',
  CARD: 'card',
  PAUSE_START: 'pauseStart',
  PERIOD_START: 'periodStart',
  PENALTY: 'penalty',
  PENALTY_SHOOTOUT: 'penaltyShootout',
  INJURY: 'injury',
  CORNER: 'corner',
  FREE_KICK: 'freeKick',
  SHOT: 'shot',
  COMMENTARY: 'commentary',
  FOUL: 'foul',
  TREATMENT: 'treatment',
  VIDEO_ASSISTANT_REFEREE: 'videoAssistantReferee',
};

export const SUBTYPES = {
  GOAL: 'goal',
  GOAL_PENALTY: 'goalPenalty',
  YELLOW_CARD: 'yellow',
  YELLOW_RED_CARD: 'yellowRed',
  RED_CARD: 'red',
  PERIOD1: 'period1',
  PERIOD2: 'period2',
  OVERTIME_PERIOD_1: 'overtimePeriod1',
  OVERTIME_PERIOD_2: 'overtimePeriod2',
  PENALTY_SHOOTOUT: 'penaltyShootout',
  PENALTY_AWARDED: 'penaltyAwarded',
  PENALTY_MISSED: 'penaltyMissed',
  GOAL_OWN: 'goalOwn',
  PENALTY_SHOOTOUT_MISSED: 'penaltyShootoutMissed',
  GOAL_PENALTY_SHOOTOUT: 'goalPenaltyShootout',
  INJURY: 'injury',
  INJURY_RETURN: 'injuryReturn',
  SHOT_ON: 'shotOn',
  SHOT_SAVED: 'shotSaved',
};
