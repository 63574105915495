export default ({ css }) => {
  const entryThemedWithColor = color => css`
    & > div {
      blockquote {
        background: transparent;
        border: 2px solid ${color};

        &:before {          
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-left: 17px solid transparent;
          border-right: 17px solid transparent;
          border-bottom: 15px solid ${color};
          bottom: -2px;
          left: -18px;
        }

        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-left: 17px solid transparent;
          border-right: 15px solid transparent;
          border-bottom: 15px solid #ffffff;
          bottom: 0;
          left: -13px
        }
      }

      i {
        width: 28px !important;
        height: 28px !important;
        left: -36px !important;
      }

      & > div:last-of-type {
        div:last-of-type {
          color: #000000 !important;
          font-weight: 900 !important;
        }
      }
    }
  `;

  const entryThemedWrapper = css`
    margin-top: 4px;
    margin-bottom: 16px !important;
  `;

  const entryThemed1 = entryThemedWithColor('#bfd7f7');

  const entryThemed2 = entryThemedWithColor('#f1bcbb');

  const entryThemed3 = entryThemedWithColor('#ffedba');

  const entryThemed4 = entryThemedWithColor('#c2f7ed');

  const entryThemed5 = entryThemedWithColor('#c9cfe6');

  const entryThemed6 = entryThemedWithColor('#ffdebf');

  const entryThemedDefault = entryThemedWithColor('#f5d3d3');

  return {
    styles: {
      entryThemedWrapper,
      entryThemed1,
      entryThemed2,
      entryThemed3,
      entryThemed4,
      entryThemed5,
      entryThemed6,
      entryThemedDefault,
    },
  };
};
