const requiredUserDataProperties = [
  'displayName',
];

export const isLoggedInUserDataInProperFormat = (loggedInUser) => {
  if (!loggedInUser) {
    return false;
  }

  const structureValidationResult = requiredUserDataProperties.map(
    requiredProperty => !!loggedInUser[requiredProperty],
  );

  return !(structureValidationResult.indexOf(false) > -1);
};
