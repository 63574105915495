export default ({ css }) => {
  const statusLabel = css`
    overflow: auto;
    border-radius: 8px;
    color: white;
    font-family: "Helvetica", sans-serif;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 20px 20px;
    margin-top: 8px;
  `;

  const title = css`
    font-weight: 700;
    margin-bottom: 7px;
  `;

  const description = css`
    margin-top: -3px;
    margin-bottom: 9px;
  `;

  const success = css`
    background-color: #00b191;
  `;

  const failure = css`
    background-color: #fa766f;
  `;

  return {
    styles: {
      statusLabel,
      title,
      description,
      success,
      failure,
    },
  };
};
