export default ({ css }) => {
  const liveSummary = css`
    box-sizing: border-box;
    background-color: hsla(0, 0%, 100%, .33);
    border: 1px solid rgba(34, 34, 34, .1);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .05);
    color: #333333;
    font-size: 16px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-rendering: optimizeLegibility;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  `;

  const liveSummaryContent = css`
    box-sizing: border-box;
    padding: 20px; // $grid-padding;

    a {
      color: #333333; // $bt-dark-grey-60;
      border-bottom: 1px solid #f6231e; // $bt-red-60;
      padding-bottom: 2px;
      text-decoration: none;
      font-weight: bold;
    }

    li {
      list-style: none;
      margin-bottom: 10px;
      line-height: 1.4em;

      &::before {
        content: "\\25CF";
        color: #f6231e; // $bt-red-60;
        margin-right: .25em;
        position: absolute;
        margin-left: -20px;
        font-size: 24px;
        margin-top: -1px;
      }
    }

    > li {
      // For cases when list-items don't have ul as a wrapper.
      padding-left: 15px;

      &:first-of-type {
        margin-top: 10px;
      }
    }

    ul {
      margin: 0;
      padding-left: 15px;
    }

    ul, ol {
      position: relative;
    }

    p {
      display: table-row;
      vertical-align: middle;
    }
  `;

  return {
    styles: {
      liveSummary,
      liveSummaryContent,
    },
  };
};
