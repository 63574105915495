import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import { getTexts } from '../../config';

export const StructuredImageCaption = ({ caption, byline, theme, publication }) => {
  const texts = getTexts(publication, 'EntryImageInfo');

  if (!caption && !byline) {
    return null;
  }

  const captionView = caption
    ? (
      <span className={theme.imageCaption}>
        {caption}
        {' '}
      </span>
    )
    : null;

  const bylineView = byline
    ? (
      <span className={theme.imageCredit}>
        {texts.fotoText} {byline}
      </span>
    )
    : null;

  return (
    <div className={theme.imageInfoWrapper}>
      {captionView}
      {bylineView}
    </div>
  );
};

StructuredImageCaption.propTypes = {
  caption: PropTypes.string,
  byline: PropTypes.string,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  publication: PropTypes.string.isRequired,
};

StructuredImageCaption.defaultProps = {
  theme: {},
};

export default themer('EntryImageInfo')(StructuredImageCaption);
