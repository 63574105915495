export default ({ css }) => {
  const container = css`
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    padding: 24px;
    font-family: Arial, sans-serif;
  `;

  const heading = css`
    color: #333;
    font-size: 20px;
    line-height: 20px;
  `;

  const description = css`
    color: #787878;
    justify-content: space-between;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    padding: 8px 0 0 0;
  `;

  const button = css`
    font-family: HelveticaNeue-Bold, sans-serif;
    color: #FFF;
    background-color: #2c78e4;
    border-radius: 2px;
    outline-style: none;
    border: none;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;
    white-space: nowrap;
    align-self: center;
    padding: 16px 32px;
    margin: 16px 0 0 0;
    cursor: pointer;
  `;

  return {
    styles: {
      container,
      heading,
      description,
      button,
    },
  };
};
