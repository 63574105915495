import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import Author from '../Author/Author';
import { FeedSettingsDefaultProps, FeedSettingsShape } from '../../shapes';

const JournalistReply = ({
  content,
  authorName,
  authorImage,
  authorType,
  theme,
  isUserQuote,
  isChat,
  feedSettings,
  publication,
}) => (
  <div className={theme.journalistReply}>
    <blockquote className={theme.journalistReplyBlockquote}>
      {content}
    </blockquote>
    <div className={theme.journalistReplyAuthor}>
      <Author
        authorName={authorName}
        authorImage={authorImage}
        authorType={authorType}
        isUserQuote={isUserQuote}
        isChat={isChat}
        isJournalistReply
        feedSettings={feedSettings}
        publication={publication}
      />
    </div>
  </div>
);


JournalistReply.propTypes = {
  content: PropTypes.array,
  authorName: PropTypes.string,
  authorImage: PropTypes.string,
  authorType: PropTypes.string,
  isUserQuote: PropTypes.bool,
  isChat: PropTypes.bool,
  publication: PropTypes.string.isRequired,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  feedSettings: FeedSettingsShape,
};

JournalistReply.defaultProps = {
  content: [],
  authorName: null,
  authorImage: null,
  authorType: null,
  isUserQuote: false,
  isChat: false,
  feedSettings: FeedSettingsDefaultProps,
  theme: {},
};

export default themer('JournalistReply')(JournalistReply);
