export default ({ css, defaultStyles }) => {
  const { SportsEntryHeadline } = defaultStyles;

  const sportsHeadlineText = css`
    ${SportsEntryHeadline.sportsHeadlineText};

    font-family: Verdana, sans-serif;
    font-size: 15px;
    letter-spacing: -0.2px;
  `;

  const goal = css`
    left: -34px;
  `;

  const periodStart = css`
    left: -34px;
  `;

  const scoreChange = css`
    left: -34px;
  `;

  return {
    styles: {
      ...SportsEntryHeadline,
      sportsHeadlineText,
      goal,
      periodStart,
      scoreChange,
    },
  };
};
