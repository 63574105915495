import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

export const PlayerSubstitutionContent = ({ playerIn, playerOut, theme }) => (
  <div className={theme.playerSubstitutionDetails}>
    <p className={theme.playerIn}>{playerIn}</p>
    <p className={theme.playerOut}>{playerOut}</p>
  </div>
);

PlayerSubstitutionContent.propTypes = {
  playerIn: PropTypes.string,
  playerOut: PropTypes.string,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default themer('PlayerSubstitutionContent')(PlayerSubstitutionContent);
