
export default ({ css, defaultStyles }) => {
  const { SportsEntryContent } = defaultStyles;

  const sportsEntryContent = css`
    ${SportsEntryContent.sportsEntryContent};

    color: var(--c-neutral-05, #787878);
  `;

  const sportsEntryText = css`
    ${SportsEntryContent.sportsEntryText};

    color: var(--c-dark, #333);
  `;

  return {
    styles: {
      ...SportsEntryContent,
      sportsEntryContent,
      sportsEntryText,
    },
  };
};
