import {
  colorPrimary,
  colorSportbladet,
} from '../variables';

import {
  className,
  uniqueClassName,
  sportbladet,
} from '../functions';

export default ({ css, defaultStyles }) => {
  const { MessageFormButton } = defaultStyles;

  const button = uniqueClassName(css, 'button')(css`
    ${MessageFormButton.button};

    background-color: var(--c-primary-01, ${colorPrimary});
    border-radius: 24px;
    box-shadow: 1px 2px 0 0 rgba(0, 0, 0, 0.08);
    color: #fff;
    font-size: 12px !important;
    font-weight: bold;
    line-height: 14px !important; // unfortunate but we need to override the orignals !important
    padding: 16px 32px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  `);

  const buttonDisabled = uniqueClassName(css, 'buttonDisabled')(css`
    ${MessageFormButton.buttonDisabled};

    color: var(--c-primary-01, ${colorPrimary});
    background: transparent;
    font-size: 14px !important;
    font-weight: unset;
    box-shadow: unset;
    border: none;
    text-transform: none;
    letter-spacing: 0.47px;
    line-height: 16px;
    text-align: center;
    text-decoration: underline;
    padding: 0;
  `);

  const buttonRaised = uniqueClassName(css, 'buttonRaised')(css`
    background: var(--c-primary-01, ${colorPrimary});
    font-family: HelveticaNeue-Medium, sans-serif;
    font-weight: unset;
    text-transform: unset;
    letter-spacing: 0.3px;
    padding: 10px 0;

    @media (min-width: 768px) {
      font-size: 16px !important;
      margin-top: 7px;
      padding: 12px 0;
    }
  `);

  const styles = {
    ...MessageFormButton,
    button,
    buttonDisabled,
    buttonRaised,
  };

  const globalStyles = sportbladet(`
    ${className(button)} {
      background-color: var(--c-primary-01, ${colorSportbladet};
    }

    ${className(buttonDisabled)} {
      color: ${colorSportbladet};
      background-color: transparent;
    }
  `);

  return {
    styles,
    globalStyles,
  };
};
