import triggerNrk from './../scripts/triggerNrkVideo';

export const isImage = node => node.attrs && node.attrs.class.includes('mm-image-wrapper');

export const isVideo = node => node.attrs && node.attrs.class.includes('mm-iframe-video');

export const isMediaEmbed = node => (
  node.attrs
  && node.attrs.class
  && (isImage(node) || isVideo(node)
  )
);

export const isNrkVideo = node => (
  node.attrs
  && node.attrs.class === 'nrk-video'
);

export const triggerNrkVideoInit = () => {
  triggerNrk();
};
