import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import cc from 'classcat';

import { TYPE_SUCCESS, TYPE_ERROR } from './Types';
import TickIcon from './TickIcon';
import CrossIcon from './CrossIcon';

export const StatusLabel = ({ content, statusType, visibility, theme }) => {
  const statusLabelClassnames = cc({
    [theme.statusLabel]: true,
    [theme.success]: statusType === TYPE_SUCCESS,
    [theme.failure]: statusType === TYPE_ERROR,
  });

  const retrieveIconForStatus = (status) => {
    switch (status) {
      case TYPE_SUCCESS:
        return <TickIcon />;
      case TYPE_ERROR:
        return <CrossIcon />;
      default:
        throw new Error('Not supported status for label');
    }
  };

  return visibility ? (
    <div className={statusLabelClassnames}>
      <div className={theme.title}>
        { content[0] }
      </div>

      {retrieveIconForStatus(statusType)}

      <div className={theme.description}>
        { content[1] }
      </div>
    </div>
  ) : null;
};

StatusLabel.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  statusType: PropTypes.oneOf([TYPE_ERROR, TYPE_SUCCESS]).isRequired,
  visibility: PropTypes.bool.isRequired,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

StatusLabel.defaultProps = {
  theme: {},
};

export default themer('MessageFormStatusLabel')(StatusLabel);
