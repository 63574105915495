import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

const EntryTags = ({ tags, theme }) => (
  <ul className={theme.tagsList}>
    {tags.map(tag => (
      <li key={tag.id} className={theme.tagsListItem}>
        {tag.title}
      </li>
    ))}
  </ul>
);

EntryTags.defaultProps = {
  theme: {},
  tags: [],
};

EntryTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default themer('EntryTags')(EntryTags);
