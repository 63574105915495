import {
  colorBlack,
  colorGray,
} from './variables';

export default ({ css, defaultStyles }) => {
  const { CopyToClipboard } = defaultStyles;

  const copyToClipboardWrapper = css`
    ${CopyToClipboard.copyToClipboardWrapper};
    
    color: ${colorGray};
  `;

  const copiedToast = css`
    ${CopyToClipboard.copiedToast};
    
    background-color: ${colorBlack};
    right: 58px;
    
    &::after {
      border-left-color: ${colorBlack};
    }
  `;

  return {
    styles: {
      ...CopyToClipboard,
      copyToClipboardWrapper,
      copiedToast,
    },
  };
};
