import { uniqueClassName, className } from '../functions';

import { sportbladet } from './functions';
import { colorPrimary, colorSportbladet } from './variables';

export default ({ css, defaultStyles }) => {
  const { LoadMoreEntries } = defaultStyles;

  const loadMoreButton = uniqueClassName(css, 'loadMoreButton')(css`
    ${LoadMoreEntries.loadMoreButton};

    background-color: var(--c-primary-01, ${colorPrimary});
    border-color: var(--c-primary-01, ${colorPrimary});
    border-radius: 100px;
    color: #fff;
    padding: 8px 32px;

    @media (min-width: 768px) {
      padding: 8px 32px;
    }

    &::after {
      display: none;
    }
  `);

  const styles = {
    ...LoadMoreEntries,
    loadMoreButton,
  };

  const globalStyles = sportbladet(`
    ${className(loadMoreButton)} {
      background-color: var(--c-primary-01, ${colorSportbladet});
      border-color: var(--c-primary-01, ${colorSportbladet};
    }
  `);

  return {
    styles,
    globalStyles,
  };
};
