import { resolveEntryType as _resolveEntryType } from '../utils/resolveEntryType';
import { canUserEditEntry as _canUserEditEntry } from './canUserEditEntry';

export const decorateRawEntry = (
  rawEntry,
  user,
  resolveEntryType = _resolveEntryType,
  canUserEditEntry = _canUserEditEntry,
) => {
  const typeFlags = resolveEntryType(rawEntry);

  return {
    ...rawEntry,
    ...typeFlags,
    isEditable: canUserEditEntry(user, rawEntry)
      && (typeFlags.isRegular || typeFlags.isNtb),
  };
};
