export default ({ css }) => {
  const mobileVersion = 'max-width: 768px';

  const articleTeaserWrapper = css`
    margin: 16px 0 16px;
    overflow: auto;
    border-bottom: none !important;

    &:hover {
      border: none;
    }
  `;

  const articleTeaserContent = css`
    display: flex;
    width: 100%;
    max-width: 800px;
  `;

  const articleTeaserTextContent = css`
    margin: 0 0 0 8px;
    color: #333333;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (${mobileVersion}) {
      max-width: 326px;
    }
  `;

  const articleTeaserTitle = css`
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (${mobileVersion}) {
      font-size: 17px;
      line-height: 1.29;
    }
  `;

  const articleTeaserTitleLonger = css`
    -webkit-line-clamp: 3;
  `;

  const articleTeaserMeta = css`
    display: flex;
  `;

  const articleTeaserPublishedDate = css`
    font-size: 13px;
    letter-spacing: 0.1px;
    line-height: 1.18;
    text-transform: uppercase;
    color: #6f6f6f;
    margin-bottom: 3px;
  `;

  const articleTeaserMediaContent = css`
    display: inline-block;

    img {
      width: 96px;
      height: 72px;
      vertical-align: middle;
      box-sizing: border-box;
      border-radius: 6px;
      object-fit: cover;
    }
  `;

  return {
    styles: {
      articleTeaserWrapper,
      articleTeaserContent,
      articleTeaserTextContent,
      articleTeaserTitle,
      articleTeaserTitleLonger,
      articleTeaserMeta,
      articleTeaserPublishedDate,
      articleTeaserMediaContent,
    },
  };
};
