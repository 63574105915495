import {
  colorPrimary,
  colorSportbladet,
} from '../variables';

import {
  className,
  uniqueClassName,
  sportbladet,
} from '../functions';

export default ({ css, defaultStyles }) => {
  const { MessageFormInput } = defaultStyles;

  const counter = css`
    ${MessageFormInput.counter};

    border-bottom-right-radius: 8px;
  `;

  const input = uniqueClassName(css, 'input')(css`
    ${MessageFormInput.input};

    border-radius: 8px;
  `);

  const validationErrorContainer = uniqueClassName(
    css,
    'validationErrorContainer',
  )(
    css`
    ${MessageFormInput.validationErrorContainer};

    border-radius: 8px;
    border-color: var(--c-primary-01, ${colorPrimary});

    ${className(input)}::-webkit-input-placeholder {
      color: var(--c-primary-01, ${colorPrimary});
    }
  `);

  const styles = {
    ...MessageFormInput,
    input,
    counter,
    validationErrorContainer,
  };

  const globalStyles = sportbladet(`
    ${className(validationErrorContainer)} {
      background-color: var(--c-primary-01, ${colorSportbladet};

      ${className(input)}::-webkit-input-placeholder {
        color: var(--c-primary-01, ${colorSportbladet};
      }
    }
  `);

  return {
    styles,
    globalStyles,
  };
};
