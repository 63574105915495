export default ({ css }) => {
  const loadMoreWrapper = css`
    margin: 20px 0;
  `;

  const loadMoreButton = css`
    outline-style: none;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: white;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4px;
    background: #2c78e4;
    border-radius: 2px;
    border: none;
    display: block;
    position: relative;
    cursor: pointer;
    padding: 8px 48px;
    margin: 0 auto;

    @media (min-width: 768px) {
      padding: 8px 103px;
    }

    &::after {
      content: '';
      border: solid #FFF;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3.5px;
      transform: rotate(45deg);
      position: absolute;
      right: 11px;
      top: 9px;

      @media (min-width: 768px) {
        right: 20px;
        top: 10px;
      }
    }
  `;

  return {
    styles: {
      loadMoreWrapper,
      loadMoreButton,
    },
  };
};
