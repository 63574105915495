const removeLimitParamFromUrl = (url = '') => url.replace(/&?limit=\d+/gmi, '').replace(/\?&/gmi, '?');

const getLimitParamFromUrl = (url) => {
  const results = /&?limit=(\d+)/gmi.exec(url);

  return results === null ? null : results[1];
};

const getParamsForEntriesEndpoint = ({
  lastEntryId,
  oldestRegularEntryId,
  cacheVersion,
  pagination,
  forceLimit,
  blogSettings = {},
}) => {
  const params = [];

  if (forceLimit) {
    params.push(['limit', forceLimit]);
  } else if (pagination && !lastEntryId && !blogSettings.isSportsLiveblog) {
    params.push(['limit', pagination + 1]);
  }

  if (lastEntryId && !blogSettings.isSportsLiveblog) {
    params.push(['minId', lastEntryId]);
  }

  if (oldestRegularEntryId) {
    params.push(['maxId', oldestRegularEntryId]);
  }

  if (cacheVersion) {
    params.push(['cacheVersion', cacheVersion]);
  }

  return params;
};

export {
  removeLimitParamFromUrl,
  getParamsForEntriesEndpoint,
  getLimitParamFromUrl,
};
