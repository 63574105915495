import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash.get';

import StructuredText from './StructuredText';
import StructuredImage from './StructuredImage';
import { StructuredContentShape } from '../../shapes';
import { TEXT_TYPE, IMAGE_TYPE } from '../structuredContent';

export const SUPPORTED_COMPONENT_TYPES = [
  IMAGE_TYPE,
  TEXT_TYPE,
];

export const EntryStructuredContent = ({
  structuredContent,
  publication,
}) => {
  const { components } = structuredContent;

  const mappedContent = components
    .map(
      (component, index) => {
        const { type } = component;
        const key = `entry-structured-content-${index}`;

        if (type === TEXT_TYPE) {
          return (
            <StructuredText
              component={component}
              key={key}
            />
          );
        }

        if (type === IMAGE_TYPE) {
          const imageId = _get(component, 'imageAsset.id') || index;
          const imageKey = `entry-structured-content-${imageId}`;
          return (
            <StructuredImage
              component={component}
              publication={publication}
              key={imageKey}
            />
          );
        }

        return null;
      });

  return (
    <div>
      {mappedContent}
    </div>
  );
};

EntryStructuredContent.propTypes = {
  structuredContent: StructuredContentShape.isRequired,
  publication: PropTypes.string.isRequired,
};


export const isEntrySupported = structuredContent => !!(
  structuredContent
  && structuredContent.components
  && structuredContent.components.filter(
    ({ type }) => SUPPORTED_COMPONENT_TYPES.indexOf(type) === -1,
  ).length === 0
);
