import _createErrorMessage from './createErrorMessage';

const ThrowError = (
  url,
  createErrorMessage = _createErrorMessage,
) => (message) => {
  throw new Error(createErrorMessage(url, message));
};

export default ThrowError;
