import PropTypes from 'prop-types';
import React from 'react';

import ThemeProvider from './ThemeProvider';

const selectStylingById = (id, themes) =>
  themes[id] || themes.default;

const mergeThemes = (whitelabel, local) => {
  const merged = {};
  const allThemes = Object.keys(whitelabel).concat(Object.keys(local));

  allThemes.forEach((theme) => {
    merged[theme] = {
      ...whitelabel.default,
      ...local.default,
      ...whitelabel[theme],
      ...local[theme],
    };
  });

  return merged;
};

const SelectedThemeProvider = ({
  id,
  whitelabelThemesMapping,
  localThemesMapping,
  children,
}) => {
  const mergedThemes = mergeThemes(whitelabelThemesMapping, localThemesMapping);

  const activeTheme = selectStylingById(id, mergedThemes);

  return (
    <ThemeProvider theme={activeTheme}>
      { children }
    </ThemeProvider>
  );
};

const themeType = PropTypes.shape({
  default: PropTypes.object,
});

SelectedThemeProvider.propTypes = {
  id: PropTypes.string,
  whitelabelThemesMapping: themeType,
  localThemesMapping: themeType,
  children: PropTypes.node.isRequired,
};

SelectedThemeProvider.defaultProps = {
  id: 'default',
  whitelabelThemesMapping: {},
  localThemesMapping: {},
};

export default SelectedThemeProvider;
