import {
  className,
  uniqueClassName,
} from '../functions';

export default ({ css }) => {
  const entryImageContent = uniqueClassName(css, 'entryImageContent')(css`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  `);

  const entryImageWrapper = css`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    margin-top: 10px;
    background-size: contain;

    &[data-crop="16:9"] {
      padding-bottom: 56.25%;
    }

    &[data-crop="4:3"] {
      padding-bottom: 75%;
    }

    &[data-crop="3:4"] {
      padding-bottom: 133.33%;
    }

    &[data-crop="2:3"] {
      padding-bottom: 150%;
    }

    &[data-crop="1:1"] {
      padding-bottom: 100%;
    }

    &:not([data-crop]), &[data-crop="Free"], &[data-crop="Auto"], &[data-crop=""] {
      background: none;
      padding-bottom: 0;
      height: auto;

      ${className(entryImageContent)} {
        height: auto;
        position: relative;
      }
    }

    @media all and (max-width: 420px) {
      margin: 0 auto;
      border-top: solid 2px #fff;
    }
  `;

  return {
    styles: {
      entryImageWrapper,
      entryImageContent,
    },
  };
};
