export const EVENT_TYPES = {
  UPDATE_LIVEBLOG: 'updateLiveblog',
  PUBLISH_ENTRY: 'publishEntry',
  DELETE_ENTRY: 'deleteEntry',
  UPDATE_ENTRY: 'updateEntry',
  PUBLISH_SPORT_INCIDENT: 'publishSportsIncident',
  PUBLISH_USER_MESSAGE: 'publishUserMessage',
  UPDATE_USER_MESSAGE: 'updateUserMessages',
  UPDATE_USER_MESSAGES: 'updateUserMessage',
  DELETE_USER_MESSAGE: 'deleteUserMessage',
  CREATE_FEED: 'createFeed',
  UPDATE_FEED: 'updateFeed',
  DELETE_FEED: 'deleteFeed',
};

export const USER_MESSAGE_EVENTS = [
  EVENT_TYPES.PUBLISH_USER_MESSAGE,
  EVENT_TYPES.UPDATE_USER_MESSAGE,
  EVENT_TYPES.UPDATE_USER_MESSAGES,
  EVENT_TYPES.DELETE_USER_MESSAGE,
];

export const ENTRY_EVENTS = [
  EVENT_TYPES.UPDATE_LIVEBLOG,
  EVENT_TYPES.PUBLISH_ENTRY,
  EVENT_TYPES.DELETE_ENTRY,
  EVENT_TYPES.UPDATE_ENTRY,
];

export const FEED_EVENTS = [
  EVENT_TYPES.CREATE_FEED,
  EVENT_TYPES.UPDATE_FEED,
  EVENT_TYPES.DELETE_FEED,
];

export const SPORT_EVENTS = [
  EVENT_TYPES.PUBLISH_SPORT_INCIDENT,
];
