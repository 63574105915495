import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import CloseIcon from './CloseIcon';

export const UploadedFiles = ({ theme, uploadedFiles, removeUploadedFile }) => {
  const uploadedFilesView = uploadedFiles
    .filter(element => element)
    .map((element, index) => {
      const key = `uploaded-file-${index}-key`;

      return (<div key={key}>
        {element.name}

        <i onClick={removeUploadedFile} role="button" className={theme.removeButton} tabIndex="0">
          <CloseIcon />
        </i>
      </div>);
    });

  return (
    <div className={theme.uploadedFilesWrapper}>
      {uploadedFilesView}
    </div>
  );
};

UploadedFiles.propTypes = {
  removeUploadedFile: PropTypes.func,
  uploadedFiles: PropTypes.array,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

UploadedFiles.defaultProps = {
  removeUploadedFile: () => {},
  uploadedFiles: [],
  theme: {},
};

export default themer('MessageFormUploadedFiles')(UploadedFiles);
