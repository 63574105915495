import {
  counterBackground,
  counterColor,
} from './variables';

export default ({ css }) => {
  const defaultContainer = css`
    margin-bottom: 8px;
    position: relative;
  `;

  const counter = css`
    background-color: ${counterBackground};
    color: ${counterColor};
    font-size: 14px;
    padding: 2px 10px 9px;
    text-align: right;
    letter-spacing: 0.2px;
    line-height: 18px;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 0 0 4px 4px;
  `;

  return {
    defaultContainer,
    counter,
  };
};
