export default ({ css }) => {
  const wrapper = css`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.3%;
    overflow: hidden;
    margin: 10px 0;

    div[id^="livefeed-video-asset"] {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  `;

  return {
    styles: {
      wrapper,
    },
  };
};
