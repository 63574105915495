import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import { ThemeShape } from '../../shapes';

export const EntryTitle = ({ title, theme }) => (
  title ? (
    <div className={theme.entryTitle}>
      {title}
    </div>
  ) : null
);

EntryTitle.propTypes = {
  theme: ThemeShape,
  title: PropTypes.string,
};

EntryTitle.defaultProps = {
  theme: {},
  title: '',
};

export default themer('EntryTitle')(EntryTitle);
