
export default ({ css, defaultStyles }) => {
  const { Liveblog } = defaultStyles;

  const noEntriesMessage = css`
    ${Liveblog.noEntriesMessage};

    color: var(--c-dark, #333);
  `;


  return {
    styles: {
      ...Liveblog,
      noEntriesMessage,
    },
  };
};
