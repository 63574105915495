import React from 'react';
import PropTypes from 'prop-types';

const OnErrorComponent = ({
  error,
  errorInfo,
}) => {
  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  if (errorInfo) {
    // eslint-disable-next-line no-console
    console.error(errorInfo);
  }

  return (
    <div
      style={{
        textAlign: 'center',
        backgroundColor: '#EBEDF0',
        padding: '10px',
      }}
    >
      There is something wrong with the live feed.
      <br /><br />Refresh the page to load the feed again.
      <br /><br />We are working to fix this issue.
    </div>
  );
};

OnErrorComponent.propTypes = {
  error: PropTypes.object,
  errorInfo: PropTypes.object,
};

OnErrorComponent.defaultProps = {
  error: null,
  errorInfo: null,
};

export default OnErrorComponent;
