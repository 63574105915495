import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import { getTexts } from '../../../../config';

export class GoalContent extends Component {
  getTeamScoresLine(currentScores, texts) {
    const teams = Object.keys(currentScores);

    if (teams.length !== 2) {
      return null;
    }

    return texts.currentScores
      .replace('{team1}', teams[0])
      .replace('{score1}', currentScores[teams[0]])
      .replace('{score2}', currentScores[teams[1]])
      .replace('{team2}', teams[1]);
  }

  render() {
    const { currentScores, scorer, assistant, theme, publication } = this.props;
    const texts = getTexts(publication, 'GoalContent', 'SportsLive');

    const teamScoresLine = this.getTeamScoresLine(currentScores, texts);
    const scorerLine = scorer
      ? texts.scorer.replace('{scorer}', scorer)
      : null;
    const assistantLine = assistant
      ? texts.assistant.replace('{assistant}', assistant)
      : null;

    return (
      <div>
        <p className={theme.goalCurrentScores}>{teamScoresLine}</p>
        <p>{scorerLine}</p>
        <p>{assistantLine}</p>
      </div>
    );
  }
}

GoalContent.propTypes = {
  // see shapes.js for reason
  // eslint-disable-next-line react/forbid-prop-types
  currentScores: PropTypes.object,
  scorer: PropTypes.string,
  assistant: PropTypes.string,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  publication: PropTypes.string.isRequired,
};

export default themer('GoalContent')(GoalContent);
