import _getTopicNames from './getTopicNames';

const EventListener = (
  {
    client,
    publicationId,
    liveblogId,
    onMessage,
    subscribedEvents,
    resourceNames,
    isStageMode,
  },
  getTopicNames = _getTopicNames,
) => {
  const topicNames = getTopicNames(publicationId, liveblogId, resourceNames, isStageMode);

  const handleMessage = (message) => {
    if (subscribedEvents.includes(message.type)) {
      onMessage(message);
    }
  };

  const subscribe = () =>
    topicNames.forEach(topic => client.onTopicMessage(topic, handleMessage));

  return {
    subscribe,
  };
};

export default EventListener;
