import { findLastIds as _findLastIds } from '../../utils/findLastIds';
import { determineQuantitativeFlags as _determineQuantitativeFlags } from '../../utils/determineQuantitativeFlags';

export const createHandleEntryMessage = (
  handleSpecificMessage,
  findLastIds = _findLastIds,
  determineQuantitativeFlags = _determineQuantitativeFlags,
) => (
  decoratedEntry,
  currentNormalizedEntries,
  currentEntriesCount,
  currentEntriesAuthors,
) => {
  const {
    updatedEntries,
    entriesCount,
    entriesAuthors,
  } = handleSpecificMessage(
    decoratedEntry,
    currentNormalizedEntries,
    currentEntriesCount,
    currentEntriesAuthors,
  );

  return {
    fetchedEntries: updatedEntries,
    entriesCount,
    entriesAuthors,
    ...findLastIds(updatedEntries),
    ...determineQuantitativeFlags(updatedEntries, entriesCount),
  };
};
