/* eslint-disable no-mixed-operators */
import { errorMessages } from './const';

export const getRandomNumber = (number) => {
  if (isNaN(number) || parseInt(number, 10) < 0) {
    throw new Error(errorMessages.numberHelper.invalidNumberInput);
  }

  const min = Math.floor(number * 0.8);
  const max = Math.round(number * 1.2);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getFlooredNumber = (number, factor = 10) => {
  if (isNaN(number) || parseInt(number, 10) < 0) {
    throw new Error(errorMessages.numberHelper.invalidNumberInput);
  }

  const logFactor = Math.log10(factor);

  if (!Number.isInteger(logFactor) || logFactor < 1) {
    throw new Error(errorMessages.numberHelper.invalidFactorInput);
  }

  if (number === 0) {
    return 0;
  }

  return Math.floor(number / factor) * factor;
};
