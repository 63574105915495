import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import StructuredImageCaption from './StructuredImageCaption';
import { StructuredImageShape, ThemeShape } from '../../shapes';
import { ImageComponent } from '../structuredContent';

const ONE_SECOND_IN_MS = 1000;
const TWO_SECONDS = 2;

export class StructuredImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delayedImage: null,
    };
    this.image = new ImageComponent(this.props.component);
  }

  randomizeDelayTime(maxDelay) {
    const delay = Math.random() * maxDelay * ONE_SECOND_IN_MS;

    return Number.parseInt(delay, 10);
  }

  delayImage(image) {
    setTimeout(
      () => this.setState(
        { delayedImage: image }),
      this.randomizeDelayTime(TWO_SECONDS),
    );
  }
  componentDidMount() {
    this.delayImage(<img
      className={this.props.theme.entryImageContent}
      srcSet={this.image.getSrcset()}
      src={this.image.getDefaultUrl()}
      alt={this.image.getCaption()}
    />);
  }

  render() {
    const { publication, theme } = this.props;

    return (
      <div>
        <div className={theme.entryImageWrapper} data-crop={this.image.getCrop()}>
          {this.state.delayedImage}
        </div>
        <StructuredImageCaption
          caption={this.image.getCaption()}
          byline={this.image.getByline()}
          publication={publication}
        />
      </div>
    );
  }
}

StructuredImage.propTypes = {
  component: StructuredImageShape.isRequired,
  publication: PropTypes.string.isRequired,
  theme: ThemeShape,
};

StructuredImage.defaultProps = {
  theme: {},
};

export default themer('EntryImage')(StructuredImage);
