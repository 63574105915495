const SUPPORTED_MESSAGE_TYPES = [
  'publishEntry',
  'deleteEntry',
  'publishSportsIncident',
];

const assembleFromPublishOrDeleteEntryMessage = (message) => {
  const { type, body: { id } } = message;

  return SUPPORTED_MESSAGE_TYPES.includes(type)
    ? `${type}-${id}`
    : null;
};

export default assembleFromPublishOrDeleteEntryMessage;
