/* eslint-disable react/jsx-filename-extension */
import { isBrowser } from './env';

export function getScrollY(scrollableElement) {
  // scrollableElement may be a window or custom element
  // - `scrollTop` is for custom scrollableElement
  // - `pageYOffset` is for window
  return scrollableElement.scrollTop || scrollableElement.pageYOffset;
}

export function getOffsetTop(scrollableElement, el) {
  if (!isBrowser()) {
    return 0;
  }

  const box = el.getBoundingClientRect() || { top: 0 };

  return box.top + getScrollY(scrollableElement);
}

export function scrollToY(scrollableElement, offset, speed = 2000) {
  const scrollTargetY = offset;
  const scrollY = getScrollY(scrollableElement);

  let currentTime = 0;

  // min time .1, max time .8 seconds
  const time = Math.max(0.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8));

  // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
  const easeInOut = pos => (-0.5 * (Math.cos(Math.PI * pos) - 1));

  function tick() {
    currentTime += 1 / 60;

    const p = currentTime / time;
    const t = easeInOut(p);

    if (p < 1) {
      window.requestAnimationFrame(tick);
      scrollableElement.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
    } else {
      scrollableElement.scrollTo(0, scrollTargetY);
    }
  }

  tick();
}

export function wrapContent(content) { return { __html: content }; }

export function unescape(string) {
  const htmlUnescapes = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
  };

  const reEscapedHtml = /&(?:amp|lt|gt|quot|#39);/g;
  const reHasEscapedHtml = new RegExp(reEscapedHtml.source);

  return (string && reHasEscapedHtml.test(string))
    ? string.replace(reEscapedHtml, entity => htmlUnescapes[entity])
    : string;
}

function camelize(string) {
  return string.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => (index === 0 ? letter.toLowerCase() : letter.toUpperCase())).replace(/\s+|-/g, '');
}

function parseStyleString(styleString) {
  if (!styleString) return {};
  const unescapedStyles = unescape(styleString);
  const splitStyle = unescapedStyles.split(';');

  const jsonStyles = {};
  for (let i = 0; i < splitStyle.length; i++) {
    const singleStyle = splitStyle[i].split(/:(.+)/);
    const key = camelize(singleStyle[0].trim());
    const value = singleStyle[1];
    if (key.length > 0 && value && value.length > 0) {
      jsonStyles[key] = value;
    }
  }
  return jsonStyles;
}

export function createElementProps(node, ix) {
  const elementProps = {
    key: ix,
  };
  if (node.attrs) {
    for (let i = 0, keys = Object.keys(node.attrs); i < keys.length; i++) {
      if (keys[i].includes('http')) { elementProps.src = keys[i]; }
      switch (keys[i]) {
        case 'style':
          elementProps.style = parseStyleString(node.attrs[keys[i]]);
          break;
        case 'class':
          elementProps.className = node.attrs[keys[i]];
          break;
        case 'data-crop':
          elementProps.crop = node.attrs[keys[i]];
          break;
        case 'data-img-caption':
          elementProps.caption = node.attrs[keys[i]];
          break;
        case 'data-img-credit':
          elementProps.credit = node.attrs[keys[i]];
          break;
        case 'data-img-template':
          elementProps.src = node.attrs[keys[i]];
          break;
        default:
          elementProps[keys[i]] = node.attrs[keys[i]];
      }
    }
  }
  return elementProps;
}

export function regexParameter(parameter, string) {
  const regex = new RegExp(`[?&]${parameter}=(.*?)(&|$)`, 'g');
  return regex.exec(string);
}
/* eslint-disable no-bitwise */
export function hashString(string) {
  let hash = 0;
  let i;
  let chr;

  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
/* eslint-enable no-bitwise */
/* eslint-enable react/jsx-filename-extension */
export const voidElementTags = [
  'area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'iframe',
  'keygen', 'link', 'meta', 'param', 'source', 'track', 'wbr', 'textarea',
];
export function isVoidElement(nodeName) {
  return voidElementTags.includes(nodeName);
}

export const isInViewport = (node) => {
  if (!node) {
    return false;
  }

  const { top, bottom } = node.getBoundingClientRect();
  const { scrollY, innerHeight: height } = window;

  const realTop = top + scrollY;
  const realBottom = bottom + scrollY;
  const vpTop = scrollY;
  const vpBot = scrollY + height;

  // top is in vp
  if (realTop >= vpTop && realTop <= vpBot) {
    return true;
  }

  // bottom is in vp
  if (realBottom >= vpTop && realBottom <= vpBot) {
    return true;
  }

  // element taller than the screen and screen in the middle of element
  if (realBottom >= vpBot && realTop <= vpTop) {
    return true;
  }

  return false;
};
