import { DEFAULT_LAST_ENTRY_ID } from './findLastIds';
import { POLLING_STRATEGIES } from './consts';

const defaultState = {
  fetchedEntries: [],
  hasMoreEntries: true,
  isFetchingBelow: false,
  lastEntryId: DEFAULT_LAST_ENTRY_ID,
  liveblogDataLoaded: false,
  entriesAuthors: [],
  propsPinnedEntryId: null,
  pinnedEntry: null,
  cacheVersion: null,
  entriesCount: 0,
  liveFeedUrl: null,
  isInitialFetchDone: false,
  error: null,
  loggedInUser: {},
  backendBaseUrl: null,
  feedId: null,
  placement: null,
  feedSettings: {
    title: null,
    description: null,
    actionText: null,
    summary: null,
    allowUserMessages: true,
    theme: null,
  },
  blogSettings: {
    closed: false,
    pinnedEntryId: null,
    pinnedEntryFeedId: null,
    pollingStrategy: POLLING_STRATEGIES.NORMAL,
    isUserMessagesLoginRequired: false,
    isSeeMoreFeatureLoginRequired: false,
    isSportsLiveblog: false,
  },
};

const getDefaultContainerState = ({
  pinnedEntryId,
  liveFeedUrl,
  feedId,
  placement,
}) => {
  const propsPinnedEntryId = pinnedEntryId || null;
  const [backendBaseUrl] = liveFeedUrl.split('/entries');

  return {
    ...defaultState,
    propsPinnedEntryId,
    liveFeedUrl,
    backendBaseUrl,
    feedId,
    placement,
  };
};

export {
  defaultState,
  getDefaultContainerState,
};
