export default ({ css }) => {
  const wrapper = css`
    position: relative;
    padding: 0;
    overflow-anchor: none;
  `;

  const liveblog = css`
    position: relative;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: Arial, sans-serif;
    font-weight: 400;
    margin: 0 0 0 13px;
    padding: 8px 0 0 8px !important; // Temp fix to integration with articles.

    @media(min-width: 767px) {
      padding-top: 40px;
    }

    a {
      color: #1a76aa;
    }

    strong, b {
      font-weight: bold;
    }

    em, i {
      font-style: italic;
    }

    s, del, strike {
      text-decoration: line-through;
    }
  `;

  const sportLiveblog = css`
    //sportLiveblog class name
  `;

  const adContainer = css`
    &:not(:empty) {
      margin: 12px 0;
    }
  `;

  const noEntriesMessage = css`
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 17px;
    text-align: center;
    margin-top: 20px;
  `;

  return {
    styles: {
      wrapper,
      liveblog,
      sportLiveblog,
      adContainer,
      noEntriesMessage,
    },
  };
};
