import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import cc from 'classcat';

import {
  AuthorTypeShape,
  FeedSettingsDefaultProps,
  FeedSettingsShape,
} from '../../shapes';
import { isAuthorAJournalist } from '../../utils/authorHelpers';
import { LIVEFEED_THEMES } from '../../utils/constants';

export const Author = ({
  authorName,
  authorImage,
  authorType,
  isUserQuote,
  isChat,
  theme,
  isJournalistReply,
  feedSettings,
}) => {
  // make sure that we don't show two avatars at once
  if (!authorName || (isUserQuote || isChat) || feedSettings.theme === LIVEFEED_THEMES.SPEECH_BUBBLES) {
    return (
      <div className={theme.defaultAuthorField}>
        <div className={theme.defaultAvatarPlaceholder} />
      </div>
    );
  }

  const authorWithoutAvatarView = (
    <div className={theme.defaultAuthorField}>
      <div className={theme.defaultAvatarPlaceholder} />
      <span className={theme.defaultAuthorName}>
        {authorName}
      </span>
    </div>
  );

  if (!authorImage) {
    return authorWithoutAvatarView;
  }

  const imgStyle = {
    backgroundImage: `url(${authorImage})`,
  };

  const styledAuthorField = cc({
    [theme.styledAuthorField]: true,
    [theme.journalistReplyAuthorField]: isJournalistReply,
  });

  const avatarStyle = cc({
    [theme.styledAvatar]: true,
    [theme.styledPublicationLogo]: isAuthorAJournalist(authorType),
    [theme.journalistReplyAuthorImage]: isJournalistReply,
  });

  const authorNameStyle = cc({
    [theme.styledAuthorName]: !isJournalistReply,
    [theme.journalistReplyAuthorName]: isJournalistReply,
    [theme.authorNameMarginLeft]: isAuthorAJournalist(authorType),
  });

  return (
    <div className={styledAuthorField}>
      <div
        className={avatarStyle}
        style={imgStyle}
      />
      <div className={authorNameStyle}>{authorName}</div>
    </div>
  );
};

Author.propTypes = {
  authorName: PropTypes.string,
  authorImage: PropTypes.string,
  authorType: AuthorTypeShape,
  isUserQuote: PropTypes.bool,
  isChat: PropTypes.bool,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  isJournalistReply: PropTypes.bool,
  feedSettings: FeedSettingsShape,
};

Author.defaultProps = {
  authorName: '',
  isChat: false,
  isJournalistReply: false,
  theme: {},
  feedSettings: FeedSettingsDefaultProps,
};

export default themer('Author')(Author);
