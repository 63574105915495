import { numberHelpers } from '@core-live/common-utils';

const { getRandomNumber: GetRandomNumber } = numberHelpers;

const ONE_SECOND_IN_MS = 1000;

export const MINIMUM_INTERVAL = 5;
export const DEFAULT_INTERVAL = 10;

export const POLLING_STRATEGIES = {
  SLOW: 25,
  SLOWER: 45,
  MINIMAL: 120,
};

export default ({ interval }, getRandomNumber = GetRandomNumber) => (pollingStrategyName) => {
  let intervalValue;

  if (typeof interval !== 'number' || interval < MINIMUM_INTERVAL) {
    intervalValue = DEFAULT_INTERVAL;
  } else {
    intervalValue = POLLING_STRATEGIES[pollingStrategyName] || interval;
  }

  return getRandomNumber(intervalValue) * ONE_SECOND_IN_MS;
};
