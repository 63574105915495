export default ({ css }) => {
  const entryContentWrapper = css`
    display: flex;
    flex-direction: column;
    font-size: 17px;
    line-height: 1.4em;
    margin-left: 15px;
    word-wrap: break-word;

    br {
      // we don't want to use it since paragraphs have their own margin
      display: none;
    }

    p:empty {
      // make sure we don't display empty paragraphs that get ninjaed in from CMS
      display: none;
      margin: 0;
      padding: 0;
    }

    p {
      line-height: 1.4em;
      margin-top: 1em;
      margin-bottom: 0;

      &:first-of-type {
        margin-top: 2px;
      }
    }

    a {
      display: inline;
      color: #1973c8;
      text-decoration: none;
      border-bottom: 1px solid #d4d4d4;
      font-weight: normal;

      strong {
        font-weight: normal;
      }

      &:hover {
        border-bottom: 1px solid #1973c8;
      }
    }

    @media (min-width: 767px) {
      font-size: 20px;
      line-height: 31.6px;
    }
  `;

  return {
    styles: {
      entryContentWrapper,
    },
  };
};
