export default ({ css }) => {
  const heading = css`
    font-family: Arial, sans-serif;
    color: #333;
    font-size: 24px;
    letter-spacing: -0.11px;
  `;

  return {
    styles: {
      heading,
    },
  };
};
