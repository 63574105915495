export default ({ css }) => {
  const wrapper = css`
    font-size: 20px;
    line-height: 1em;
    display: flex;
    justify-content: space-between;
    width: 100%;
  `;

  const dateWithAvatar = css`
    margin-left: 25px;
  `;

  return {
    styles: {
      wrapper,
      dateWithAvatar,
    },
  };
};
