import EntryTimeMain from './EntryTimeMain';
import { className, uniqueClassName } from '../functions';

export default ({ css }) => {
  const empty = uniqueClassName(css, 'empty')(css``);

  const sportsEventTime = css`
    color: #333;
    padding-right: 10px;

    &${className(empty)} {
      padding-right: 0;
    }
  `;

  return {
    styles: {
      ...EntryTimeMain,
      empty,
      sportsEventTime,
    },
  };
};
