/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import cc from 'classcat';

export default (WrappedComponent) => {
  class WithCounterHOC extends Component {
    constructor(props) {
      super(props);

      this.state = {
        charCount: 0,
      };

      this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
      event.persist();

      const { onChangeParentHandler } = this.props;

      this.setState(() => ({ charCount: event.target.value.length }));

      return onChangeParentHandler(event);
    }

    // in case where userName was already defined (SPiD displayName detection)
    componentDidMount() {
      const { value } = this.props;

      if (!value) {
        return;
      }

      this.setState(() => ({ charCount: this.props.value.length }));
    }

    render() {
      const { theme, maxLength, validated } = this.props;

      const { charCount } = this.state;

      const classNames = cc({
        [theme.defaultContainer]: true,
        [theme.validationErrorContainer]: !validated,
      });

      const counterView = <div className={theme.counter}>{charCount}/{maxLength}</div>;

      return (
        <div className={classNames}>
          <WrappedComponent {...this.props} onChange={this.onChange} />
          {counterView}
        </div>
      );
    }
  }

  return WithCounterHOC;
};
