export default ({ css }) => {
  const journalistReply = css`
    position: relative;
    word-wrap: break-word;
  `;

  const journalistReplyBlockquote = css`
    margin: 0 45px 0 16px;
    background-color: #f6d3d3;
    position: relative;
    left: -4px;
    padding: 7px 15px;
    border-radius: 16px;
    font-size: 16px;
    line-height: 20px;

    @media (min-width: 767px) {
      margin: 0 81px 0 35px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 17px solid transparent;
      border-right: 17px solid transparent;
      border-bottom: 15px solid #f6d3d3;
      bottom: 0;
      left: -16px;
    }
  `;

  return {
    styles: {
      journalistReply,
      journalistReplyBlockquote,
    },
  };
};
