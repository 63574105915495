/* eslint-disable no-console */
import _createErrorMessage from './createErrorMessage';

const LogErrorAndReturn = (
  url,
  createErrorMessage = _createErrorMessage,
) => (message) => {
  // eslint-disable-next-line no-console
  console.error(createErrorMessage(url, message));

  return {};
};

export default LogErrorAndReturn;
