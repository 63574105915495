import assembleFromPublishOrDeleteEntryMessage from './assembleFromPublishOrDeleteEntryMessage';
import assembleFromUpdateEntryMessage from './assembleFromUpdateEntryMessage';
import assembleFromLiveblogMessage from './assembleFromLiveblogMessage';

export const assembleCacheVersion = (
  message,
  assemblers = [
    assembleFromPublishOrDeleteEntryMessage,
    assembleFromUpdateEntryMessage,
    assembleFromLiveblogMessage,
  ],
) => assemblers.reduce(
  (cacheVersion, assembler) => cacheVersion || assembler(message),
  null,
);
