export default ({ css }) => {
  const mobileVersion = 'max-width: 768px';

  const entryTitle = css`
    font-family: Inter-SemiBold, Inter, sans-serif;
    font-size: 23px;
    font-weight: 600;
    letter-spacing: -.4px;
    line-height: 28px;
    margin-bottom: 15px;
    margin-top: 13px;
    margin-left: 13px;

    @media (${mobileVersion}) {
      font-size: 20px;
      line-height: 26px;
    }
  `;

  return {
    styles: {
      entryTitle,
    },
  };
};
