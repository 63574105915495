const SUPPORTED_MESSAGE_TYPES = [
  'updateEntry',
];

const assembleFromUpdateEntryMessage = (message) => {
  const { type, body: { id, updatedAt } } = message;

  const updatedAtDate = new Date(updatedAt);

  return SUPPORTED_MESSAGE_TYPES.includes(type)
    ? `${type}-${id}-${updatedAtDate.getTime()}`
    : null;
};

export default assembleFromUpdateEntryMessage;
