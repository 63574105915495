// hack to get around emotion warning about interpolating a class name
export const className = name => `.${name}`;

export const rawSelector = name => `${name}`;

export const uniqueClassName = (css, name) => cssStyles =>
  css`
    // class-name-salt: ${name}

    ${cssStyles};
  `;

export const createStyles = (styleDefinitions, props) => {
  const allStyles = { ...styleDefinitions };
  const allGlobalStyles = [];

  Object.keys(allStyles).forEach((name) => {
    const styleDefinition = allStyles[name];

    if (typeof styleDefinition === 'function') {
      const { styles, globalStyles = false } = styleDefinition(props);

      if (globalStyles) {
        allGlobalStyles.push(globalStyles);
      }

      allStyles[name] = styles;
    }
  });

  return {
    styles: allStyles,
    globalStyles: allGlobalStyles,
  };
};

export const createStylesWithBaseTheme = (
  styleDefinitions,
  baseTheme,
  props,
) => {
  const {
    styles: defaultStyles,
    globalStyles: defaultGlobalStyles,
  } = baseTheme(props);

  const propsWithDefaultStyles = { ...props, defaultStyles };

  const {
    styles: overrideStyles,
    globalStyles: overrideGlobalStyles,
  } = createStyles(styleDefinitions, propsWithDefaultStyles);

  return {
    styles: {
      ...defaultStyles,
      ...overrideStyles,
    },
    globalStyles: [
      ...defaultGlobalStyles,
      ...overrideGlobalStyles,
    ],
  };
};
