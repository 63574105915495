export default ({ css, keyframes }) => {
  const placeholderGradientPrimaryColor = '#EBEDF0';
  const placeholderGradientSecondaryColor = '#F8F9FA';

  const shineLines = keyframes`
    0% { background-position: 200%; }
    60% { background-position: -100%; }
    100% { background-position: -100%; }
  `;

  const placeholder = css`
    background-image: linear-gradient(
    135deg,
    ${placeholderGradientPrimaryColor} 0%,
    ${placeholderGradientPrimaryColor} 20%,
    ${placeholderGradientSecondaryColor} 50%,
    ${placeholderGradientPrimaryColor} 60%,
    ${placeholderGradientPrimaryColor} 100%
    );
    background-color: ${placeholderGradientPrimaryColor};
    background-size: 200%;
    background-position-x: 200%;
    background-repeat: no-repeat;
    animation: ${shineLines} .8s infinite cubic-bezier(0.55, 0.09, 0.68, 0.53);
  `;

  const placeholderStyles = css`
    ${placeholder};

    height: 20px;
  `;

  const titlePlaceholder = css`
    ${placeholderStyles};

    margin-bottom: 10px;
    width: 33%;
  `;

  const descriptionPlaceholder = css`
    ${placeholderStyles};

    width: 60%;
  `;

  const sendButtonPlaceholder = css`
    ${placeholderStyles};

    width: 10%;
  `;

  const bylinePlaceholder = css`
    ${placeholderStyles};

    width: 50%;
  `;

  const contentPlaceholder = css`
    ${placeholder};

    height: 120px;
    width: 100%;
    margin-top: 10px;
  `;

  const userMessagesFormRow2 = css`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `;

  const livefeedPlaceholderFrame = css`
    margin-top: 35px;
    display: flex;
  `;

  const livefeedColumn = css`
    width: 100%;
  `;

  return {
    styles: {
      titlePlaceholder,
      descriptionPlaceholder,
      sendButtonPlaceholder,
      bylinePlaceholder,
      contentPlaceholder,
      userMessagesFormRow2,
      livefeedPlaceholderFrame,
      livefeedColumn,
    },
  };
};
