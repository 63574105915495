import PropTypes from 'prop-types';
import { POLLING_STRATEGIES } from '../utils/constants';

export const BlogSettingsShape = PropTypes.shape({
  closed: PropTypes.bool,
  pinnedEntryId: PropTypes.number,
  pinnedEntryFeedId: PropTypes.string,
  pollingStrategy: PropTypes.oneOf(Object.keys(POLLING_STRATEGIES)),
  isUserMessagesLoginRequired: PropTypes.bool,
  isSeeMoreFeatureLoginRequired: PropTypes.bool,
  isSportsLiveblog: PropTypes.bool,
});

export const BlogSettingsDefaultProps = PropTypes.shape({
  closed: false,
  pinnedEntryId: null,
  pinnedEntryFeedId: null,
  pollingStrategy: POLLING_STRATEGIES.NORMAL,
  isUserMessagesLoginRequired: false,
  isSeeMoreFeatureLoginRequired: false,
  isSportsLiveblog: false,
});
