import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

export class EntryEditOptions extends Component {
  constructor() {
    super();
    this.handleEditEntryClick = this.handleEditEntryClick.bind(this);
    this.handleDeleteEntryClick = this.handleDeleteEntryClick.bind(this);
    this.handlePinEntryClick = this.handlePinEntryClick.bind(this);
    this.handleUnpinEntryClick = this.handleUnpinEntryClick.bind(this);
  }

  handleEditEntryClick() {
    const {
      onEditEntry,
    } = this.props;

    onEditEntry(this.props.id);
  }

  handleDeleteEntryClick() {
    const {
      onDeleteEntry,
      id,
    } = this.props;

    onDeleteEntry(id);
  }

  handlePinEntryClick() {
    const {
      onPinEntry,
    } = this.props;

    onPinEntry({
      entryId: this.props.id,
      feedId: this.props.feedId,
    });
  }

  handleUnpinEntryClick() {
    const {
      onUnpinEntry,
    } = this.props;

    onUnpinEntry(this.props.id);
  }

  render() {
    const {
      theme,
      newsValue,
      isEntryPinned,
      onEditEntry,
      onDeleteEntry,
      onPinEntry,
      onUnpinEntry,
    } = this.props;

    const newsValueView = newsValue
      ? <div className={`${theme.entryEditOptionsLegend} ${theme.entryEditOptionsNewsValue}`}>NV: {newsValue}</div>
      : null;

    const pinButtonView = isEntryPinned
      ? <button className={theme.unpinEntryButton} onClick={this.handleUnpinEntryClick} name="Unpin entry">Unpin</button>
      : <button className={theme.pinEntryButton} onClick={this.handlePinEntryClick} name="Pin entry">Pin</button>;

    const pinEntryView = onPinEntry && onUnpinEntry
      ? pinButtonView
      : null;

    const removeButtonView = !isEntryPinned && onDeleteEntry
      ? <button className={theme.deleteEntryButton} onClick={this.handleDeleteEntryClick} name="Delete entry">Delete</button>
      : null;

    const editButtonView = onEditEntry
      ? <button className={theme.editEntryButton} onClick={this.handleEditEntryClick} name="Edit entry">Edit</button>
      : null;

    return (
      <div className={theme.entryEditOptions}>
        {newsValueView}
        <div className={theme.entryEditOptionsLegend}>Visible only for editors</div>
        {pinEntryView}
        {editButtonView}
        {removeButtonView}
      </div>
    );
  }
}

EntryEditOptions.propTypes = {
  isEntryPinned: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onEditEntry: PropTypes.func,
  onDeleteEntry: PropTypes.func,
  onPinEntry: PropTypes.func,
  onUnpinEntry: PropTypes.func,
  newsValue: PropTypes.number,
  feedId: PropTypes.string,
};

EntryEditOptions.defaultProps = {
  theme: {},
  isEntryPinned: false,
  feedId: null,
};

export default themer('EntryEditOptions')(EntryEditOptions);
