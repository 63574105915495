import React from 'react';

const PinnedIcon = () => (
  <svg viewBox="0 0 15 17" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#787878" fill="none">
      <path d="M2.217 16.718l2.992-5.18M11.106 2.66l-2.71-1.565-.612 1.06.743 1.842L5.384 8.19l-3.05-.348-1.19 1.434 4.607 2.66" />
      <path d="M11.106 2.66l2.71 1.563-.613 1.06-1.967.278-2.06 4.82 1.828 2.468-.648 1.746-4.605-2.66" />
    </g>
  </svg>
);

export default PinnedIcon;
