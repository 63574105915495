import _EventHubClient from './client/eventHub';
import _ConnectionMaintainer from './connection-maintainer';
import _EventListener from './event-listener';
import _RealtimeStrategy from './strategy';

export default (
  {
    publicationId,
    liveblogId,
    isStageMode,
    onMessage = () => {},
    onInitialConnect = () => {},
    onReconnect = () => {},
    fallbackStrategy,
    subscribedEvents,
    resourceNames,
  },
  EventHubClient = _EventHubClient,
  EventListener = _EventListener,
  ConnectionMaintainer = _ConnectionMaintainer,
  RealtimeStrategy = _RealtimeStrategy,
) => {
  const client = EventHubClient();

  const eventListener = EventListener({
    client,
    publicationId,
    liveblogId,
    onMessage,
    subscribedEvents,
    resourceNames,
    isStageMode,
  });

  const connectionMaintainer = ConnectionMaintainer(client);

  connectionMaintainer.onStart(eventListener.subscribe);

  return RealtimeStrategy({
    connectionMaintainer,
    onInitialConnect,
    onReconnect,
    fallbackStrategy,
  });
};
