import PropTypes from 'prop-types';
import { ROLE_READER, userRoles } from '../utils/consts';

export const UserShape = PropTypes.shape({
  id: PropTypes.number,
  role: PropTypes.oneOf(userRoles),
  byline: PropTypes.string,
});

export const UserDefaultProps = {
  id: null,
  role: ROLE_READER,
  byline: null,
};
