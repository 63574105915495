import { colorPrimary, colorDark } from './variables';

export default ({ css, defaultStyles }) => {
  const { LiveblogLoginView } = defaultStyles;

  const container = css`
    ${LiveblogLoginView.container};

    border-color: var(--c-neutral-03, #ccc);
  `;

  const heading = css`
    ${LiveblogLoginView.heading};

    font-family: Verdana, sans-serif;
    font-weight: bold;
    color: var(--c-dark, ${colorDark});
  `;

  const description = css`
    ${LiveblogLoginView.description};

    color: var(--c-dark, ${colorDark});
  `;

  const button = css`
    ${LiveblogLoginView.button};

    font-family: Arial, sans-serif;
    text-transform: uppercase;
    background-color: var(--c-primary-01, ${colorPrimary});
    border-radius: 100px;
  `;

  return {
    styles: {
      ...LiveblogLoginView,
      container,
      heading,
      description,
      button,
    },
  };
};
