export default ({ css, defaultStyles }) => {
  const { MessageForm } = defaultStyles;

  const messageFormDescription = css`
    ${MessageForm.messageFormDescription};

    display: flex;
    justify-content: flex-end;

    span {
      display: none;
    }
  `;

  return {
    styles: {
      ...MessageForm,
      messageFormDescription,
    },
  };
};
