export default ({ css }) => {
  const userMessageWrapper = css`
    display: flex;
    flex-direction: column;
    word-break: break-word;
    margin: 6px 17px 10px 43px;

    @media (min-width: 767px) {
      margin: 6px 17px 10px 91px;
    }
  `;

  const userMessageImage = css`
    display: block;
    max-width: 100%;
    margin-top: 6px;
    border-radius: 16px;
  `;

  const userMessageAuthor = css`
    font-size: 14px;
    color: #787878;
    line-height: 18px;
    align-self: flex-end;
  `;

  const userMessageText = css`
    position: relative;
    padding: 7px 15px;
    background-color: #e7eaed;
    border-radius: 16px;
    margin-top: 5px;
    font-size: 16px;
    line-height: 20px;

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      bottom: 0;
      right: -16px;
      border-left: 17px solid transparent;
      border-right: 17px solid transparent;
      border-bottom: 15px solid #e7eaed;
    }

    &:empty {
      display: none;
    }

    &:not(:empty) ~ .userMessageAuthor {
      margin-right: -17px;
    }
  `;

  return {
    styles: {
      userMessageWrapper,
      userMessageImage,
      userMessageText,
      userMessageAuthor,
    },
  };
};
