import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import get from 'lodash.get';

import { nodeShape } from '../../utils/shapes';

import { getTimestampForArticleTeaser } from './utils';

export const ArticleTeaserContentVariants = {
  default: 'default',
  withoutDate: 'withoutDate',
};

export const ArticleTeaserContent = ({ node, theme, publication, variant }) => {
  const parentClass = get(node, 'attrs.class');
  const children = get(node, 'children');

  if (!parentClass || parentClass !== 'article-teaser' || !children || children.length === 0) {
    return null;
  }

  const [image, title, publishedDate] = children;

  if (!image || !title) {
    return null;
  }

  const imageSource = get(image, 'attrs.src');
  const articleUrl = get(title, 'attrs.href');
  const articleTitle = get(title, 'children[0].content');
  const articlePublishedDate = get(publishedDate, 'children[0].content');

  const imageView = (
    <img
      alt="teaser"
      src={imageSource}
    />
  );

  let titleViewStyles = theme.articleTeaserTitle;

  if (variant === ArticleTeaserContentVariants.withoutDate) {
    titleViewStyles += ` ${theme.articleTeaserTitleLonger}`;
  }

  const titleView = (
    <div className={titleViewStyles}>
      {articleTitle}
    </div>
  );

  const metaView = variant !== ArticleTeaserContentVariants.withoutDate ? (
    <div className={theme.articleTeaserMeta}>
      <span className={theme.articleTeaserPublishedDate}>
        {getTimestampForArticleTeaser(articlePublishedDate, publication)}
      </span>
    </div>
  ) : null;

  return (
    <a className={theme.articleTeaserWrapper} href={articleUrl}>
      <div className={theme.articleTeaserContent}>
        <div className={theme.articleTeaserMediaContent}>
          {imageView}
        </div>
        <div className={theme.articleTeaserTextContent}>
          {metaView}
          {titleView}
        </div>
      </div>
    </a>
  );
};

ArticleTeaserContent.propTypes = {
  node: PropTypes.shape(nodeShape).isRequired,
  theme: PropTypes.object.isRequired,
  publication: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

ArticleTeaserContent.defaultProps = {
  variant: ArticleTeaserContentVariants.default,
};

export default themer('ArticleTeaserContent')(ArticleTeaserContent);
