import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

export const SkeletonView = ({ theme }) => (
  <div className={theme.skeletonView} data-testid="liveblog-skeleton-view">
    <div className={theme.userMessagesFormRow1}>
      <div className={theme.titlePlaceholder} />
    </div>
    <div className={theme.userMessagesFormRow2}>
      <div className={theme.descriptionPlaceholder} />
      <div className={theme.sendButtonPlaceholder} />
    </div>
    <div className={theme.livefeedPlaceholderFrame}>
      <div className={theme.livefeedColumn}>
        <div className={theme.bylinePlaceholder} />
        <div className={theme.contentPlaceholder} />
      </div>
    </div>
  </div>
);

SkeletonView.propTypes = {
  theme: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

SkeletonView.defaultProps = {
  theme: {},
};

export default themer('SkeletonView')(SkeletonView);
