export default ({ css }) => {
  const widthMobile = 266;
  const widthDesktop = 580;
  const breakpointDesktop = 768;
  const colorRed = '#f6231e';

  const newEntriesIndicator = css`
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer !important;

    border-radius: 8px;
    box-shadow: 0 12px 16px 2px rgba(0, 0, 0, 0.05), 0 8px 32px 4px rgba(0, 0, 0, 0.05), 0 6px 12px -4px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    color: #212529;

    position: fixed;
    box-sizing: border-box;
    top: 120px;
    z-index: 15;
    padding: 12px 58px 12px 37px;
    height: 48px;
    width: ${widthMobile}px;
    left: calc(50% - ${widthMobile / 2}px);

    &:focus {
      outline: none;
    }

    @media (min-width: ${breakpointDesktop}px) {
      width: ${widthDesktop}px;
      left: calc(50% - ${widthDesktop / 2}px);
      top: 150px;
    }
  `;

  const labelPrefix = css`
    color: ${colorRed};
    position: relative;

    &::before {
      content: '';
      border: 4px solid ${colorRed};
      border-radius: 50%;
      width: 14px;
      height: 14px;
      display: inline-block;
      box-sizing: border-box;
      position: absolute;
      top: 2px;
      left: -20px;
    }
  `;

  const closeIcon = css`
    position: absolute;
    top: 0;
    right: 0;
    padding: 14px 16px 14px 0;
  `;

  return {
    styles: {
      newEntriesIndicator,
      labelPrefix,
      closeIcon,
    },
  };
};
