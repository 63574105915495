import isValid from 'date-fns/isValid';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import isSameYear from 'date-fns/isSameYear';
import format from 'date-fns/format';
import norwegianLocale from 'date-fns/locale/nb';
import swedishLocale from 'date-fns/locale/sv';
import differenceInMinutes from 'date-fns/differenceInMinutes';

import { getTexts } from '../../config';

const swedishPublications = ['ab', 'svd'];

const hourInMinutes = 60;

export const getTimestampForArticleTeaser = (inputDate, publication = 'bt') => {
  try {
    const date = new Date(format(new Date(inputDate), 'yyyy-MM-dd HH:mm:ss'));

    if (!isValid(date)) {
      return null;
    }

    const currentDate = new Date();

    const minutesDifference = Math.abs(differenceInMinutes(currentDate, date));

    const isSwedish = swedishPublications.includes(publication);
    const locale = isSwedish
      ? swedishLocale
      : norwegianLocale;

    const texts = getTexts(publication, 'datesAndTime');

    if (minutesDifference < 5) {
      return texts.aroundNow;
    }

    if (minutesDifference >= 5 && minutesDifference < 50) {
      const minutes = Math.ceil(minutesDifference / 10) * 10;

      return texts.lessThanMinutesAgo
        .replace('{minutes}', minutes);
    }

    if (minutesDifference >= 50 && minutesDifference <= 3 * hourInMinutes) {
      const hours = Math.ceil(minutesDifference / hourInMinutes);

      return texts.lessThanHoursAgo
        .replace('{hours}', hours);
    }

    if (isToday(date) && minutesDifference > 3 * hourInMinutes) {
      const time = format(date, 'HH:mm');

      return texts.todayAtTime
        .replace('{time}', time);
    }

    if (isYesterday(date)) {
      const time = format(date, 'HH:mm');

      return texts.yesterdayAtTime
        .replace('{time}', time);
    }

    if (isSameYear(currentDate, date)) {
      const dateFormat = isSwedish ? 'd MMMM' : 'd. MMMM';

      return format(date, dateFormat, { locale });
    }

    const dateFormat = isSwedish ? 'd MMMM yyyy' : 'd. MMMM yyyy';

    return format(date, dateFormat, { locale });
  } catch (error) {
    return null;
  }
};
