import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ marginLeft }) => (
  <svg width="16px" height="16px" viewBox="0 0 21 20" style={{ marginLeft }}>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="icons/delete"
        transform="translate(1.000000, 1.000000)"
        stroke="currentcolor"
        strokeWidth="3"
      >
        <g id="Group">
          <path
            d="M17.3224358,1.09697513 L1.08133653,17.3380744 M17.7715771,17.4689724 L0.950438523,0.647833875"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

CloseIcon.propTypes = {
  marginLeft: PropTypes.string,
};

CloseIcon.defaultProps = {
  marginLeft: '10px',
};

export default CloseIcon;
