export const handleUpdateLiveblogMessage = (
  messageBody,
  toBoolean = value => !!value,
) => {
  const {
    eventId,
    meta,
  } = messageBody;

  const {
    closed,
    pinnedEntryId,
    pinnedEntryFeedId,
    pollingStrategy,
    isUserMessagesLoginRequired,
    isSeeMoreFeatureLoginRequired,
  } = meta || {};

  return {
    blogSettings: {
      closed,
      pinnedEntryId,
      pinnedEntryFeedId,
      pollingStrategy,
      isUserMessagesLoginRequired,
      isSeeMoreFeatureLoginRequired,
      isSportsLiveblog: toBoolean(eventId),
    },
  };
};
