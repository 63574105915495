import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import cc from 'classcat';

import { parseDate } from '../../utils/datesAndTime';

import EntryTimeMain from '../EntryTimeMain/EntryTimeMain';
import EntryTimeDetails from '../EntryTimeDetails/EntryTimeDetails';

import { regularMetaShape } from '../../utils/shapes';
import { BlogSettingsDefaultProps, BlogSettingsShape } from '../../shapes';

const ONE_MINUTE = 1000 * 60;

class EntryTime extends Component {
  constructor(props) {
    super(props);
    this.tick = this.tick.bind(this);
    this.intervalId = null;
    this.state = {
      parsedDate: {},
    };
  }

  componentDidMount() {
    this.tick();
    this.intervalId = setInterval(this.tick, ONE_MINUTE);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  tick() {
    const { date, publication, blogSettings } = this.props;
    this.setState({
      parsedDate: parseDate(date, blogSettings.isSportsLiveblog, publication),
    });
  }

  render() {
    const { date, addedWithinMatch, matchMeta, theme, hasAuthorWithAvatar } = this.props;

    const dateClass = cc({
      [theme.dateWithAvatar]: hasAuthorWithAvatar,
    });

    if (addedWithinMatch) {
      if (!matchMeta || !matchMeta.minutes) {
        return false;
      }

      const addedMinutes = matchMeta.minutesPlus
        ? ` + ${matchMeta.minutesPlus}`
        : '';

      return (
        <span title={date}>
          <EntryTimeMain time={{ main: `${matchMeta.minutes}${addedMinutes}′` }} />
        </span>
      );
    }

    const { parsedDate } = this.state;
    if (!date) return false;
    return (
      <span className={dateClass} title={date}>
        <EntryTimeMain time={parsedDate} />
        <EntryTimeDetails time={parsedDate} />
      </span>
    );
  }
}

EntryTime.propTypes = {
  date: PropTypes.string,
  addedWithinMatch: PropTypes.bool,
  matchMeta: PropTypes.shape(regularMetaShape),
  publication: PropTypes.string.isRequired,
  blogSettings: BlogSettingsShape,
  theme: PropTypes.object, // es-lint-disable-line
  hasAuthorWithAvatar: PropTypes.bool,
};

EntryTime.defaultProps = {
  blogSettings: BlogSettingsDefaultProps,
  hasAuthorWithAvatar: false,
};

export default themer('EntryTime')(EntryTime);
