import { sportbladetArticleClass } from './variables';
import { className, uniqueClassName } from '../functions';

// we must not use `css` function here to make it work properly
// with Global component in hyperion
const sportbladet = customStyles => `
  ${className(sportbladetArticleClass)} {
    ${customStyles}
  }
`;

export {
  className,
  sportbladet,
  uniqueClassName,
};
