import {
  colorPrimary,
  colorSportbladet,
} from './variables';

import {
  className,
  uniqueClassName,
  sportbladet,
} from './functions';

export default ({ css, defaultStyles }) => {
  const { Entry } = defaultStyles;

  const entry = uniqueClassName(css, 'entry')(css`
    ${Entry.entry};
    color: var(--c-dark, #333);
    padding-bottom: 12px;

    &::before {
      border-left: 1px solid var(--c-primary-01, ${colorPrimary});
    }
  `);

  const entryMeta = css`
    ${Entry.entryMeta};
    margin-bottom: 10px;
  `;

  const styles = {
    ...Entry,
    entry,
    entryMeta,
  };

  const globalStyles = sportbladet(`
    ${className(entry)}::before {
      border-left: 1px solid var(--c-primary-01, ${colorSportbladet});
    }
  `);

  return {
    styles,
    globalStyles,
  };
};
