export default ({ css, defaultStyles }) => {
  const { EntryTitle } = defaultStyles;
  const mobileVersion = 'max-width: 768px';

  const entryTitle = css`
    ${EntryTitle.entryTitle};
    font-family: var(--ff-title, verdana);
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -1px;

    @media (${mobileVersion}) {
      font-size: 25px;
      line-height: 28px;
    }
`;

  return {
    styles: {
      ...EntryTitle,
      entryTitle,
    },
  };
};
