import { normalizeDecoratedEntry as _normalizeDecoratedEntry } from '../../utils/normalizeDecoratedEntry';

export const createHandleUpdateEntryMessage = (
  normalizeDecoratedEntry = _normalizeDecoratedEntry,
) => (
  decoratedUpdatedEntry,
  currentNormalizedEntries,
  currentEntriesCount,
  currentEntriesAuthors,
) => {
  const { id } = decoratedUpdatedEntry;

  const index = currentNormalizedEntries.findIndex(entry => entry.id === id);

  const entriesAuthors = [...currentEntriesAuthors];

  // due to wrong order of messages, it may occur that updated entry
  // had been deleted from the local state or hadn't been added to the local state
  // before update message arrived
  // entry to update can also be not present in the state as that entry can be old one
  if (index === -1) {
    return {
      updatedEntries: currentNormalizedEntries,
      entriesCount: currentEntriesCount,
      entriesAuthors,
    };
  }

  const precedingEntry = currentNormalizedEntries[index + 1];

  const normalizedUpdatedEntry = normalizeDecoratedEntry(
    decoratedUpdatedEntry,
    precedingEntry,
  );

  const normalizedUpdatedEntries = [...currentNormalizedEntries];

  normalizedUpdatedEntries[index] = normalizedUpdatedEntry;

  const { authorName } = normalizedUpdatedEntry;

  // when an author has only one entry in liveblog and he/she edits his/her name in that entry
  // then the obsolete name should be removed from the authors list (as the backend does)
  // but on the frontend there's no way to deduce if the edited entry is the single one
  if (!entriesAuthors.includes(authorName)) {
    entriesAuthors.push(authorName);
  }

  return {
    updatedEntries: normalizedUpdatedEntries,
    entriesCount: currentEntriesCount,
    entriesAuthors,
  };
};
