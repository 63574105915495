export default ({ css }) => {
  const sportsEntryHeadline = css`
    position: relative;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
    left: 15px;
  `;

  const sportsEntryIcon = css`
    position: absolute;
    left: -37px;
    top: -10px;
  `;

  const sportsHeadlineText = css`
    margin-bottom: 10px;
  `;

  return {
    styles: {
      sportsEntryHeadline,
      sportsEntryIcon,
      sportsHeadlineText,
    },
  };
};
