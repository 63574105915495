import {
  className,
  uniqueClassName,
  sportbladet,
} from './functions';

export default ({ css, defaultStyles }) => {
  const { LiveblogSummary } = defaultStyles;

  const liveSummary = css`
    ${LiveblogSummary.liveSummary};

    background-color: var(--c-neutral-02, rgba(255, 255, 255, 0.33));
    border: 1px solid var(--c-neutral-03, #ccc);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
    color: var(--c-dark, #222);
    font-family: Arial, Helvetica, Verdana, Geneva, sans-serif;
    font-size: 16px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  `;

  const liveSummaryContent = uniqueClassName(css, 'liveSummaryContent')(css`
    ${LiveblogSummary.liveSummaryContent};

    box-sizing: border-box;
    padding: 20px;

    p {
      margin-bottom: 12px;
    }

    a {
      color: #0078bd;
      border-bottom: none;
      padding-bottom: 2px;
      text-decoration: none;
      font-weight: bold;
    }

    ol, ul {
      margin: 16px;
    }

    ul {
      padding-left: 19px;

      li {
        list-style: none;
        margin-bottom: 4px;
        line-height: 1.4em;

        &::before {
          content: "\\25A0";
          color: #dd2a30;
          font-size: 24px;
          margin-left: -20px;
          margin-right: 0.25em;
          margin-top: -1px;
          position: relative;
        }
      }
    }

    ol {
      padding-left: 19px;

      li {
        line-height: 1.4em;
        list-style: unset;
        margin-bottom: 4px;

        &::before {
          content: "";
        }
      }
    }

    ol {
      list-style: none;
      counter-reset: list-counter;
    }

    ol li {
      counter-increment: list-counter;
    }

    ol li::before {
      content: counter(list-counter);
      background-color: #dd2a30;
      border-radius: 2px;
      color: #FEFEFE;
      display: inline-block;
      font-size: 16px;
      height: 21px;
      margin-left: -20px;
      margin-right: 8px;
      margin-top: -1px;
      position: relative;
      text-align: center;
      top: 1px;
      width: 21px;
    }

    blockquote {
      display: block;
      margin: 12px;
      padding-top: 34px;
      position: relative;

      p {
        background-color: #f7f7fa;
        display: inline-block;
        font-size: 22px;
        line-height: 1.2;
        margin-bottom: 2px;
        padding: 4px;
      }

      &::before {
        content: "\\201D";
        background-color: #f7f7fa;
        display: inline-block;
        font-size: 70px;
        font-weight: bold;
        height: 32px;
        line-height: 0.95;
        overflow: hidden;
        position: absolute;
        top: 0;
        vertical-align: top;
      }
    }

    table {
      width: 100%;

      td, th {
        padding: 8px;
      }
    }

    hr {
      margin-bottom: 12px;
    }
  `);

  const styles = {
    liveSummary,
    liveSummaryContent,
  };

  const globalStyles = sportbladet(`
    ${className(liveSummaryContent)} {
      a {
        color: #006ba8;
      }

      ul li {
        &::before {
          color: #a11014;
        }
      }

      ol li::before {
        background-color: #a11014;
      }

      blockquote {
        p {
          background-color: #fdd5db;
        }

        &::before {
          background-color: #fdd5db;
        }
      }
    }
  `);

  return {
    styles,
    globalStyles,
  };
};
