const MAX_RECONNECTION_COUNT = 5;

const RECONNECTION_INTERVAL = 2000;

const RealtimeStrategy = ({
  connectionMaintainer,
  fallbackStrategy,
  onInitialConnect,
  onReconnect,
  reconnectionInterval = RECONNECTION_INTERVAL,
  maxReconnectionCount = MAX_RECONNECTION_COUNT,
}) => {
  let running = false;
  let reconnectionCounter = 0;

  const onReconnectSuccess = () => {
    reconnectionCounter = 0;

    // eslint-disable-next-line no-unused-expressions
    fallbackStrategy && fallbackStrategy.end();

    onReconnect();
  };

  const onReconnectRequest = () => {
    reconnectionCounter++;

    if (reconnectionCounter === maxReconnectionCount) {
      // eslint-disable-next-line no-unused-expressions
      fallbackStrategy && fallbackStrategy.run();
    }
  };

  const run = () => {
    if (!running) {
      running = true;

      connectionMaintainer.onInitialConnect(onInitialConnect);
      connectionMaintainer.onReconnectRequest(onReconnectRequest);
      connectionMaintainer.onReconnect(onReconnectSuccess);

      connectionMaintainer.start(reconnectionInterval);
    }
  };

  const end = () => {
    if (running) {
      running = false;

      // eslint-disable-next-line no-unused-expressions
      fallbackStrategy && fallbackStrategy.end();

      connectionMaintainer.stop();
    }
  };

  const isRunning = () => running;

  return {
    run,
    end,
    isRunning,
  };
};

export default RealtimeStrategy;
