const getFeedIdFromLiveblog = (liveblog, placement) => {
  const { id, feeds } = liveblog || {};

  if (!feeds || !Array.isArray(feeds)) {
    throw new Error('[Liveblog]: Feeds not found for liveblog');
  }

  const feedForPlacement = feeds.find(
    (feed = {}) => feed.placement === placement,
  );

  if (!feedForPlacement) {
    throw new Error(`[Liveblog]: Feed for placement ${placement} not found for liveblog ${id}`);
  }

  return feedForPlacement.id;
};

export default getFeedIdFromLiveblog;
