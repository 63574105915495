export default ({ css }) => {
  const sportsEntryContent = css`
    color: #787878;
    font-size: 14px;
    line-height: 18px;
    padding: 0 15px;
    position: relative;

    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  `;

  const sportsEntryText = css`
    color: #333;
    font-size: 17px;
    line-height: 1.4em;
    font-weight: 400;

    @media (min-width: 768px) {
      font-size: 20px;
    }
  `;

  const separated = css`
    margin-top: 10px;
  `;

  return {
    styles: {
      sportsEntryContent,
      sportsEntryText,
      separated,
    },
  };
};
