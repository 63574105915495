export default ({ css }) => {
  const uploadedFilesWrapper = css`
    color: #848484;
    font-family: Arial, sans-serif;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 18px;
  `;

  const removeButton = css`
    position: relative;
    top: 4px;
    left: 7px;
  `;

  return {
    styles: {
      uploadedFilesWrapper,
      removeButton,
    },
  };
};
