export default ({ css }) => {
  const timeDetails = css`
    color: #707070;
    font-size: 14px;
    font-weight: 200;
    padding-left: 10px;
  `;

  return {
    styles: {
      timeDetails,
    },
  };
};
