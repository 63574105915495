import { className, uniqueClassName } from '../functions';
import { sportbladet } from './functions';
import { colorPrimary, colorSportbladet } from './variables';
import Entry from './Entry';

export default (props) => {
  const { css, defaultStyles } = props;
  const { SportsEntry } = defaultStyles;

  const sportsEntry = uniqueClassName(css, 'sportsEntry-ab')(css`
    ${SportsEntry.sportsEntry};

    padding-bottom: 12px;

    &::before {
      border-left: 1px solid var(--c-primary-01, ${colorPrimary});
    }

    &${className(SportsEntry.goal)},
    &${className(SportsEntry.periodStart)} {
      ${SportsEntry.sportsEntryWithColor('var(--c-neutral-02, #333)')};

      padding: 12px 0;
      margin-bottom: 12px;
    }
  `);

  const abEntryTheme = Entry(props);

  const styles = {
    ...SportsEntry,
    ...abEntryTheme.styles,
    sportsEntry,
  };

  const globalStyles = sportbladet(`
    ${className(SportsEntry.entry)}::before {
      border-left: 1px solid var(--c-primary-01, ${colorSportbladet});
    }

    ${className(sportsEntry)} {
      &${className(SportsEntry.goal)},
      &${className(SportsEntry.periodStart)} {
        &::before {
          border: none;
        }
      }
    }
  `);

  return {
    styles,
    globalStyles,
  };
};
