const ConnectionMaintainer = (client) => {
  let initialCall = true;
  let connectionEstablished = false;

  let onStartCallback = () => {};
  let onInitialConnectCallback = () => {};
  let onReconnectRequestCallback = () => {};
  let onReconnectCallback = () => {};

  const onStart = (callback) => { onStartCallback = callback; };
  const onInitialConnect = (callback) => { onInitialConnectCallback = callback; };
  const onReconnectRequest = (callback) => { onReconnectRequestCallback = callback; };
  const onReconnect = (callback) => { onReconnectCallback = callback; };

  const stop = () => {
    client.endConnection();
  };

  const start = (reconnectionInterval) => {
    client.initializeConnection(reconnectionInterval);
    onStartCallback();

    client.onConnect(() => {
      if (!connectionEstablished) {
        // eslint-disable-next-line no-unused-expressions
        initialCall ? onInitialConnectCallback() : onReconnectCallback();
      }

      connectionEstablished = true;
      initialCall = false;
    });

    client.onReconnect(() => onReconnectRequestCallback());

    client.onOffline(() => { connectionEstablished = false; });
  };

  return {
    onStart,
    onInitialConnect,
    onReconnectRequest,
    onReconnect,
    start,
    stop,
  };
};

export default ConnectionMaintainer;
