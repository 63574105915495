import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import cc from 'classcat';
import get from 'lodash.get';

import EntryContent from '../EntryContent/EntryContent';
import PinnedIcon from '../Icons/PinnedIcon';

import { isUserJournalistChat, isUserQuote } from '../../utils/entryContentEvaluator';
import { formatDateForPinned } from '../../utils/datesAndTime';
import { getTexts } from '../../config';

import CopyToClipboardButton from '../CopyToClipboard/CopyToClipboardButton';
import EntryEditOptions from '../EntryEditOptions/EntryEditOptions';
import { EntryActionsShape } from '../../shapes/EntryActions';
import EntryTitle from '../EntryTitle/EntryTitle';
import { isAuthorAJournalist } from '../../utils/authorHelpers';
import { FeedSettingsDefaultProps, FeedSettingsShape } from '../../shapes';

export class PinnedEntry extends Component {
  constructor(props) {
    super(props);

    this.setNodeRef = (node) => {
      this.node = node;
    };
  }

  componentDidMount() {
    const calculateHeightDelayMs = 3000;

    setTimeout(
      this.setPinnedEntryHeightInParentState.bind(this),
      calculateHeightDelayMs,
    );
  }

  setPinnedEntryHeightInParentState() {
    const { setPinnedEntryHeightFunction } = this.props;
    const pinnedEntryHeight = this.node ? this.node.clientHeight : 0;

    setPinnedEntryHeightFunction(pinnedEntryHeight);
  }

  checkIfTitleExist(content) {
    if (!content) {
      return false;
    }
    const titlePosition = content.indexOf('strong');

    return (titlePosition > -1) && (titlePosition < 10);
  }

  parseContentForTitle() {
    const { content } = this.props.entry;
    const regex = /^<p><strong>(.*)<\/strong>(.*?)<\/p>/i;

    const titleTag = content.match(regex);
    const contentWithoutTitle = titleTag ? content.replace(titleTag[0], '') : null;

    return { title: titleTag ? { __html: titleTag[1] } : '', contentWithoutTitle };
  }

  checkIfFirstItemIsTitle(structuredContent) {
    const firstItem = get(structuredContent, 'components[0]');

    return firstItem
      && firstItem.type === 'text'
      && firstItem.markup
      && firstItem.markup[0]
      && firstItem.markup[0].type === 'style:strong'
      && firstItem.markup[0].offset === 0
      && firstItem.markup[0].length === firstItem.value.length;
  }

  getTitleFromFirstItem(structuredContent) {
    return get(structuredContent, 'components[0].value');
  }

  getStructuredContentWithoutFirstItem(structuredContent) {
    return {
      ...structuredContent,
      components: structuredContent.components.slice(1),
    };
  }

  render() {
    if (!this.props.entry) {
      return null;
    }

    const {
      entry: {
        id,
        content,
        date,
        authorName,
        authorImage,
        authorType,
        isEditable,
        structuredContent,
        title,
      },
      pinnedUrl,
      theme,
      publication,
      entryActions,
      feedId,
      feedSettings,
    } = this.props;

    const texts = getTexts(publication, 'PinnedEntry');

    let parsedContent;
    if (this.checkIfTitleExist(content)) {
      parsedContent = this.parseContentForTitle();
    }

    let pinnedEntryTitle;
    let parsedStructuredContent;

    if (!title && this.checkIfFirstItemIsTitle(structuredContent)) {
      pinnedEntryTitle = this.getTitleFromFirstItem(structuredContent);
      parsedStructuredContent = this.getStructuredContentWithoutFirstItem(structuredContent);
    } else {
      pinnedEntryTitle = title;
      parsedStructuredContent = structuredContent;
    }

    const isChat = isUserJournalistChat(content);
    const isQuoteOnly = isUserQuote(content);

    const {
      pinnedEntryContent,
      pinnedEntryChatContent,
      pinnedEntryCopyToClipboardWrapper,
    } = theme;

    const pinnedEntryContentClass = cc({
      [pinnedEntryContent]: isChat === false,
      [pinnedEntryChatContent]: isChat === true,
    });

    const copyToClipboardWrapperClass = cc({
      [pinnedEntryCopyToClipboardWrapper]: !!authorImage,
    });

    const editOptionsView = isEditable
      ? (
        <EntryEditOptions
          id={id}
          isEntryPinned
          feedId={feedId}
          {...entryActions}
        />
      )
      : null;

    const pinnedEntryTitleView = pinnedEntryTitle
      ? (
        <div className={theme.pinnedEntryTitle}>
          <EntryTitle title={pinnedEntryTitle} />
        </div>
      )
      : null;

    const imgStyle = {
      backgroundImage: `url(${authorImage})`,
    };

    const avatarStyle = cc({
      [theme.styledAvatar]: true,
      [theme.styledPublicationLogo]: isAuthorAJournalist(authorType),
    });

    const avatarView = authorImage
      ? (<div className={avatarStyle} style={imgStyle} />)
      : null;

    return (
      <div
        ref={this.setNodeRef}
        className={theme.pinnedEntry}
        data-testid="liveblog-pinned-entry"
      >
        <div className={theme.pinnedEntryHeader}>
          <div>{texts.pinnedEntryLabel}</div>
          <div className={theme.pinnedEntryIcon}>
            <PinnedIcon />
          </div>
          { editOptionsView }
        </div>
        {pinnedEntryTitleView}
        <div className={theme.pinnedEntryDetails}>
          <div className={theme.metadataWrapper}>
            <span className={theme.datePublished}>
              { formatDateForPinned(date, publication) }
            </span>
            <span className={theme.author}>
              { authorName }
            </span>
            {avatarView}
          </div>
          <div className={copyToClipboardWrapperClass}>
            <CopyToClipboardButton pinnedUrl={pinnedUrl} id={id} publication={publication} />
          </div>
        </div>
        <div className={pinnedEntryContentClass}>
          <EntryContent
            isChat={isChat || isQuoteOnly}
            authorName={authorName}
            authorImage={authorImage}
            content={(parsedContent && parsedContent.title) ? parsedContent.contentWithoutTitle : content}
            publication={publication}
            structuredContent={parsedStructuredContent}
            feedSettings={feedSettings}
          />
        </div>
        <div className={theme.itemSeparator} />
      </div>
    );
  }
}

PinnedEntry.propTypes = {
  entry: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
    content: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    isEditable: PropTypes.bool.isRequired,
    authorName: PropTypes.string,
    authorImage: PropTypes.string,
    authorType: PropTypes.string,
    structuredContent: PropTypes.shape({
      components: PropTypes.array,
    }),
    title: PropTypes.string,
  }),
  pinnedUrl: PropTypes.string,
  setPinnedEntryHeightFunction: PropTypes.func,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  publication: PropTypes.string.isRequired,
  entryActions: EntryActionsShape,
  feedId: PropTypes.string,
  feedSettings: FeedSettingsShape,
};

PinnedEntry.defaultProps = {
  theme: {},
  feedId: null,
  feedSettings: FeedSettingsDefaultProps,
};

export default themer('PinnedEntry')(PinnedEntry);
