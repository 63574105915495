export const i18n = {
  'nb-no': {
    CopyToClipboard: {
      copyButton: 'DEL',
      copiedText: 'Lenken kopiert.',
    },
    datesAndTime: {
      atTime: 'kl',
      minutes: 'min',
      now: 'Nå',
      todayAt: 'i dag kl',
      yesterday: 'i går',
      today: 'i dag',
      aroundNow: 'Akkurat nå',
      lessThanMinutesAgo: 'Mindre enn {minutes} min siden',
      lessThanHoursAgo: 'Mindre enn {hours} time siden',
      todayAtTime: 'i dag {time}',
      yesterdayAtTime: 'i går {time}',
    },
    FileUpload: {
      dropArea: 'Legg til foto',
    },
    Liveblog: {
      noPosts: 'Det er ingen innlegg ennå.',
    },
    MessageForm: {
      abortButtonContent: 'Avbryt',
      statusLabelSuccessContent: ['Takk for bidraget!', 'Vi leser alle meldinger og publiserer fortløpende.'],
      statusLabelFailureContent: ['Oops!', 'Noe gikk galt. Prøv en gang til :-)'],
      messagePlaceholder: 'Skriv din melding',
      messageErrorMessage: 'Du må skrive en melding eller legge ved et bilde',
      namePlaceholder: 'Ditt navn',
      nameErrorMessage: 'Du må skrive inn et navn',
      sendButtonContent: 'Send melding',
      closedPostMessageDescription: 'Denne direktechatten er nå avsluttet. Takk for bidragene!',
    },
    LoadMoreEntries: {
      loadMoreText: 'Vis flere innlegg',
      loginToLoadMoreText: 'Logg inn for å vise flere innlegg',
      isLoadingText: 'Laster...',
    },
    LiveblogLoginView: {
      defaultClosedDescription: 'Denne direktechatten er nå avsluttet. Takk for bidragene!',
      defaultButtonContent: 'Send melding',
      defaultLoginButtonContent: 'Send melding',
    },
    ShareBtn: {
      linkButtonText: 'Lenke',
      linkButtonTextCopied: 'Kopiert til utklippstavlen',
    },
    EntryImageInfo: {
      fotoText: 'Foto:',
    },
    PinnedEntry: {
      pinnedEntryLabel: 'Festet innlegg',
    },
    SportsLive: {
      GeneralContent: {
        playerAndTeam: '{player} - {team}',
        teamOnly: '{team}',
        playerOnly: '{player}',
      },
      GoalContent: {
        currentScores: '{team1} {score1} - {score2} {team2}',
        scorer: 'Mål: {scorer}',
        assistant: 'Målgivende: {assistant}',
      },
      headlineFactory: {
        eventStart: 'Kampen har startet',
        goal: 'Mål for {team}!',
        offside: 'Offside for {team}',
        offsidePlain: 'Offside',
        substitution: 'Spillerbytte - {team}',
        substitutionPlain: 'Spillerbytte',
        yellowCard: 'Gult kort!',
        videoAssistantReferee: 'VAR-situasjon',
        yellowRedCard: 'Andre gule kort!',
        redCard: 'Rødt kort!',
        eventEnd: 'Kampen er slutt',
        pauseStart: 'Pause',
        period1: '1. omgang',
        period2: '2. omgang',
        overtimePeriod1: '1. ekstraomgang',
        overtimePeriod2: '2. ekstraomgang',
        penaltyShootout: 'Straffesparkkonkurranse',
        penaltyAwarded: 'Straffe til {team}',
        penaltyAwardedPlain: 'Straffe',
        penaltyMissed: '{team} bommer på straffe!',
        penaltyMissedPlain: 'Bommer på straffe!',
        goalPenalty: '{team} scorer på straffe!',
        goalOwn: '{team} scorer selvmål!',
        injury: 'Skade',
        injuryReturn: 'Tilbake fra skade',
        corner: 'Hjørnespark til {team}',
        cornerPlain: 'Hjørnespark',
        freeKick: 'Frispark, {team}',
        freeKickPlain: 'Frispark',
        shotOn: 'Skudd på mål, {team}',
        shotOnPlain: 'Skudd på mål',
        shotSaved: 'Redning, {team}',
        shotSavedPlain: 'Redning',
      },
    },
    NewEntriesIndicator: {
      newEntriesPrefix: 'Nå',
      newEntriesLabelSingular: '{count} ny oppdatering',
      newEntriesLabelPlural: '{count} nye oppdateringer',
    },
    Author: {
      byAuthor: 'av {authorName}',
    },
  },
  'sv-se': {
    CopyToClipboard: {
      copyButton: 'DELA',
      copiedText: 'Länken har kopierats.',
    },
    datesAndTime: {
      atTime: 'kl',
      minutes: 'min',
      now: 'Nu',
      todayAt: 'i dag kl',
      today: 'i dag',
      yesterday: 'i går',
      aroundNow: 'Alldeles nyss',
      lessThanMinutesAgo: 'Mindre än {minutes} min sedan',
      lessThanHoursAgo: 'mindre än {hours} tim sedan',
      todayAtTime: 'i dag 🕒 {time}',
      yesterdayAtTime: 'i går 🕒 {time}',
    },
    FileUpload: {
      dropArea: 'Lägg till foto',
    },
    Liveblog: {
      noPosts: 'Det finns inga inlägg ännu.',
    },
    MessageForm: {
      abortButtonContent: 'Avbryt',
      statusLabelSuccessContent: ['Tack för bidraget!', 'Vi läser alla inlägg och publicerar efter hand.'],
      statusLabelFailureContent: ['Oops!', 'Något gick gick fel. Pröva igen :-)'],
      messagePlaceholder: 'Skriv ditt inlägg',
      messageErrorMessage: 'Du behöver skriva ett meddelande eller ladda upp en bild',
      namePlaceholder: 'Ditt namn',
      nameErrorMessage: 'Du måste fylla i ditt namn',
      sendButtonContent: 'Skicka inlägg',
      closedPostMessageDescription: 'Denna chatt är stängd.',
    },
    LoadMoreEntries: {
      loadMoreText: 'Visa fler inlägg',
      loginToLoadMoreText: 'Logga in för att läsa mer',
      isLoadingText: 'Laddar...',
    },
    LiveblogLoginView: {
      ab: {
        defaultClosedDescription: '',
        defaultButtonContent: 'Skriv inlägg',
        defaultLoginButtonContent: 'Logga in för att skriva',
      },
      defaultClosedDescription: 'Denna chatt är stängd.',
      defaultButtonContent: 'Skriv inlägg',
      defaultLoginButtonContent: 'Logga in för att skriva',
    },
    ShareBtn: {
      linkButtonText: 'Länk',
      linkButtonTextCopied: 'Kopierad till urklipp',
    },
    EntryImageInfo: {
      fotoText: 'Foto:',
    },
    PinnedEntry: {
      pinnedEntryLabel: 'Fastnålat inlägg',
    },
    SportsLive: {
      GeneralContent: {
        playerAndTeam: '{player} - {team}',
        teamOnly: '{team}',
        playerOnly: '{player}',
      },
      GoalContent: {
        currentScores: '{team1} {score1} - {score2} {team2}',
        scorer: 'Mål: {scorer}',
        assistant: 'Assist: {assistant}',
      },
      headlineFactory: {
        eventStart: 'Matchen har startat',
        goal: 'Mål för {team}!',
        offside: 'Offside för {team}',
        offsidePlain: 'Offside',
        substitution: 'Spelarbyte - {team}',
        substitutionPlain: 'Spelarbyte',
        yellowCard: 'Gult kort!',
        videoAssistantReferee: 'VAR-situation',
        yellowRedCard: 'Andra gula kortet!',
        redCard: 'Rött kort!',
        eventEnd: 'Matchen är slut',
        pauseStart: 'Paus',
        period1: 'Första halvlek',
        period2: 'Andra halvlek',
        overtimePeriod1: 'Första förlängningskvarten',
        overtimePeriod2: 'Andra förlängningskvarten',
        penaltyShootout: 'Straffsparksavgörande',
        penaltyAwarded: 'Straff för {team}',
        penaltyAwardedPlain: 'Straff',
        penaltyMissed: '{team} missar straff!',
        penaltyMissedPlain: 'Missar straff!',
        goalPenalty: '{team} mäl på straff!',
        goalOwn: '{team} gör självmål!',
        injury: 'Skada',
        injuryReturn: 'Tillbaka från skada',
        corner: 'Hörna för {team}',
        cornerPlain: 'Hörna',
        freeKick: 'Frispark, {team}',
        freeKickPlain: 'Frispark',
        shotOn: 'Skott på mål, {team}',
        shotOnPlain: 'Skott på mål',
        shotSaved: 'Räddning, {team}',
        shotSavedPlain: 'Räddning',
      },
    },
    NewEntriesIndicator: {
      newEntriesPrefix: 'Nu',
      newEntriesLabelSingular: '{count} ny uppdatering',
      newEntriesLabelPlural: '{count} nya uppdateringar',
    },
    Author: {
      byAuthor: '{authorName}',
    },
  },
};
