const DELETE_COUNT = 1;

export const createHandleDeleteEntryMessage = () => {
  const handledEntryIds = [];

  return (
    decoratedEntry,
    currentNormalizedEntries,
    currentEntriesCount,
    currentEntriesAuthors,
  ) => {
    let entriesCount = currentEntriesCount;

    // there is no need to remove an author when his/her last entry is removed
    // because authors of removed entries are stored in the author list on the backend
    const entriesAuthors = currentEntriesAuthors;

    const { id } = decoratedEntry;

    const index = currentNormalizedEntries.findIndex(entry => entry.id === id);

    // due to MQTT protocol and its QoS the same message can be delivered more than once
    // this behaviour relies on MQTT broker itself and also subscription type requested by a client
    // entry to remove can also be not present in the state as that entry can be old one
    const isDuplicate = handledEntryIds.includes(id);

    if (!isDuplicate) {
      handledEntryIds.push(id);
      entriesCount -= 1;
    }

    if (index === -1) {
      return {
        updatedEntries: currentNormalizedEntries,
        entriesCount,
        entriesAuthors,
      };
    }

    const normalizedUpdatedEntries = [...currentNormalizedEntries];

    normalizedUpdatedEntries.splice(index, DELETE_COUNT);

    return {
      updatedEntries: normalizedUpdatedEntries,
      entriesCount,
      entriesAuthors,
    };
  };
};
