import { ROLE_GUEST, ROLE_JOURNALIST, ROLE_READER } from './consts';

export const canUserEditEntry = (user = {}, rawEntry = {}) => {
  const {
    id: userId,
    role: userRole,
    byline: userByline,
  } = user;

  const {
    userId: entryAuthorId,
    journalistName: entryAuthorByline,
  } = rawEntry;

  switch (userRole) {
    case ROLE_READER:
      return false;
    case ROLE_JOURNALIST:
      return true;
    case ROLE_GUEST:
      return entryAuthorId
        ? userId === entryAuthorId
        : userByline === entryAuthorByline;
    default:
      throw new Error(`User role ${userRole} is not recognized`);
  }
};
