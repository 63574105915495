import React from 'react';
import PropTypes from 'prop-types';

const ArrowUpIcon = ({ position }) => {
  const styles = {
    position: 'relative',
    top: '2px',
    marginLeft: '6px',
  };

  if (position === 'down') {
    styles.transform = 'rotateZ(180deg)';
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="16"
      style={styles}
    >
      <defs>
        <path
          d="M12 4l.09.004.06.007.052.01.065.015.046.014.06.02.052.023.06.03.036.02.074.05a1 1 0 0 1 .112.097l-.1-.08.1.08 4 4a1 1 0 0 1-1.414 1.414L13 7.414V19a1 1 0 0 1-.883.993L12 20a1 1 0 0 1-1-1V7.414L8.707 9.707a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1 0-1.414l4-4 .082-.073.03-.023.075-.05.037-.02.06-.03.052-.022.06-.022.046-.014.065-.016.052-.01C11.9 4.004 11.95 4 12 4l-.09.004.07-.004z"
          id="A"
        />
      </defs>
      <use
        fill="#f6231e"
        xlinkHref="#A"
        transform="translate(-7 -4)"
        fillRule="evenodd"
      />
    </svg>);
};

ArrowUpIcon.propTypes = {
  position: PropTypes.oneOf(['up', 'down']).isRequired,
};

export default ArrowUpIcon;
