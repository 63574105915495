import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import ArrowUpIcon from './ArrowUpIcon';
import CloseIcon from './CloseIcon';

import { getTexts } from '../../config';

const NewEntriesIndicator = ({
  isVisible,
  newEntriesCount,
  onClick,
  publication,
  pointDown,
  theme,
  onClose,
}) => {
  const {
    newEntriesPrefix,
    newEntriesLabelSingular,
    newEntriesLabelPlural,
  } = getTexts(publication, 'NewEntriesIndicator');

  const newEntriesLabel = newEntriesCount > 1
    ? newEntriesLabelPlural
    : newEntriesLabelSingular;

  const handleOnCloseClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onClose();
  };

  const arrowPosition = pointDown ? 'down' : 'up';

  return isVisible
    ? <div
      className={theme.newEntriesIndicator}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <span className={theme.labelPrefix}>
        {newEntriesPrefix}:
      </span>
      {' '}
      {newEntriesLabel.replace('{count}', newEntriesCount)}

      <ArrowUpIcon position={arrowPosition} />

      <div className={theme.closeIcon} onClick={handleOnCloseClick} role="button" tabIndex={0}>
        <CloseIcon />
      </div>
    </div>
    : null;
};

NewEntriesIndicator.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  newEntriesCount: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  publication: PropTypes.string,
  pointDown: PropTypes.bool,
  // eslint-disable-next-line
  theme: PropTypes.object,
};

NewEntriesIndicator.defaultProps = {
  newEntriesCount: 0,
  publication: 'bt',
  pointDown: false,
  theme: {},
};

export default themer('NewEntriesIndicator')(NewEntriesIndicator);
