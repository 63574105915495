export default ({ css }) => {
  const playerSubstitutionDetails = css``;

  const playerIn = css`
    position: relative;
    margin-left: 20px;

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      top: 3px;
      border-color: transparent transparent transparent #15A6A6;
      border-style: solid;
      border-width: 6px 6px 6px 10px;
    }
  `;

  const playerOut = css`
    position: relative;
    margin-left: 20px;

    &::before {
      content: "";
      position: absolute;
      left: -27px;
      top: 3px;
      border-color: transparent #F00018 transparent transparent;
      border-style: solid;
      border-width: 6px 10px 6px 6px;
    }
  `;

  return {
    styles: {
      playerSubstitutionDetails,
      playerIn,
      playerOut,
    },
  };
};
