import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import FacebookProvider, { EmbeddedPost } from 'react-facebook';
import InstagramEmbed from 'react-instagram-embed';
import TweetEmbed from 'react-tweet-embed/dist/tweet-embed';

import { getInstagramHref, getTweetId, getFbHref } from '../../utils/socialEmbeds';

import { SOCIAL } from '../../utils/constants';

import { nodeShape, socialEmbedShape } from '../../utils/shapes';
import { getConfig } from '../../config';

class SocialEmbed extends Component {
  shouldComponentUpdate() {
    return false;
  }

  getSocialEmbedType(node) {
    if (node.attrs.class && node.attrs.class.indexOf('instagram-media') > -1) {
      return 'instagram';
    } else if (node.attrs.class && node.attrs.class.indexOf('twitter-tweet') > -1) {
      return 'twitter';
    } else if (node.attrs.src.indexOf('facebook') > -1) {
      return 'facebook';
    }
    return null;
  }

  render() {
    const { node, theme, publication } = this.props;
    const { TYPES } = SOCIAL;
    const fbAppId = getConfig(publication, 'appId', 'facebook');
    const type = this.getSocialEmbedType(node);
    let element = null;

    if (type === TYPES.INSTAGRAM) {
      const href = getInstagramHref(node);

      element = href ? <InstagramEmbed key={href} url={href} /> : null;
    } else if (type === TYPES.TWITTER) {
      const id = getTweetId(node);

      element = id ? <TweetEmbed key={id} id={id} /> : null;
    } else if (type === TYPES.FACEBOOK) {
      const href = getFbHref(node);

      element = href
        ? (
          <div
            className={theme.facebookPostContainer}
          >
            <FacebookProvider
              key={href}
              appID={fbAppId}
              width={500}
            >
              <EmbeddedPost
                href={href}
              />
            </FacebookProvider>
          </div>
        )
        : null;
    }
    return element;
  }
}

SocialEmbed.propTypes = {
  node: PropTypes.shape(nodeShape).isRequired,
  theme: PropTypes.shape(socialEmbedShape),
  publication: PropTypes.string.isRequired,
};

export default themer('SocialEmbed')(SocialEmbed);
