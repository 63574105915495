import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import { LoggedInUserShape } from '../../shapes/LoggedInUser';
import { getTexts } from '../../config';
import {
  BlogSettingsDefaultProps,
  BlogSettingsShape,
  FeedSettingsDefaultProps,
  FeedSettingsShape,
} from '../../shapes';

export const LiveblogLoginView = ({
  theme,
  publication,
  loggedInUser,
  blogSettings,
  feedSettings,
}, { trackEvent }) => {
  const texts = getTexts(publication, 'LiveblogLoginView');

  const {
    defaultClosedDescription,
    defaultLoginButtonContent,
  } = texts[publication] || texts;

  const descriptionText = blogSettings.closed
    ? defaultClosedDescription
    : feedSettings.description;

  const titleView = feedSettings.title
    ? (<div className={theme.heading}>{feedSettings.title}</div>)
    : null;

  const descriptionView = descriptionText
    ? (
      <div
        className={theme.description}
        style={!titleView ? { padding: 0 } : {}}
      >
        {descriptionText}
      </div>
    ) : null;

  const buttonView = loggedInUser.logIn && !loggedInUser.isLoggedIn && !blogSettings.closed
    ? (
      <button
        className={`liveblog-button-login ${theme.button}`}
        style={!titleView && !descriptionView ? { margin: 0 } : {}}
        onClick={(e) => {
          trackEvent(e);
          return loggedInUser.logIn();
        }}
      >
        {defaultLoginButtonContent}
      </button>
    ) : null;

  if (!titleView && !descriptionView && !buttonView) {
    return null;
  }

  return (
    <div className={theme.container}>
      {titleView}
      {descriptionView}
      {buttonView}
    </div>
  );
};

LiveblogLoginView.propTypes = {
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  publication: PropTypes.string.isRequired,
  loggedInUser: LoggedInUserShape,
  blogSettings: BlogSettingsShape,
  feedSettings: FeedSettingsShape,
};

LiveblogLoginView.defaultProps = {
  theme: {},
  loggedInUser: {},
  blogSettings: BlogSettingsDefaultProps,
  feedSettings: FeedSettingsDefaultProps,
};

LiveblogLoginView.contextTypes = {
  trackEvent: PropTypes.func,
};

export default themer('LiveblogLoginView')(LiveblogLoginView);
