export const handleUpdateFeedMessage = (
  messageBody,
) => {
  if (!messageBody) {
    return {};
  }

  const {
    title,
    description,
    actionText,
    summary,
    theme,
    allowUserMessages,
  } = messageBody;

  // structuredSummary field omitted because the front-end uses the raw html field only

  return {
    feedSettings: {
      title,
      description,
      actionText,
      summary,
      allowUserMessages,
      theme,
    },
  };
};

