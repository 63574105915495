import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import cc from 'classcat';

import EntryMetaInfo from '../EntryMetaInfo/EntryMetaInfo';
import EntryTitle from '../EntryTitle/EntryTitle';
import EntryContent from '../EntryContent/EntryContent';
import EntryEditOptions from '../EntryEditOptions/EntryEditOptions';
import EntryTags from '../EntryTags/EntryTags';

import { isInViewport } from '../../utils/html';
import { sportsMetaShape } from '../../utils/shapes';
import { isUserQuote, isUserJournalistChat } from '../../utils/entryContentEvaluator';
import { isBrowser } from '../../utils/env';
import {
  EntryActionsShape,
  AuthorTypeShape,
  BlogSettingsShape,
  FeedSettingsShape,
  BlogSettingsDefaultProps,
  FeedSettingsDefaultProps,
} from '../../shapes';

export class Entry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animate: false,
    };

    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    const { isNew, scrollableParent } = this.props;

    if (!isBrowser()) {
      return;
    }

    if (isNew) {
      scrollableParent.addEventListener('scroll', this.onScroll);

      this.onScroll();
    }
  }

  componentDidUpdate(prevProps) {
    const { isNew, scrollableParent } = this.props;

    if (isNew) {
      scrollableParent.addEventListener('scroll', this.onScroll);

      this.onScroll();
    } else if (prevProps.isNew === true) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ animate: true });

      scrollableParent.removeEventListener('scroll', this.onScroll);
    }
  }

  componentWillUnmount() {
    const { scrollableParent } = this.props;

    if (!scrollableParent) {
      return;
    }

    scrollableParent.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    const { onNewEntryVisit, isNew } = this.props;

    if (isNew) {
      const isEntryInViewPort = isInViewport(this.node);

      if (isEntryInViewPort) {
        onNewEntryVisit();
      }
    }
  }

  render() {
    const {
      id,
      content,
      authorName,
      authorImage,
      authorType,
      date,
      tags,
      shouldShowTags,
      pinnedUrl,
      isEditable,
      isNew,
      addedWithinMatch,
      meta,
      theme,
      publication,
      entriesAuthors,
      entryActions,
      structuredContent,
      isNtb,
      title,
      newsValue,
      feedId,
      sourceName,
      blogSettings,
      feedSettings,
    } = this.props;

    const { animate } = this.state;

    const entryStyleClasses = cc({
      [theme.entry]: true,
      [theme.animate]: animate,
      [theme.isNew]: isNew,
    });

    const editOptions = isEditable
      ? (
        <EntryEditOptions
          id={id}
          newsValue={newsValue}
          feedId={feedId}
          {...entryActions}
        />
      )
      : null;

    const entryTags = shouldShowTags
      ? <EntryTags tags={tags} />
      : null;

    const isQuoteOnly = isUserQuote(content);
    const isChat = isUserJournalistChat(content);

    return (
      <li
        ref={(node) => { this.node = node; }}
        className={entryStyleClasses}
        data-testid="liveblog-entry"
      >
        <div className={theme.entryMeta}>
          <EntryMetaInfo
            authorName={authorName}
            authorImage={authorImage}
            authorType={authorType}
            date={date}
            addedWithinMatch={addedWithinMatch}
            matchMeta={meta}
            isUserQuote={isQuoteOnly}
            isChat={isChat}
            publication={publication}
            pinnedUrl={pinnedUrl}
            id={id}
            blogSettings={blogSettings}
            feedSettings={feedSettings}
            hasEntryTitle={!!title}
          />
          {editOptions}
        </div>
        <EntryTitle title={title} />
        <EntryContent
          content={content}
          publication={publication}
          isChat={isChat || isQuoteOnly}
          authorName={authorName}
          authorImage={authorImage}
          authorType={authorType}
          entriesAuthors={entriesAuthors}
          structuredContent={structuredContent}
          isNtb={isNtb}
          feedSettings={feedSettings}
          sourceName={sourceName}
        />
        {entryTags}
      </li>
    );
  }
}

Entry.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  content: PropTypes.string.isRequired,
  authorName: PropTypes.string,
  authorImage: PropTypes.string,
  authorType: AuthorTypeShape,
  sourceName: PropTypes.string.isRequired,
  date: PropTypes.string,
  pinnedUrl: PropTypes.string,
  isEditable: PropTypes.bool,
  shouldShowTags: PropTypes.bool,
  addedWithinMatch: PropTypes.bool,
  onNewEntryVisit: PropTypes.func,
  meta: PropTypes.shape(sportsMetaShape),
  isNew: PropTypes.bool,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  publication: PropTypes.string.isRequired,
  entriesAuthors: PropTypes.arrayOf(PropTypes.string),
  entryActions: EntryActionsShape,
  scrollableParent: PropTypes.shape({
    addEventListener: PropTypes.func,
    removeEventListener: PropTypes.func,
  }),
  structuredContent: PropTypes.object,
  isNtb: PropTypes.bool,
  title: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  newsValue: PropTypes.number,
  feedId: PropTypes.string,
  blogSettings: BlogSettingsShape,
  feedSettings: FeedSettingsShape,
};

Entry.defaultProps = {
  title: '',
  tags: [],
  isEditable: false,
  entriesAuthors: [],
  theme: {},
  structuredContent: {},
  isNtb: false,
  feedId: null,
  blogSettings: BlogSettingsDefaultProps,
  feedSettings: FeedSettingsDefaultProps,
};

export default themer('Entry')(Entry);
