import { isBrowser } from './env';

// for this to work in embeds we need to refer to window.parent
// if a window does not have a parent
// its parent property is a reference to itself
// https://developer.mozilla.org/en-US/docs/Web/API/Window/parent
export const getPageUrl = () => (
  isBrowser()
    ? window.parent.location.href.replace(/#.+$/, '')
    : ''
);

export const getPageUrlWithoutPinnedEntry = pageUrl => (
  isBrowser()
    ? pageUrl.replace(/([?&])pinnedEntry=\d+/, '')
    : ''
);

export const getUrlSeparator = pageUrlWithoutPinnedEntry => (
  pageUrlWithoutPinnedEntry.includes('?')
    ? '&'
    : '?'
);

export const getDomainFromUrl = (url) => {
  if (!isBrowser()) {
    return null;
  }

  const link = document.createElement('a');
  link.setAttribute('href', url);

  return link.hostname;
};

export const allowedScriptOrigins = [
  'www.aftenposten.no',
  'aftenposten.no',
  'mm.aftenposten.no',
  'ap.no',
  'www.bt.no',
  'bt.no',
  'mm.bt.no',
  'www.aftenbladet.no',
  'aftenbladet.no',
  'mm.aftenbladet.no',
];
