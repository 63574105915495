import { colorSportbladet } from './variables';

export default ({ css, defaultStyles }) => {
  const { SportsEntryTime } = defaultStyles;

  const timeMain = css`
    ${SportsEntryTime.timeMain};

    color: var(--c-primary-01, ${colorSportbladet});
  `;

  return {
    styles: {
      ...SportsEntryTime,
      timeMain,
    },
  };
};
