import PropTypes from 'prop-types';

import { USER } from '../utils/constants';

const {
  TYPES: {
    JOURNALIST,
    GUEST,
  },
} = USER;

export const AuthorTypeShape = PropTypes.oneOf(
  [
    JOURNALIST,
    GUEST,
  ],
);
