import PropTypes from 'prop-types';
import { LIVEFEED_THEMES } from '../utils/constants';

export const FeedSettingsShape = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  actionText: PropTypes.string,
  summary: PropTypes.string,
  allowUserMessages: PropTypes.bool,
  theme: PropTypes.oneOf(Object.keys(LIVEFEED_THEMES)),
});

export const FeedSettingsDefaultProps = PropTypes.shape({
  title: null,
  description: null,
  actionText: null,
  summary: null,
  allowUserMessages: true,
  theme: LIVEFEED_THEMES.DEFAULT,
});
