import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import JournalistReply from '../JournalistReply/JournalistReply';
import EntryUserMessage from '../EntryUserMessage';

import {
  getEntriesStructure,
  BLOCKQUOTE_TYPE,
  CONTENT_TYPE,
  JOURNALIST_REPLY_TYPE,
} from '../../utils/QAStructureParser';

import { uniqueKeyGenerator } from '../../utils/generators';
import {
  CHAT_TYPE,
  USER_MESSAGE_SUBTYPE,
} from '../structuredContent/componentTypes';

import {
  isUserJournalistChat,
  isUserQuote,
} from '../../utils/entryContentEvaluator';
import {
  FeedSettingsDefaultProps,
  FeedSettingsShape,
} from '../../shapes';

const keyGenerator = uniqueKeyGenerator();

export const ChatContent = ({
  content,
  authorName,
  authorImage,
  authorType,
  publication,
  theme,
  structuredContent: { components = [] } = {},
  feedSettings,
}) => {
  const entriesStructure = getEntriesStructure(content);

  const isQuoteOnly = isUserQuote(content);
  const isChat = isUserJournalistChat(content);

  const parsedEntriesStructure = entriesStructure.map((item) => {
    const key = keyGenerator.generateUniqueKey();

    switch (item.type) {
      case BLOCKQUOTE_TYPE: {
        const userMessage = components.find(
          ({ type, subtype, meta: { userMessageId } = {} }) => type === CHAT_TYPE
            && subtype === USER_MESSAGE_SUBTYPE
            && userMessageId === item.attrs['data-id'],
        );

        return (
          <EntryUserMessage
            userMessage={userMessage}
            theme={theme}
            key={key}
          />
        );
      }

      case CONTENT_TYPE:
        return item.content;

      case JOURNALIST_REPLY_TYPE:
        return (
          <JournalistReply
            authorImage={authorImage}
            authorName={authorName}
            authorType={authorType}
            isQuoteOnly={isQuoteOnly}
            isChat={isChat}
            publication={publication}
            content={item.content}
            key={key}
            feedSettings={feedSettings}
          />
        );

      default:
        throw new Error(`Unhandled structure type "${item.type}"`);
    }
  });

  return (
    <div className={theme.chatContentWrapper}>
      { parsedEntriesStructure }
    </div>
  );
};

ChatContent.propTypes = {
  content: PropTypes.array,
  structuredContent: PropTypes.shape({
    components: PropTypes.array,
  }),
  authorName: PropTypes.string,
  authorImage: PropTypes.string,
  authorType: PropTypes.string,
  publication: PropTypes.string.isRequired,
  feedSettings: FeedSettingsShape,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

ChatContent.defaultProps = {
  content: [],
  authorName: null,
  authorImage: null,
  authorType: null,
  feedSettings: FeedSettingsDefaultProps,
  theme: {},
};

export default themer('ChatContent')(ChatContent);
