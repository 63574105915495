export const ROLE_JOURNALIST = 'JOURNALIST';
export const ROLE_READER = 'READER';
export const ROLE_GUEST = 'GUEST';

const userRoles = [ROLE_GUEST, ROLE_JOURNALIST, ROLE_READER];

export const LIVEFEED_THEMES = {
  DEFAULT: 'DEFAULT',
  SPEECH_BUBBLES: 'SPEECH_BUBBLES',
};

export const POLLING_STRATEGIES = {
  NORMAL: 'NORMAL',
  SLOW: 'SLOW',
  SLOWER: 'SLOWER',
  MINIMAL: 'MINIMAL',
};

export {
  userRoles,
};
