import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import cc from 'classcat';

import { getTexts } from '../../config';

import CameraIcon from './Icons/CameraIcon';

class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDragInProgress: false,
    };

    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onChange = this.onChange.bind(this);
    this.uploadFilesToState = this.uploadFilesToState.bind(this);
    this.preventDefaults = this.preventDefaults.bind(this);
  }

  onDragEnter(event) {
    this.preventDefaults(event);
  }

  onDragOver(event) {
    this.preventDefaults(event);
    this.setState(() => ({ isDragInProgress: true }));
  }

  onDragLeave(event) {
    this.preventDefaults(event);
    this.setState(() => ({ isDragInProgress: false }));
  }

  onDrop(event) {
    this.preventDefaults(event);
    this.uploadFilesToState(event.dataTransfer.files);
  }

  onChange(event) {
    this.uploadFilesToState(event.target.files);
  }

  uploadFilesToState(files) {
    const updatedUploadedFiles = [...this.props.uploadedFiles, ...files];

    this.setState(() => (
      { isDragInProgress: false }
    ),
    () => {
      this.props.onChange(updatedUploadedFiles);
    });
  }

  preventDefaults(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const {
      acceptableFileTypes,
      theme,
      publication,
      preview,
    } = this.props;

    const texts = getTexts(publication, 'FileUpload');

    const buttonContent = (
      <span>
        <CameraIcon />
        <span className={theme.buttonText}>
          { texts.dropArea }
        </span>
      </span>
    );

    const dropAreaView = (
      <div className={cc({
        [theme.dropAreaWrapper]: true,
        [theme.withPreview]: !!preview,
      })}
      >
        <input
          type="file"
          id="file"
          multiple
          accept={acceptableFileTypes}
          className={theme.input}
        />

        <label htmlFor="file" className={theme.labelDesktop} >
          Dra bilder hit, eller

          <span className={theme.button}>
            {buttonContent}
          </span>
        </label>

        <label htmlFor="file" className={theme.labelMobile}>
          {buttonContent}
        </label>
      </div>
    );

    const previewView = preview && preview.name ?
      (
        <img
          src={URL.createObjectURL(preview)}
          alt="preview"
          className={theme.uploadPreview}
        />
      ) : null;

    return (
      <div
        className={cc({
          [theme.dropArea]: true,
          [theme.dropAreaOnDragover]: this.state.isDragInProgress,
        })}
        onDragEnter={this.onDragEnter}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onChange={this.onChange}
      >
        { previewView }

        { dropAreaView }
      </div>
    );
  }
}

FileUpload.propTypes = {
  publication: PropTypes.string.isRequired,
  acceptableFileTypes: PropTypes.string,
  uploadedFiles: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  preview: PropTypes.object,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

FileUpload.defaultProps = {
  acceptableFileTypes: 'image/jpg, image/jpeg, image/png, image/heif, image/heic, image/heif-sequence, image/heic-sequence',
  uploadedFiles: [],
  preview: null,
  onChange: () => {},
  theme: {},
};

export default themer('FileUpload')(FileUpload);
