export default ({ css, defaultStyles }) => {
  const { ArticleTeaserContent } = defaultStyles;

  const articleTeaserTextContent = css`
    ${ArticleTeaserContent.articleTeaserTextContent}
    color: var(--c-dark, #333);
  `;

  const articleTeaserPublishedDate = css`
    ${ArticleTeaserContent.articleTeaserPublishedDate}
    color: var(--c-neutral-05,rgba(0,0,0,0.6));
  `;

  return {
    styles: {
      ...ArticleTeaserContent,
      articleTeaserTextContent,
      articleTeaserPublishedDate,
    },
  };
};
