import React from 'react';
import { resolveMarkup } from '@rnd-live/markup-to-jsx';

import { StructuredTextShape } from '../../shapes';

const StructuredText = ({ component }) => {
  const { value, markup } = component;

  const resolvedContent = markup
    ? resolveMarkup(value, markup)
    : value;

  return (
    <p>
      {resolvedContent}
    </p>
  );
};

StructuredText.propTypes = {
  component: StructuredTextShape.isRequired,
};

export default StructuredText;
