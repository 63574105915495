export default ({ css }) => {
  const mobileVersion = 'min-width: 768px';

  const messageFormContainer = css`
    font-family: Arial, sans-serif;

    * {
      box-sizing: border-box;
    }
  `;

  const messageFormDescription = css`
    display: flex;
    justify-content: space-between;
    color: #787878;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 18px;
    margin-top: 15px;
  `;

  const form = css`
    margin-top: 15px;

    @media (${mobileVersion}) {
      display: flex;
    }
  `;

  const contentContainer = css`
    flex-grow: 1;

    @media (${mobileVersion}) {
      margin-right: 12px;
    }
  `;

  const textareaWrapper = css`
    & > div > div {
      position: static;
    }
  `;

  const actionContainer = css`
    display: flex;

    @media (${mobileVersion}) {
      width: 224px;
      display: block;
    }

    div {
      width: 100%;
    }

    [role="button"] {
      margin-left: 0;
    }
  `;

  return {
    styles: {
      messageFormContainer,
      messageFormDescription,
      form,
      contentContainer,
      actionContainer,
      textareaWrapper,
    },
  };
};
