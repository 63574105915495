import { createHandleEntryMessage } from './handleEntryMessage';
import { createHandlePublishEntryMessage as createHandlePublish } from './handlePublishEntryMessage';
import { createHandleUpdateEntryMessage as createHandleUpdate } from './handleUpdateEntryMessage';
import { createHandleDeleteEntryMessage as createHandleDelete } from './handleDeleteEntryMessage';
import { handleUpdateLiveblogMessage } from './handleUpdateLiveblogMessage';
import { handleUpdateFeedMessage } from './handleUpdateFeedMessage';

export const createMessageHandlers = () => ({
  handleUpdateLiveblogMessage,
  handlePublishEntryMessage: createHandleEntryMessage(createHandlePublish()),
  handleUpdateEntryMessage: createHandleEntryMessage(createHandleUpdate()),
  handleDeleteEntryMessage: createHandleEntryMessage(createHandleDelete()),
  handleUpdateFeedMessage,
});

export * from './handleUpdateLiveblogMessage';
export * from './handleUpdateFeedMessage';
export * from './handlePinnedEntryModification';
