export default ({ css, defaultStyles }) => {
  const { EntryContent } = defaultStyles;

  const overrideFontSize = css`
    font-size: 16px;
    line-height: 21px;
  `;

  const entryContentWrapper = css`
    ${EntryContent.entryContentWrapper};
    margin-right: 30px;

    ${overrideFontSize};

    a {
      color: var(--link-color, #1973c8);
    }

    @media (min-width: 767px) {
      ${overrideFontSize};
    }
  `;

  return {
    styles: {
      ...EntryContent,
      entryContentWrapper,
    },
  };
};
