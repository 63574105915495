export default ({ css }) => {
  const tagsList = css`
    list-style: none;
    display: flex;
    width: 100%;
    margin-top: 0.5em;
    justify-content: flex-start;
    padding: 0 13px;
    flex-wrap: wrap;
  `;

  const tagsListItem = css`
    padding: 5px 10px;
    margin-right: 10px;
    margin-top: 10px;
    background-color: #F0F0F0;

    &:last-of-type {
      margin-right: 0;
    }
  `;

  return {
    styles: {
      tagsList,
      tagsListItem,
    },
  };
};
