export default ({ css }) => {
  const heading = css`
    display: none;
  `;

  return {
    styles: {
      heading,
    },
  };
};
