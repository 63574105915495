import PropTypes from 'prop-types';

const DisplayConditionallyForAB = ({ publication, hasEntryTitle, children }) => {
  if (publication === 'ab' && !hasEntryTitle) {
    return null;
  }

  return children;
};

DisplayConditionallyForAB.propTypes = {
  publication: PropTypes.string.isRequired,
  hasEntryTitle: PropTypes.bool,
};

DisplayConditionallyForAB.defaultProps = {
  hasEntryTitle: false,
};

export default DisplayConditionallyForAB;
