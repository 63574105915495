const getTopicNames = (publication, liveblogId, resourceNames, isStageMode = false) => {
  const errorMessage = 'is required to assembly correct topic name';

  if (!publication) {
    throw new Error(`Publication name ${errorMessage}`);
  }

  if (!liveblogId) {
    throw new Error(`Liveblog ID ${errorMessage}`);
  }

  if (!resourceNames || resourceNames.length === 0) {
    throw new Error(`Resource name ${errorMessage}`);
  }

  const prefixForStage = isStageMode ? 'stage/' : '';

  const topics = resourceNames.map(name => `live/${prefixForStage}${publication}/${name}/${liveblogId}`);

  return topics;
};

export default getTopicNames;
