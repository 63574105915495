import React from 'react';

const CameraIcon = () => (
  <svg width="13px" height="11px" viewBox="0 0 13 11" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1213.000000, -310.000000)" fill="currentColor">
        <path d="M1218.375,310 C1218.72018,310 1219,310.268629 1219,310.6 L1219,312 L1225.35903,312 C1225.58191,312 1225.66273,312.023206 1225.74421,312.066783 C1225.82569,312.11036 1225.88964,312.174307 1225.93322,312.255789 C1225.97679,312.33727 1226,312.418092 1226,312.640971 L1226,320.359029 C1226,320.581908 1225.97679,320.66273 1225.93322,320.744211 C1225.88964,320.825693 1225.82569,320.88964 1225.74421,320.933217 C1225.66273,320.976794 1225.58191,321 1225.35903,321 L1213.64097,321 C1213.41809,321 1213.33727,320.976794 1213.25579,320.933217 C1213.17431,320.88964 1213.11036,320.825693 1213.06678,320.744211 C1213.02321,320.66273 1213,320.581908 1213,320.359029 L1213,312.640971 C1213,312.418092 1213.02321,312.33727 1213.06678,312.255789 C1213.11036,312.174307 1213.17431,312.11036 1213.25579,312.066783 C1213.33727,312.023206 1213.41809,312 1213.64097,312 L1214,312 L1214,310.6 C1214,310.268629 1214.27982,310 1214.625,310 L1218.375,310 Z M1221.5,314 C1220.11929,314 1219,315.119288 1219,316.5 C1219,317.880712 1220.11929,319 1221.5,319 C1222.88071,319 1224,317.880712 1224,316.5 C1224,315.119288 1222.88071,314 1221.5,314 Z M1221.5,315 C1222.32843,315 1223,315.671573 1223,316.5 C1223,317.328427 1222.32843,318 1221.5,318 C1220.67157,318 1220,317.328427 1220,316.5 C1220,315.671573 1220.67157,315 1221.5,315 Z M1218,311 L1215,311 L1215,312 L1218,312 L1218,311 Z" id="icon_camera_red" />
      </g>
    </g>
  </svg>
);

export default CameraIcon;
