const ParseResponse = handleError => (response) => {
  if (response.status >= 400) {
    return Promise.resolve(handleError(`http response code ${response.status}`));
  }

  return response
    .text()
    .then((textBody) => {
      if (textBody.length) {
        try {
          return JSON.parse(textBody);
        } catch (error) {
          return handleError(`JSON malformed
parsing error: ${error}
response body: ${textBody}`);
        }
      }

      return handleError('empty response body');
    })
    .then((data) => {
      if (data && data.statusCode) {
        return handleError(`API responded with
code: ${data.statusCode}
message: ${data.message}`);
      }

      return data;
    });
};

export default ParseResponse;
