import { createStyles } from '../functions';

import Liveblog from './Liveblog';
import LiveblogHeading from './LiveblogHeading';
import LiveblogLoginView from './LiveblogLoginView';
import LiveblogSummary from './LiveblogSummary';
import LoadMoreEntries from './LoadMoreEntries';
import NewEntriesIndicator from './NewEntriesIndicator';
import PinnedEntry from './PinnedEntry';
import Author from './Author';
import CopyToClipboard from './CopyToClipboard';
import Entry from './Entry';
import SportsEntry from './SportsEntry';
import SportsEntryHeader from './SportsEntryHeader';
import SportsEntryHeadline from './SportsEntryHeadline';
import SportsIcon from './SportsEntryIcon';
import EntryContent from './EntryContent';
import ChatContent from './ChatContent';
import EntryEditOptions from './EntryEditOptions';
import EntryImage from './EntryImage';
import EntryImageInfo from './EntryImageInfo';
import EntryMetaInfo from './EntryMetaInfo';
import EntryTime from './EntryTime';
import EntryTimeDetails from './EntryTimeDetails';
import EntryTimeMain from './EntryTimeMain';
import EntryTitle from './EntryTitle';
import EntryTags from './EntryTags';
import EntryThemed from './EntryThemed';
import EntryUserMessage from './EntryUserMessage';
import EntryVideo from './EntryVideo';
import FileUpload from './FileUpload';
import ArticleTeaserContent from './ArticleTeaserContent';
import JournalistReply from './JournalistReply';
import SocialEmbed from './SocialEmbed';
import SkeletonView from './SkeletonView';
import SportsEntryTime from './SportsEntryTime';
import SportsEntryContent from './SportsEntryContent';
import GeneralContent from './SportsEntryContent/GeneralContent';
import GoalContent from './SportsEntryContent/GoalContent';
import PlayerSubstitutionContent from './SportsEntryContent/PlayerSubstitutionContent';
import MessageForm from './MessageForm/MessageForm';
import MessageFormButton from './MessageForm/MessageFormButton';
import MessageFormInput from './MessageForm/MessageFormInput';
import MessageFormTextArea from './MessageForm/MessageFormTextArea';
import MessageFormUploadedFiles from './MessageForm/MessageFormUploadedFiles';
import MessageFormStatusLabel from './MessageForm/MessageFormStatusLabel';

export const theme = (props) => {
  const styles = {
    ArticleTeaserContent,
    Author,
    ChatContent,
    CopyToClipboard,
    Entry,
    EntryContent,
    EntryEditOptions,
    EntryImage,
    EntryImageInfo,
    EntryMetaInfo,
    EntryTime,
    EntryTimeMain,
    EntryTimeDetails,
    EntryTitle,
    EntryTags,
    EntryVideo,
    EntryThemed,
    EntryUserMessage,
    FileUpload,
    GeneralContent,
    GoalContent,
    JournalistReply,
    Liveblog,
    LiveblogHeading,
    LiveblogLoginView,
    LiveblogSummary,
    LoadMoreEntries,
    MessageFormButton,
    MessageForm,
    MessageFormInput,
    MessageFormStatusLabel,
    MessageFormTextArea,
    MessageFormUploadedFiles,
    NewEntriesIndicator,
    PinnedEntry,
    PlayerSubstitutionContent,
    SkeletonView,
    SocialEmbed,
    SportsEntry,
    SportsIcon,
    SportsEntryContent,
    SportsEntryHeader,
    SportsEntryHeadline,
    SportsEntryTime,
  };

  return createStyles(styles, props);
};
