export default ({ css }) => {
  const button = css`
    outline-style: none;
    font-family: HelveticaNeue-Bold, sans-serif;
    color: #FFF;
    cursor: pointer;
    background-color: #2c78e4;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
    padding: 8px 22px;
    letter-spacing: 0.4px;
    line-height: 17px;
    white-space: nowrap;
    margin-left: 12px;
  `;

  const buttonDisabled = css`
    color: #2c78e4;
    background-color: transparent;
    font-family: HelveticaNeue, sans-serif;
    font-size: 14px;
    font-weight: unset;
    text-decoration: underline;
    text-align: right;
    letter-spacing: 0.47px;
    line-height: 16px;
    border: none;
    outline: none;
    padding: 0;
  `;

  const buttonRaised = css`
    color: #FFF;
    background: #2c78e4;
    font-family: HelveticaNeue-Medium, sans-serif;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.3px;
    line-height: 16px;
    border-radius: 2px;

    @media (min-width: 768px) {
      font-size: 16px;
      margin-top: 7px;
      padding: 12px 0;
    }
  `;

  return {
    styles: {
      button,
      buttonDisabled,
      buttonRaised,
    },
  };
};
