
export const publicationConfigs = {
  default: {
    facebook: {
      appId: '218256922062230',
    },
    locale: 'nb-NO',
    language: 'nb-no',
  },
  ab: {
    facebook: {
      appId: '2305037226488658',
    },
    locale: 'sv-SE',
    language: 'sv-se',
  },
  svd: {
    facebook: {
      appId: '120052334696503',
    },
    locale: 'sv-SE',
    language: 'sv-se',
  },
};
