import PollingStrategy from './strategy/PollingStrategy';
import PollingStrategyInterval from './strategy/PollingStrategyInterval';

export default ({
  interval,
  action,
} = {}) => PollingStrategy({
  getPollingInterval: PollingStrategyInterval({
    interval,
  }),
  callback: action,
});
