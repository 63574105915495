import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

export const LiveblogHeading = ({
  // eslint-disable-next-line no-unused-vars
  hasPin,
  theme,
  title,
}) => (
  <span className={theme.heading}>
    {title}
  </span>
);

LiveblogHeading.propTypes = {
  hasPin: PropTypes.bool,
  title: PropTypes.string,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

LiveblogHeading.defaultProps = {
  hasPin: false,
  theme: {},
};

export default themer('LiveblogHeading')(LiveblogHeading);
