import { colorSportbladetChatResponse } from './variables';
import {
  className,
  uniqueClassName,
  sportbladet,
} from './functions';

export default ({ css, defaultStyles }) => {
  const { JournalistReply } = defaultStyles;

  const journalistReplyBlockquote = uniqueClassName(
    css,
    'journalistReplyBlockquote',
  )(css`
    ${JournalistReply.journalistReplyBlockquote};

    // this is default color, tried with transparency but didn't work, we don't have this pink in our DNA
    background-color: rgb(246,211,211);
    color: #333;

    &::after {
      border-bottom-color: rgb(246,211,211);
    }

    a {
      color: #1973c8;
    }
  `);

  const styles = {
    ...JournalistReply,
    journalistReplyBlockquote,
  };

  const globalStyles = sportbladet(`
    ${className(journalistReplyBlockquote)} {
      background-color: var(--c-neutral-04, ${colorSportbladetChatResponse});

      &::after {
        border-bottom-color: var(--c-neutral-04, ${colorSportbladetChatResponse});
      }
    }
  `);

  return {
    styles,
    globalStyles,
  };
};
