import {
  colorPrimary,
  colorSportbladet,
} from '../variables';

import {
  className,
  uniqueClassName,
  sportbladet,
} from '../functions';

export default ({ css, defaultStyles }) => {
  const { MessageFormTextArea } = defaultStyles;

  const counter = css`
    ${MessageFormTextArea.counter};

    border-radius: 0 0 8px 8px;
  `;

  const content = uniqueClassName(css, 'content')(css`
    ${MessageFormTextArea.content};

    border-radius: 8px 8px 0 0;
  `);

  const validationErrorContainer = uniqueClassName(
    css,
    'validationErrorContainer',
  )(
    css`
    ${MessageFormTextArea.validationErrorContainer};

    border-radius: 8px;
    border-color: var(--c-primary-01, ${colorPrimary});

    ${className(content)}::-webkit-input-placeholder {
      color: var(--c-primary-01, ${colorPrimary});
    }
  `);

  const styles = {
    ...MessageFormTextArea,
    counter,
    content,
    validationErrorContainer,
  };

  const globalStyles = sportbladet(`
    ${className(validationErrorContainer)} {
      background-color: ${colorSportbladet};

      ${className(content)}::-webkit-input-placeholder {
        color: ${colorSportbladet};
      }
    }
  `);

  return {
    styles,
    globalStyles,
  };
};
