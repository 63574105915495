import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import SportsEntryHeadline from '../SportsEntryHeadline/SportsEntryHeadline';

import { sportsEntryShape } from '../../../utils/shapes';

export const SportsEntryHeader = ({ type, subtype, entry, theme, publication }) => (
  <div className={theme.sportsEntryHeader} >
    <SportsEntryHeadline type={type} subtype={subtype} meta={entry.meta} elapsed={entry.elapsed} publication={publication} />
  </div>
);

SportsEntryHeader.propTypes = {
  type: PropTypes.string,
  subtype: PropTypes.string,
  entry: PropTypes.shape(sportsEntryShape),
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  publication: PropTypes.string.isRequired,
};

export default themer('SportsEntryHeader')(SportsEntryHeader);
