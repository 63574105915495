import { EVENT_TYPES } from '@core-live/realtime-connector';

import { normalizeDecoratedEntry as _normalizeDecoratedEntry } from '../../utils/normalizeDecoratedEntry';

export const createHandlePinnedEntryModification = (
  normalizeDecoratedEntry = _normalizeDecoratedEntry,
) => (
  messageType,
  decoratedEntry,
  blogSettings,
  currentPinnedEntry,
) => {
  const { id } = decoratedEntry;

  if (id === blogSettings.pinnedEntryId) {
    if (messageType === EVENT_TYPES.DELETE_ENTRY) {
      return {
        blogSettings: {
          ...blogSettings,
          pinnedEntryId: null,
        },
        pinnedEntry: null,
      };
    } else if (messageType === EVENT_TYPES.UPDATE_ENTRY) {
      return {
        blogSettings,
        pinnedEntry: normalizeDecoratedEntry(decoratedEntry),
      };
    }
  }

  return {
    blogSettings,
    pinnedEntry: currentPinnedEntry,
  };
};
