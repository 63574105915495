import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import cc from 'classcat';

import { EntryActionsShape } from './shapes/EntryActions';
import { LoggedInUserShape } from './shapes/LoggedInUser';
import MessageForm from './components/MessageForm/MessageForm';
import Entry from './components/Entry/Entry';
import PinnedEntry from './components/PinnedEntry/PinnedEntry';
import LoadMoreEntries from './components/LoadMoreEntries/LoadMoreEntries';
import LiveblogHeading from './components/LiveblogHeading/LiveblogHeading';
import LiveblogLoginView from './components/LiveblogLoginView/LiveblogLoginView';
import SportsEntry from './components/SportsLive/SportsEntry/SportsEntry';
import SkeletonView from './components/SkeletonView/SkeletonView';

import { isBrowser } from './utils/env';
import {
  scrollToY,
  getOffsetTop,
  isInViewport,
  getScrollY,
} from './utils/html';
import NewEntriesIndicator from './components/NewEntriesIndicator/NewEntriesIndicator';

import { TYPES } from './components/SportsLive/utils/const';
import { getTexts } from './config';
import {
  BlogSettingsDefaultProps,
  BlogSettingsShape,
  FeedSettingsDefaultProps,
  FeedSettingsShape,
} from './shapes';

export class Liveblog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newEntriesCount: 0,
    };

    this.pinnedEntryHeight = 0;
    this.liveblogWasInViewport = false;
    this.newestEntryId = null;
    this.containerHeight = null;
    this.isLoadingMoreEntries = false;
    this.firstDataLoaded = false;

    this.onNewEntriesClick = this.onNewEntriesClick.bind(this);
    this.setPinnedEntryHeight = this.setPinnedEntryHeight.bind(this);
    this.clearNewEntriesCount = this.clearNewEntriesCount.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.clearListeners = this.clearListeners.bind(this);
    this.handleViewportCheck = this.handleViewportCheck.bind(this);
    this.getLiveblogView = this.getLiveblogView.bind(this);
    this.getLoadingView = this.getLoadingView.bind(this);
    this.updateNewEntriesCount = this.updateNewEntriesCount.bind(this);
    this.onNewEntryVisit = this.onNewEntryVisit.bind(this);
  }

  getChildContext() {
    return { trackEvent: this.props.trackEvent };
  }

  componentDidMount() {
    if (!isBrowser()) {
      return;
    }

    const { scrollableParent } = this.props;
    scrollableParent.addEventListener('scroll', this.onScroll);

    this.handleViewportCheck();

    this.createResizeObserver();
  }

  updateNewEntriesCount() {
    const { entries, isDataLoadedClientSide, placement } = this.props;

    if (isDataLoadedClientSide && !this.firstDataLoaded) {
      this.firstDataLoaded = true;

      if (entries.length > 0) {
        this.newestEntryId = entries[0].id;
      }

      return;
    }

    if (entries.length === 0 || !this.firstDataLoaded) {
      this.newestEntryId = null;

      return;
    }

    if (placement && placement !== 1) {
      return;
    }

    if (this.newestEntryId === entries[0].id) {
      return;
    }

    let count = this.liveblogWasInViewport
      ? entries.findIndex(el => el.id === this.newestEntryId)
      : 0;

    if (count < 0) {
      count = this.newestEntryId ? 0 : entries.length;
    }

    this.newestEntryId = entries[0].id;

    if (count) {
      this.setState(({ newEntriesCount }) => ({
        newEntriesCount: newEntriesCount + count,
      }));
    }
  }

  componentDidUpdate() {
    if (!isBrowser()) {
      return;
    }

    const { isFetchingBelow } = this.props;

    if (isFetchingBelow) {
      this.isLoadingMoreEntries = true;
    } else if (this.isLoadingMoreEntries) {
      // TODO: find a better solution - https://github.schibsted.io/RND/Live/issues/1442
      this.resetLoadingMoreEntriesTimeout = setTimeout(() => {
        this.isLoadingMoreEntries = false;
      }, 2000);
    }

    this.containerOffsetTop = getOffsetTop(this.props.scrollableParent, this.container);

    this.updateNewEntriesCount();

    if (!this.liveblogWasInViewport) {
      this.handleViewportCheck();
    }
  }

  componentWillUnmount() {
    if (!isBrowser()) {
      return;
    }

    this.clearListeners();
    this.disableResizeObserver();
    clearTimeout(this.resetLoadingMoreEntriesTimeout);
  }

  markInViewport() {
    this.liveblogWasInViewport = true;
    this.clearListeners();
  }

  clearListeners() {
    const { scrollableParent } = this.props;

    scrollableParent.removeEventListener('scroll', this.onScroll);
  }

  handleViewportCheck() {
    if (isInViewport(this.container)) {
      this.markInViewport();
    }
  }

  shouldKeepScrollPosition() {
    const { scrollableParent } = this.props;
    const { newEntriesCount } = this.state;

    const someOffsetForTheHeader = 70;
    const firstEntryOffsetTop = this.containerOffsetTop + this.pinnedEntryHeight + someOffsetForTheHeader;
    const currentScrollPosition = getScrollY(scrollableParent);

    const shouldKeepScrollPosition = firstEntryOffsetTop < currentScrollPosition;

    return newEntriesCount && shouldKeepScrollPosition;
  }

  restoreScrollPosition(newContainerHeight) {
    const { scrollableParent } = this.props;

    const scrollOffset = newContainerHeight - this.containerHeight;

    if (scrollOffset !== 0) {
      scrollableParent.scrollTo(
        0,
        getScrollY(scrollableParent) + scrollOffset,
      );
    }
  }

  createResizeObserver() {
    if (!isBrowser() || !window.ResizeObserver) {
      return;
    }

    this.containerResizeObserver = new window.ResizeObserver((entries) => {
      if (entries.length > 0) {
        const [containerSizeChange] = entries;
        const { contentBoxSize, contentRect } = containerSizeChange;
        let newHeight;

        if (contentBoxSize) {
          const newSize = contentBoxSize[0] ? contentBoxSize[0] : contentBoxSize;

          newHeight = newSize.blockSize;
        } else {
          newHeight = contentRect.height;
        }

        if (!this.isLoadingMoreEntries && this.shouldKeepScrollPosition()) {
          this.restoreScrollPosition(newHeight);
        }

        this.containerHeight = newHeight;
      }
    });

    this.containerResizeObserver.observe(this.container);
  }

  disableResizeObserver() {
    if (this.containerResizeObserver) {
      this.containerResizeObserver.disconnect();
    }
  }

  onScroll() {
    this.handleViewportCheck();
  }

  setPinnedEntryHeight(height) {
    this.pinnedEntryHeight = height;
  }

  clearNewEntriesCount() {
    this.setState({ newEntriesCount: 0 });
  }

  onNewEntryVisit() {
    this.setState(({ newEntriesCount }) => ({
      newEntriesCount: newEntriesCount - 1,
    }));
  }

  getEntriesContainerOffsetTop() {
    // offset by estimated header height, doesn't need to be accurate,
    // just enough not overlap the entry and leave some space above it
    const headerOffset = 70;

    return (this.containerOffsetTop + this.pinnedEntryHeight) - headerOffset;
  }

  onNewEntriesClick() {
    const { scrollableParent } = this.props;

    scrollToY(scrollableParent, this.getEntriesContainerOffsetTop());
  }

  getLiveblogView() {
    const {
      entries,
      hasNoEntries,
      postMessageHandler,
      liveblogDataLoaded,
      AdComponent,
      pinnedEntry,
      pinnedUrl,
      hasMoreEntries,
      isFetchingBelow,
      handleFetchMoreEntries,
      theme,
      publication,
      entriesAuthors,
      entryActions,
      scrollableParent,
      loggedInUser,
      forceUserMessagesLoginDisabling,
      feedId,
      placement,
      blogSettings,
      feedSettings,
    } = this.props;

    const { newEntriesCount } = this.state;
    const texts = getTexts(publication, 'Liveblog');

    const zip = (rows, shift) => rows[0].map((_, i) => {
      if (shift) {
        return rows.slice().reverse().map(row => row[i]);
      }

      return rows.map(row => row[i]);
    });

    const entriesView = entries
      .filter(entry => entry.id !== (pinnedEntry || {}).id)
      .filter((entry, index, entriesArr) => index === 0 || entry.id !== entriesArr[index - 1].id)
      .map((entry, index) => {
        const isEntryNew = index < newEntriesCount;

        const commonEntryProps = {
          key: entry.id,
          tags: entry.tags,
          shouldShowTags: entry.isEditable, // for now behind feature flag, delete it after release
          isNew: isEntryNew,
          scrollableParent,
          entryActions,
          onNewEntryVisit: this.onNewEntryVisit,
          newsValue: entry.value,
          feedId,
        };

        if (entry.isSport && entry.type === TYPES.COMMENTARY) {
          return (<Entry
            content={entry.text}
            meta={entry.elapsed}
            pinnedUrl={pinnedUrl}
            addedWithinMatch
            publication={publication}
            isEditable={false}
            entriesAuthors={entriesAuthors}
            blogSettings={blogSettings}
            feedSettings={feedSettings}
          />);
        }

        if (entry.isSport) {
          return (<SportsEntry
            type={entry.type}
            subtype={entry.subtype}
            entry={entry}
            publication={publication}
            {...commonEntryProps}
          />);
        }

        if (entry.isDirekteVg) {
          return (<Entry
            {...entry}
            pinnedUrl={pinnedUrl}
            publication={publication}
            isEditable={false}
            blogSettings={blogSettings}
            {...commonEntryProps}
          />);
        }

        return (<Entry
          {...entry}
          pinnedUrl={pinnedUrl}
          publication={publication}
          entriesAuthors={entriesAuthors}
          blogSettings={blogSettings}
          feedSettings={feedSettings}
          {...commonEntryProps}
        />);
      });

    const ads = AdComponent
      ? entries.map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={theme.adContainer} key={`ad-${i}`}>
          <AdComponent currentIndex={i} active={false} />
        </div>
      ))
      : [];

    const entriesWithAds = []
      .concat(...zip([entriesView, ads], Boolean(pinnedEntry)));

    const messageForm = feedSettings.allowUserMessages
      ? (<MessageForm
        postMessageHandler={postMessageHandler}
        isPostMessageButtonVisible={liveblogDataLoaded}
        publication={publication}
        forceUserMessagesLoginDisabling={forceUserMessagesLoginDisabling}
        loggedInUser={loggedInUser}
        blogSettings={blogSettings}
        feedSettings={feedSettings}
      />)
      : null;

    const loadMoreEntriesButton = !hasNoEntries && hasMoreEntries
      ? (<LoadMoreEntries
        isFetchingBelow={isFetchingBelow}
        handleFetchMoreEntries={handleFetchMoreEntries}
        publication={publication}
        blogSettings={blogSettings}
        loggedInUser={loggedInUser}
      />)
      : null;

    const hasPin = pinnedEntry != null;

    const liveblogHeading = <LiveblogHeading hasPin={hasPin} title={feedSettings.title} />;

    const liveblogLoginView = (<LiveblogLoginView
      publication={publication}
      loggedInUser={loggedInUser}
      feedSettings={feedSettings}
      blogSettings={blogSettings}
    />);

    const liveblogClassName = cc({
      [theme.liveblog]: true,
      [theme.sportLiveblog]: this.props.blogSettings.isSportsLiveblog,
    });

    const liveblogContent = hasNoEntries
      ? <p className={theme.noEntriesMessage}>{texts.noPosts}</p>
      : <ul className={liveblogClassName}>{ entriesWithAds }</ul>;

    const isNewEntriesIndicatorVisible = (newEntries, plcmnt) => {
      if (newEntries < 1) {
        return false;
      }
      if (!plcmnt) {
        return true;
      }

      return plcmnt && plcmnt === 1;
    };

    return (
      <div>
        <NewEntriesIndicator
          isVisible={isNewEntriesIndicatorVisible(newEntriesCount, placement)}
          newEntriesCount={newEntriesCount}
          onClick={this.onNewEntriesClick}
          onClose={this.clearNewEntriesCount}
          publication={publication}
          pointDown={getScrollY(scrollableParent) < this.getEntriesContainerOffsetTop()}
        />
        <PinnedEntry
          key={pinnedEntry && pinnedEntry.id}
          entry={pinnedEntry}
          pinnedUrl={pinnedUrl}
          setPinnedEntryHeightFunction={this.setPinnedEntryHeight}
          publication={publication}
          entryActions={entryActions}
          feedId={feedId}
          feedSettings={feedSettings}
        />
        {
          !forceUserMessagesLoginDisabling
          && blogSettings.isUserMessagesLoginRequired
          && feedSettings.allowUserMessages
            ? liveblogLoginView
            : liveblogHeading
        }
        { messageForm }
        { liveblogContent }
        { loadMoreEntriesButton }
      </div>
    );
  }

  getLoadingView() {
    const { theme } = this.props;

    return <SkeletonView theme={theme} />;
  }

  render() {
    const {
      liveblogDataLoaded,
      theme,
    } = this.props;

    const liveblogWrapperClassName = cc({
      [theme.wrapper]: true,
      /*
      * added it as public class name
      * to estimate width of the container
      * for video player height needed
      * for lazy loading
      */
      'core-liveblog': true,
    });

    const view = liveblogDataLoaded
      ? this.getLiveblogView()
      : this.getLoadingView();

    return (
      <div className={liveblogWrapperClassName} ref={(node) => { this.container = node; }}>
        {view}
      </div>
    );
  }
}

const entryPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  date: PropTypes.string,
  authorName: PropTypes.string,
  authorImage: PropTypes.string,
  content: PropTypes.string.isRequired,
  structuredContent: PropTypes.object,
  isEditable: PropTypes.bool,
  newsValue: PropTypes.number,
  sourceName: PropTypes.string,
});

Liveblog.propTypes = {
  entries: PropTypes.arrayOf(entryPropTypes).isRequired,
  hasNoEntries: PropTypes.bool,
  pinnedUrl: PropTypes.string,
  postMessageHandler: PropTypes.func,
  liveblogDataLoaded: PropTypes.bool,
  isDataLoadedClientSide: PropTypes.bool,
  AdComponent: PropTypes.func,
  pinnedEntry: entryPropTypes,
  hasMoreEntries: PropTypes.bool,
  isFetchingBelow: PropTypes.bool,
  handleFetchMoreEntries: PropTypes.func,
  isEditable: PropTypes.bool,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  publication: PropTypes.string,
  entriesAuthors: PropTypes.arrayOf(PropTypes.string),
  entryActions: EntryActionsShape,
  scrollableParent: PropTypes.shape({
    addEventListener: PropTypes.func,
    removeEventListener: PropTypes.func,
    scrollTo: PropTypes.func,
  }),
  loggedInUser: LoggedInUserShape,
  // TODO: The prop has to stay as it is until liveblog-static supports user messages login
  forceUserMessagesLoginDisabling: PropTypes.bool,
  trackEvent: PropTypes.func,
  feedId: PropTypes.string,
  placement: PropTypes.number,
  blogSettings: BlogSettingsShape,
  feedSettings: FeedSettingsShape,
};

Liveblog.defaultProps = {
  entries: [],
  theme: {},
  isEditable: false,
  liveblogDataLoaded: true,
  isDataLoadedClientSide: false,
  publication: 'bt',
  entriesAuthors: [],
  scrollableParent: isBrowser() ? window : {
    addEventListener: () => {},
    removeEventListener: () => {},
    scrollTo: () => {},
  },
  loggedInUser: {},
  forceUserMessagesLoginDisabling: false,
  trackEvent: () => {},
  feedId: null,
  placement: null,
  blogSettings: BlogSettingsDefaultProps,
  feedSettings: FeedSettingsDefaultProps,
};

Liveblog.childContextTypes = {
  trackEvent: PropTypes.func,
};

export default themer('Liveblog')(Liveblog);
