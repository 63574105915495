// eslint-disable-next-line import/prefer-default-export
export const updateQueryStringParameter = (uri, key, value) => {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';

  return uri.match(re)
    ? uri.replace(re, `$1${key}=${value}$2`)
    : `${uri + separator + key}=${value}`;
};

export const getQueryStringValueForKey = (uri = '', key) => {
  const regexp = new RegExp(`${key}=([^&]*)`);

  const matches = uri.match(regexp);

  return matches && matches[1];
};

export const appendToQueryString = (uri, keyValueArray) => {
  let uriWithQueryParams = uri;
  keyValueArray.forEach((kv) => {
    if (!kv[0] || !kv[1]) {
      return;
    }

    uriWithQueryParams = updateQueryStringParameter(uriWithQueryParams, kv[0], kv[1]);
  });

  return uriWithQueryParams;
};
