import React, { Component } from 'react';

const withErrorBoundary = (
  WrappedComponent,
  OnErrorComponent,
) => class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { errorInfo, error } = this.state;

    if (errorInfo) {
      return <OnErrorComponent errorInfo={errorInfo} error={error} />;
    }

    return <WrappedComponent {...this.props} />;
  }
};

export default withErrorBoundary;
