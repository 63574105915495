import { className } from '../functions';

export default ({ css }) => {
  const dropAreaZindex = 2;
  const previewZindex = 1;
  const withPreview = 'withPreview';
  const breakpointDesktop = '768px';

  const dropArea = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @media (min-width: ${breakpointDesktop}) {
      height: 215px;
    }
  `;

  const dropAreaOnDragover = css`
    background-color: #d7d3cf;
  `;

  const input = css`
    display: none;
  `;

  const dropAreaWrapper = css`
    &${className(withPreview)} {
      z-index: ${dropAreaZindex};

      @media (min-width: ${breakpointDesktop}) {
        opacity: 0;
      }
    }
  `;

  const labelDesktop = css`
    height: 215px;
    color: rgba(0, 0, 0, .5);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    background-image: url('https://liveblog-static-assets.schibsted.tech/border-image.png');
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    display: none;

    @media (min-width: ${breakpointDesktop}) {
      display: flex;
    }
  `;

  const button = css`
    color: #2C78E4;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 700;
    border-radius: 4px;
    border: 1px solid #2C78E4;
    outline: none;
    margin-top: 16px;
    padding: 8px 36px;
  `;

  const buttonText = css`
    margin-left: 8px;
  `;

  const labelMobile = css`
    color: #2C78E4;
    border: 1px solid #2C78E4;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    border-radius: 2px;
    display: block;
    margin-right: 8px;
    padding: 8px 0;
    text-align: center;

    @media (min-width: ${breakpointDesktop}) {
      display: none;
    }
  `;

  const uploadPreview = css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;

    @media (min-width: ${breakpointDesktop}) {
      position: absolute;
      z-index: ${previewZindex};
      display: block;
    }
  `;

  return {
    styles: {
      dropArea,
      dropAreaOnDragover,
      input,
      dropAreaWrapper,
      button,
      buttonText,
      labelDesktop,
      labelMobile,
      uploadPreview,
    },
  };
};
