import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EntryVideo from '../EntryVideo/EntryVideo';
import EntryImage from '../EntryImage/EntryImage';
import DefaultContent from '../DefaultContent/DefaultContent';

import { regexParameter, unescape } from '../../utils/html';
import { nodeShape, elementPropsShape } from '../../utils/shapes';

class MediaContent extends Component {
  parseVideoUrl(videoUrl) {
    const unescapedVideoUrl = unescape(videoUrl);
    const vendor = regexParameter('vendor', unescapedVideoUrl)[1];
    const assetId = regexParameter('id', unescapedVideoUrl)[1];
    const title = regexParameter('title', unescapedVideoUrl)[1];

    return { assetId, vendor, title };
  }
  render() {
    const { node, elementProps, publication } = this.props;
    if (elementProps.className && elementProps.className.indexOf('mm-image-wrapper') > -1) {
      return <EntryImage {...elementProps} publication={publication} />;
    } else if (elementProps.className && elementProps.className.indexOf('mm-iframe-video') > -1) {
      const v = node.children.filter(child => (child.attrs && child.attrs.src));

      if (!v || (v && v.length < 1)) return false;
      const props = this.parseVideoUrl(v[0].attrs.src);

      return <EntryVideo {...props} />;
    }
    // if media found return component for div
    return <DefaultContent node={node} elementProps={elementProps} />;
  }
}

MediaContent.propTypes = {
  node: PropTypes.shape(nodeShape).isRequired,
  elementProps: PropTypes.shape(elementPropsShape).isRequired,
  publication: PropTypes.string.isRequired,
};

export default MediaContent;
