import React from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import { getTexts } from '../../config';

const wrapContent = (content, prefix) => {
  if (prefix) {
    return { __html: `${prefix} ${content}` };
  }
  return { __html: content };
};

export const EntryImageInfo = ({ caption, credit, theme, publication }) => {
  const texts = getTexts(publication, 'EntryImageInfo');

  if (!caption && !credit) {
    return null;
  }

  const wrappedCaption = wrapContent(caption);
  const wrappedCredit = wrapContent(credit, texts.fotoText);

  const captionView = caption && caption !== 'undefined'
    // eslint-disable-next-line react/no-danger
    ? <span className={theme.imageCaption} dangerouslySetInnerHTML={wrappedCaption} />
    : null;

  const creditView = credit && credit !== 'undefined'
    // eslint-disable-next-line react/no-danger
    ? <span className={theme.imageCredit} dangerouslySetInnerHTML={wrappedCredit} />
    : null;

  const wrapperView = captionView || creditView
    ? (
      <div className={theme.imageInfoWrapper}>
        {captionView}
        {' '}
        {creditView}
      </div>
    )
    : null;

  return wrapperView;
};

EntryImageInfo.propTypes = {
  caption: PropTypes.string,
  credit: PropTypes.string,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  publication: PropTypes.string.isRequired,
};

EntryImageInfo.defaultProps = {
  theme: {},
};

export default themer('EntryImageInfo')(EntryImageInfo);
