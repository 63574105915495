import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';

import { unescape } from '../../utils/html';

import EntryImageInfo from '../EntryImageInfo/EntryImageInfo';

const ONE_SECOND_IN_MS = 1000;
const TWO_SECONDS = 2;

export class EntryImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delayedImage: null,
    };
  }

  randomizeDelayTime(maxDelay) {
    const delay = Math.random() * maxDelay * ONE_SECOND_IN_MS;

    return Number.parseInt(delay, 10);
  }

  delayImage(image) {
    setTimeout(
      () => this.setState(
        { delayedImage: image }),
      this.randomizeDelayTime(TWO_SECONDS),
    );
  }

  componentDidMount() {
    this.delayImage(<img
      className={this.props.theme.entryImageContent}
      srcSet={this.props.srcset}
      src={unescape(this.props.src)}
      alt={this.props.caption}
    />);
  }

  render() {
    const {
      crop,
      caption,
      credit,
      theme,
      publication,
    } = this.props;

    return (
      <div>
        <div className={theme.entryImageWrapper} data-crop={crop}>
          {this.state.delayedImage}
        </div>
        <EntryImageInfo
          caption={unescape(caption)}
          credit={credit}
          publication={publication}
        />
      </div>
    );
  }
}


EntryImage.propTypes = {
  src: PropTypes.string.isRequired,
  srcset: PropTypes.string,
  caption: PropTypes.string,
  credit: PropTypes.string,
  crop: PropTypes.string,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  publication: PropTypes.string.isRequired,
};
EntryImage.defaultProps = {
  theme: {},
};
export default themer('EntryImage')(EntryImage);
