// TODO: pinnedUrl - see Entry

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themer } from '@core-live/theme-provider';
import cc from 'classcat';

import SportsEntryHeader from '../SportsEntryHeader/SportsEntryHeader';
import SportsEntryContent from '../SportsEntryContent/SportsEntryContent';

import { TYPES } from '../utils/const';
import { sportsEntryShape } from '../../../utils/shapes';
import { isInViewport } from '../../../utils/html';
import { isBrowser } from '../../../utils/env';

const supportedEvents = Object.values(TYPES);

export class SportsEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animate: false,
    };

    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    const { isNew, scrollableParent } = this.props;

    if (!isBrowser()) {
      return;
    }

    if (isNew) {
      scrollableParent.addEventListener('scroll', this.onScroll);

      this.onScroll();
    }
  }

  componentDidUpdate(prevProps) {
    const { isNew, scrollableParent } = this.props;

    if (prevProps.isNew === true && isNew === false) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ animate: true });

      scrollableParent.removeEventListener('scroll', this.onScroll);
    }
  }

  componentWillUnmount() {
    const { scrollableParent } = this.props;

    scrollableParent.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    const { isNew, onNewEntryVisit } = this.props;

    if (isNew) {
      const isEntryInViewport = isInViewport(this.node);

      if (isEntryInViewport) {
        onNewEntryVisit();
      }
    }
  }

  render() {
    const { type, subtype, entry, isNew, theme, publication } = this.props;

    const { animate } = this.state;

    if (!supportedEvents.includes(type)) {
      return false;
    }

    const entryStyleClasses = cc({
      [theme.entry]: true,
      [theme.sportsEntry]: true,
      [theme.animate]: animate,
      [theme.isNew]: isNew,
      [theme.goal]: type === TYPES.SCORE_CHANGE,
      [theme.periodStart]: type === TYPES.PERIOD_START,
    });

    return (
      <li
        ref={(node) => { this.node = node; }}
        className={entryStyleClasses}
        data-testid="liveblog-entry"
      >
        <SportsEntryHeader type={type} subtype={subtype} entry={entry} publication={publication} />
        <SportsEntryContent type={type} subtype={subtype} meta={entry.meta} text={entry.text} publication={publication} />
      </li>
    );
  }
}

SportsEntry.propTypes = {
  type: PropTypes.string,
  subtype: PropTypes.string,
  entry: PropTypes.shape(sportsEntryShape),
  isNew: PropTypes.bool,
  onNewEntryVisit: PropTypes.func,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  publication: PropTypes.string.isRequired,
  scrollableParent: PropTypes.shape({
    addEventListener: PropTypes.func,
    removeEventListener: PropTypes.func,
  }),
};

SportsEntry.defaultProps = {
  theme: {},
};

export default themer('SportsEntry')(SportsEntry);
