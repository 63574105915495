const wrapXHR = (url, opts) => {
  if (global.fetch) {
    return fetch(url, opts);
  }

  const { method, headers, body } = opts;

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    let headersSet = false;

    const contextualError = (message) => {
      const err = new Error(message);
      err.context = {
        url,
        method,
        body,
        response: xhr.responseText,
      };
      return err;
    };

    const setHeaders = () => {
      if (headersSet) {
        /*
         headersSet is a workaround because IE11 changes
         readyState to 1 again after xhr.send(),and then
         to 2 so it triggers setting headers again after
         it's no longer possible
         */
        return;
      }
      Object.keys(headers)
        .forEach((headerName) => {
          xhr.setRequestHeader(headerName, headers[headerName]);
        });
      headersSet = true;
      xhr.send(body);
    };

    const handleData = () => {
      resolve(Object.assign({}, xhr, {
        json: () => new Promise((res, rej) => {
          if (xhr.responseText) {
            res(JSON.parse(xhr.responseText));
          } else {
            rej(contextualError('Cannot JSON parse an empty response'));
          }
        }),
        text: () => Promise.resolve(xhr.responseText),
        status: xhr.status,
      }));
    };

    const onStateChange = () => {
      if (xhr.readyState === 1) {
        setHeaders();
      }
      if (xhr.readyState === 4) {
        handleData();
      }
    };

    const onError = () => {
      reject(contextualError(`XHR Error: Status ${xhr.status} ${xhr.statusText}`));
    };

    const onTimeout = () => {
      reject(contextualError('Timeout when requesting URL'));
    };

    xhr.onreadystatechange = onStateChange;
    xhr.onerror = onError;
    xhr.ontimeout = onTimeout;

    xhr.open(method, url, true);
  });
};

export function get(endpoint) {
  const opts = {
    method: 'GET',
    headers: Object.assign({
      Accept: 'application/json',
    }),
  };
  return wrapXHR(endpoint, opts);
}

export function post(endpoint, body, headers = null) {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  };

  const opts = {
    method: 'POST',
    headers: headers || defaultHeaders,
    body,
  };

  return wrapXHR(endpoint, opts);
}
