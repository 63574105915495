import PropTypes from 'prop-types';

export const nodeShape = {
  attrs: PropTypes.object,
  children: PropTypes.array,
  name: PropTypes.string,
  type: PropTypes.string,
  voidElement: PropTypes.bool,
};

export const elementPropsShape = {
  style: PropTypes.object,
  className: PropTypes.string,
  src: PropTypes.string,
};

export const timeShape = {
  main: PropTypes.string,
  details: PropTypes.string,
};

export const elapsedShape = {
  minutes: PropTypes.number,
  minutesPlus: PropTypes.number,
};

export const metaParticipantsTeamShape = {
  team: PropTypes.string,
  player: PropTypes.string,
};

export const metaParticipantsSubstitutionShape = {
  team: PropTypes.string,
  playerIn: PropTypes.string,
  playerOut: PropTypes.string,
};

export const metaParticipantsGoalShape = {
  team: PropTypes.string,
  scorer: PropTypes.string,
  assistant: PropTypes.string,
};

export const sportsMetaShape = {
  participants: PropTypes.oneOfType([
    PropTypes.shape(metaParticipantsTeamShape),
    PropTypes.shape(metaParticipantsSubstitutionShape),
    PropTypes.shape(metaParticipantsGoalShape),
  ]),
  // TODO: this cannot be defined as it has 'team: score' shape
  currentScores: PropTypes.object,
};

export const regularMetaShape = {
  minutes: PropTypes.number,
  minutesPlus: PropTypes.number,
};

export const sportsEntryShape = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  subtype: PropTypes.string,
  elapsed: PropTypes.shape(elapsedShape),
  meta: PropTypes.shape(sportsMetaShape),
  entryType: PropTypes.string,
  createdAt: PropTypes.string,
  text: PropTypes.string,
};

export const socialEmbedShape = {
  'facebook-embed-container': PropTypes.string,
};
