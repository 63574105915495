const parseMessage = (message) => {
  let decodedMessage = {};

  try {
    decodedMessage = JSON.parse(message);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`[CLIENT] Can not parse message "${message}`);

    return {};
  }

  const { body, type } = decodedMessage;

  if (!body || !body.liveblogId || !type) {
    // eslint-disable-next-line no-console
    console.error('[CLIENT] Incomplete message payload');

    return {};
  }

  return { body, type };
};

export default parseMessage;
