import format from 'date-fns/format';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import isThisWeek from 'date-fns/isThisWeek';
import isThisYear from 'date-fns/isThisYear';

import nb from 'date-fns/locale/nb';
import sv from 'date-fns/locale/sv';

import { getConfig, getTexts } from '../config';

function getDateLocale(publication) {
  const locale = getConfig(publication, 'locale');
  return locale === 'sv-SE' ? sv : nb;
}

export function parseDate(rawDate, isSportsLiveblog, publication) {
  if (!rawDate) {
    return null;
  }

  const date = new Date(rawDate);

  const dateLocale = getDateLocale(publication);
  const texts = getTexts(publication, 'datesAndTime');
  const current = new Date();
  const minutes = differenceInMinutes(current, date);
  let day = format(date, 'EEEE', { locale: dateLocale });
  day = day.charAt(0).toUpperCase() + day.slice(1); // Uppercase the first letter for Norwegian
  let hour = format(date, 'HH:mm');
  let fullDate = format(date, 'dd. MMMM', { locale: dateLocale }).toLowerCase();
  let dayNumberFormat = 'dd.';

  if (dateLocale === sv) {
    dayNumberFormat = 'd';
    day = day.toLowerCase();
    hour = format(date, 'HH.mm');
    fullDate = format(date, `${dayNumberFormat} MMMM`, { locale: dateLocale }).toLowerCase();
  }

  if (minutes === 0) {
    return { main: texts.now };
  }

  if (!isSportsLiveblog && (minutes > 0 && minutes < 31)) {
    return { main: `${minutes} ${texts.minutes}` };
  }

  if (isToday(date)) {
    return { main: `${hour}` };
  }

  if (!isSportsLiveblog && isYesterday(date)) {
    return { main: texts.yesterday, details: hour };
  }

  if (isThisWeek(date)) {
    return { main: day, details: hour };
  }

  if (!isThisYear(date)) {
    return { main: format(date, `${dayNumberFormat} MMMM yyyy`, { locale: dateLocale }).toLowerCase(), details: hour };
  }

  return { main: fullDate, details: hour };
}

export function formatDateForPinned(rawDate, publication) {
  if (!rawDate) {
    return null;
  }

  const date = new Date(rawDate);

  const dateLocale = getDateLocale(publication);
  const texts = getTexts(publication, 'datesAndTime');

  const today = isToday(date);
  let hour = format(date, 'HH:mm');
  let dayNumberFormat = 'dd.';

  if (dateLocale === sv) {
    hour = format(date, 'HH.mm');
    dayNumberFormat = 'd';
  }

  if (today) {
    return `${texts.todayAt} ${hour}`;
  }

  let dateFormat = `${dayNumberFormat} MMMM`;

  if (!isThisYear(date)) {
    dateFormat = `${dayNumberFormat} MMMM yyyy`;
  }

  const parsedDate = format(date, dateFormat, { locale: dateLocale });
  return `${parsedDate} ${texts.atTime} ${hour}`;
}

export function getTimeFromDate(date) {
  if (!date) {
    return null;
  }

  const regex = /\d\d:\d\d/g;
  const time = regex.exec(date);

  if (time && time.length > 0) {
    return time[0];
  }
  return null;
}
